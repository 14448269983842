import {
  Component,
  Input,
  Output,
  EventEmitter,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AppConfig } from '@app/app.config';
import { ToastService } from '@app/core/services';
import { AlertController, ModalController } from '@ionic/angular';

@Component({
  selector: 'rating',
  templateUrl: './ranting.component.html',
  styleUrls: ['./ranting.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RatingComponent),
      multi: true
    }
  ]
})
export class RatingComponent implements ControlValueAccessor {
  commentary: any;
  logo: string = `${AppConfig.DEFAULT_LOGO}`;
  @Input()
  rate: number;
  @Input()
  readonly: boolean;
  @Input()
  size = 'default';
  @Output()
  rateChange: EventEmitter<number> = new EventEmitter();
  hoverRate: number;
  _onChange: Function = (_: any) => {};
  isAlert: boolean;

  constructor(
    private modalCtrl: ModalController,
    private alertController: AlertController,
    private toash: ToastService
  ) {}

  onClick(rate) {
    this.rate = rate;
    this.rateChange.emit(this.rate);
    this._onChange(this.rate);
  }

  async accept() {
    if (!this.rate) {
      this.toash.presentToast({text: 'Selecciona con cuantas estrellas quieres valorar esta app.'});
      return;
    }
    this.isAlert = true;
      const alert = await this.alertController.create({
        cssClass: 'modal-popover',
        header: 'Alerta',
        subHeader: this.rate === -1 ? 'Al descartar la valoracion no podras volver a valorar esta app.' : `Quieres valorar con ${this.rate} estrella esta app.`,
        buttons: [{
          text: 'Aceptar',
          role: 'acept',
          handler: async () => {
            this.isAlert = false;
            this.modalCtrl.dismiss({
              calification: this.rate,
              commentary: this.commentary
            });
          }
        }]
      });
      alert.onDidDismiss().then(async (data) => {
        this.isAlert = false;
      });
      await alert.present();
  }

  writeValue(value: any): void {
    if (value !== undefined) {
      this.rate = value;
    }
  }

  registerOnChange(fn: (_: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState?(isDisabled: boolean): void {
    this.readonly = isDisabled;
  }
}
