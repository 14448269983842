import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class StorageService {

  private user : Subject<any>  = new Subject();
  private patient : BehaviorSubject<any>  = new BehaviorSubject([]);
  private login : BehaviorSubject<Boolean> = new BehaviorSubject(false);
  private isClear : BehaviorSubject<Boolean> = new BehaviorSubject(false);
  private isClearPatient : BehaviorSubject<Boolean> = new BehaviorSubject(false);

  constructor(
    private storage : Storage
  ) {
  }

  setData(data : any) {
    if(data) {
      this.setItem('user', data);
      this.user.next(data);
      this.login.next(true);  
    }
    else {
      this.setItem('user', null);
      this.user.next(null);
      this.login.next(false);
    }
  }
  updateData(data : any) {
    if(data) {
      const user = this.getItem('user');
      Object.assign(user, data);
      this.setItem('user', user);
      this.user.next(data);
    }
  }
  setDataPatient(data : any) {
    if(data) {
      this.setItem('patient', data);
      this.patient.next(data); 
    }
    else {
      this.setItem('patient', null);
      this.patient.next(null);
    }
  }

  reloadData() : void {
    this.getItem('user')
    .then((user : any) => {
      this.user.next(user);
    });
    
    this.login.next(true);
  }

  reloadDataPatient() : void {
    this.getItem('patient')
    .then((patient : any) => {
      this.patient.next(patient);
    });
    
    this.reloadData();
  }
  
  isLogin() : any {
    return this.login;
  }

  getUser() : any {
    return this.user;
  }
  
  getPatient() : any {
    return this.patient;
  }
  /*async setItem(key: string, value: any) {
    await Storage.set({key : key, value : JSON.stringify(value)});
  }

  async getItem(key: string): Promise<{ value: any }> {
    const ret = await Storage.get({ key });
    return JSON.parse(ret.value);
  }*/

  getItem(key: string) : any {
    return this.storage.get(key);
  }

  setItem(key: string, value: any) : void {
    this.storage.set(key, value);
  }

  getClearStatus() : any {
    return this.isClear;
  }

  getClearPatient() : any {
    return this.isClearPatient;
  }
  
  clear() {
    //await Storage.clear();
    this.storage.clear();
    this.isClear.next(true);
    this.setData(null);
    setTimeout(() => {
      this.isClear.next(false);
    }, 2000);
  }


  clearPatient() {
    this.storage.remove('patient');
  }
}
