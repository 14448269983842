import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppConfig } from '@app/app.config';
import { CountryService, SpinnerService, StorageService, ToastService } from '@app/core/services';
import { UserService } from '@app/core/services/user/user.service';
import { ModalController } from '@ionic/angular';

let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  @Input('isModal') isModal: boolean;
  logo: string = `${AppConfig.DEFAULT_LOGO}`;
  isMenu: boolean = false;
  registerForm: FormGroup;
  labelProvince: any = null;
  specialtyList: Array<any> = [
    {name: 'Neurología', value : 1},
    {name: 'Clínica médica', value : 2},
    {name: 'Cardiología', value : 3},
    {name: 'Nefrología', value : 4},
    {name: 'Otro', value : 5},
  ];
  countryList: Array<any> = new Array();
  provinceList: Array<any> = new Array();
  showProvince: boolean = false;
  user: any;
  constructor(
    private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private storageService: StorageService,
    private toastService: ToastService,
    private countryService: CountryService,
    private userService: UserService,
    private spinnerService: SpinnerService,
  ) { }

 
  async ngOnInit() {
    this.countryService.all().subscribe((resp: any)=> {      
      this.countryList = resp.data;
    });
    this.registerForm = this.formBuilder.group({     
      id: [null],    
      firstname: [null, [Validators.required]],
      lastname: [null,[Validators.required]],
      email: [null, [Validators.required, Validators.pattern(emailRegex)]],
      verficationEmail: [null, [Validators.required, Validators.pattern(emailRegex)]],
      registration: [null, Validators.required],
      institution: [null],
      country: [null, Validators.required],
      city: [null],
      city_name: [null],
      other_specialty: [null],
      specialty: [null, [Validators.required]],
      condition: [false, [Validators.required]],
    });

    // this.user = await this.storageService.getItem('user');

    // this.updateFormUser(this.user);
  }

  updateFormUser(user) {
    this.registerForm.patchValue({
      id: user.id,   
      firstname: user.firstname|| null,
      lastname: user.lastname|| null,
      email: user.email|| null,
      verficationEmail: user.email|| null,
      registration:user.profile.registration|| null,
      institution: user.profile.institution|| null,
      country: parseInt(user.profile?.country)|| null,
      city: parseInt(user.profile?.city)|| null,
      city_name: user.profile?.city_name || null,
      other_specialty: user.profile.other_specialty|| null,
      specialty: parseInt(user.profile?.specialty) || null,
    })
  }

  async ionViewWillEnter() {
    this.user = await this.storageService.getItem('user');
    this.showUser(this.user.id);
  }

  showUser(id) {
    const sub = this.userService.show(id).subscribe(resp => {
      const user = resp.data;
      this.updateFormUser(user);
      sub.unsubscribe();
    });
  }
  close() {
    this.reset();
    this.modalCtrl.dismiss();
  }

  get validate() {
    return this.registerForm.controls;
  }

  selectCountry(e) { 
    if(e.target.value === 1) {
      this.showProvince = true;
      let resul: any;
      resul = this.countryList.find( function(aux) {
        if(aux.id === e.target.value) {
          return aux.provinces;
        }
      });
      this.labelProvince = resul.label_province;
      this.provinceList = resul.provinces;
    } else {
      this.showProvince = false;
      this.labelProvince = null;
      this.registerForm.patchValue({city: null, city_name: null});
    }
  }

  onSubmit() {

    if (this.registerForm.get('firstname').invalid) {
      this.toastService.error('validation.firstname');
      return;
    }

    if (this.registerForm.get('lastname').invalid) {
      this.toastService.error('validation.lastname');
      return;
    }

    if (this.registerForm.get('email').invalid) {
      this.toastService.error('validation.email');
      return;
    }

    if (this.registerForm.get('verficationEmail').invalid) {
      this.toastService.error('validation.verficationEmail');
      return;
    }

    if (this.registerForm.value.email != this.registerForm.value.verficationEmail) {
      this.toastService.error('validation.confEmail');
      return;
    }

    if (this.registerForm.get('specialty').invalid) {
      this.toastService.error('validation.specialty');
      return;
    }

    if (this.registerForm.get('registration').invalid) {
      this.toastService.error('validation.registration');
      return;
    }

    if (this.registerForm.get('country').invalid) {
      this.toastService.error('validation.country');
      return;
    }

    if(this.registerForm.value.country == 1 && this.registerForm.value.city == null) {
      this.toastService.error('validation.city');
      return;
    }

    if (this.registerForm.valid) {
      this.spinnerService.show();

      const sub = this.userService.update(this.registerForm.value, this.registerForm.value.id).subscribe((res: any) => {
        const user = res;
        this.close();
        this.spinnerService.hide();  
        sub.unsubscribe();
        this.reset();
        }, (error): any => {
          this.toastService.error(error.error.message);
          this.spinnerService.hide();
          sub.unsubscribe();
          console.error(error)
        });
    }
  }
  reset() {
    this.registerForm.reset();
  }
}
