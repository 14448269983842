import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from '../storage/storage.service';

@Injectable()
export class LanguageService {

  private currentLanguage: BehaviorSubject<String> = new BehaviorSubject('');
  private languages: BehaviorSubject<String[]> = new BehaviorSubject([]);
  private isClear : boolean;

  constructor(
    private translate: TranslateService, 
    private storageService: StorageService
    ) {
      this.storageService.getClearStatus()
      .subscribe((status : boolean) => {
        this.isClear = status;
        if(this.isClear) {
          this.init();
        }
      });
  }

  init() : void {
    let lang : string = 'es';
    let languages : string[] = ['es','en'];
    
    this.storageService.getItem('currentLanguage')
    .then((currentLanguage: any) => {
      if(currentLanguage) {
        this.currentLanguage.next(currentLanguage);
        this.storageService.getItem('languages')
        .then((languages: any) => {
          if(languages.value) {
            this.languages.next(languages);
          }
        }).catch((error : any) => {
          // console.error('error init languages array', error);
          this.languages.next(languages);
          this.storageService.setItem('languages', languages);
        });
      }
      else {
        this.currentLanguage.next(lang);
        this.languages.next(languages);
        this.storageService.setItem('currentLanguage', lang);
        this.storageService.setItem('languages', languages);
      }

      this.translate.setDefaultLang(this.currentLanguage.value[0]);
      this.translate.use(this.currentLanguage.value[0]);
    }).catch((error : any) => {
      console.error('error init language', error);

      this.languages.next(languages);
      this.currentLanguage.next(lang);
      this.translate.setDefaultLang(lang);
      this.translate.use(lang);
      this.storageService.setItem('currentLanguage', lang);
      this.storageService.setItem('languages', languages);
    });
  }

  getAvailableLanguages(): any {
    return this.languages;
  }

  getCurrentLanguage() : any {
    return this.currentLanguage;
  }

  changeLanguage(lang : string) {
    this.currentLanguage.next(lang);
    this.translate.use(lang);
    this.storageService.setItem('currentLanguage', lang);
  }

  getKey(key : string) : any {
    return this.translate.instant(key);
  }
  
}
