import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IonContent, IonSlides } from '@ionic/angular';
import * as _ from 'lodash';

@Component({
  selector: 'app-history-third',
  templateUrl: './history-third.component.html',
  styleUrls: ['./history-third.component.scss'],
})
export class HistoryThirdComponent implements OnInit {
  @ViewChild('slides', { static: true }) slides: IonSlides;
  @ViewChild(IonContent, { static: true }) content: IonContent;
  @Input('update') update: any;
  @Input('treatment') treatment: any;
  @Input('inputs') inputs: any;


  @Output("actionTreatment") actionTreatment: EventEmitter<any>;

  menu: string;
  segment: any = 0;
  slideOpts: any = {
    pager: false,
    autoHeight: true,
    resistance: true
  };
  class = 'segment';
  menuOptions: Array<any> = [
    {
      value: '1',
      name: 'Tratamiento actual'
    },
  ]

  constructor() {
    this.actionTreatment = new EventEmitter();
  }

  ngOnInit() {
    this.segment = 0;
    this.menu = this.menuOptions[0].value;
  }
  updatePage() {
    this.segment = 0;
    this.menu = '1';
  }

  async loadTreatment(e) {
    switch (e.number) {
      case 66:
        this.treatment.medication_option = e.valueRadio
        this.treatment.medication = e.data
        break;
      case 67:
        this.treatment.clinical_study_option = e.valueRadio
        this.treatment.clinical_study = e.data
        break;
      case 68:
        this.treatment.experimental_drug_option = e.valueRadio
        this.treatment.experimental_drug = e.data
        break;
      case 69:
        this.treatment.transplant_option = e.valueRadio
        this.treatment.year_transplant = e.otherValue
        this.treatment.donor = e.donor
        this.treatment.transplant = e.data
        break;
      case 70:
        this.treatment.amyloidosis_option = e.valueRadio
        this.treatment.amyloidosis = e.data
        break;
      case 71:
        this.treatment.nutritional_option = e.valueRadio
        this.treatment.nutritional = e.data
        break;
      case 72:
        this.treatment.rehabilitation_option = e.valueRadio
        this.treatment.rehabilitation = e.data
        break;
      case 73:
        this.treatment.integral_option = e.valueRadio
        this.treatment.integral = e.data
        break;
      case 74:
        this.treatment.inmunosupreso_option = e.valueRadio
        this.treatment.inmunosupreso = e.data
        break;
    }
    await this.actionTreatment.emit(this.treatment);
  }

  actionInputTo(event) {
    const index = _.findIndex(this.inputs, {number: Number(event.numberTo)});
    if (index > -1) {
      console.log(event, 'MMMM', this.inputs[index])
      this.inputs[index].valueRadio = event.valueRadio;
      this.loadTreatment(this.inputs[index]);
      const select = document.getElementById(`select-${event.numberTo}`);
      if (select && event.valueRadio === '1') {
        setTimeout(() => {
          select.click();          
        }, 750);
      }
    }
  }
}
