import { Injectable } from '@angular/core';
import { Observable, Subject, TimeoutError } from 'rxjs';

const delay = 1200;

export class LoaderStyle {
    className?: string;
    background?: string;
    position?: string;
    spinner?: 'ball' | undefined;
    content?: string;
    height = 100;
    color?: string;
    constructor(o: any = {}) {
        this.className = o.className || 'contentLoader';
        this.background = o.background || 'var(--ion-loader-color, white)';
        this.position = o.position || 'fixed';
        this.spinner = o.spinner;
        this.content = o.content;
        this.height = o.height || 100;
        this.color = o.color || 'white';
    }
}

@Injectable()
export class LoaderService {
    status: Subject<boolean> = new Subject<boolean>();
    statusModal: Subject<boolean> = new Subject<boolean>();
    style: LoaderStyle = new LoaderStyle();
    viewErr = false;
    loading: boolean;
    id: string;

    constructor() {
        this.status.next(false);
    }

    get loaderStyle() {
        return new LoaderStyle({
            background: 'rgba(214, 214, 214, 0.43)',
            height: 100
        });
    }

    show(style: LoaderStyle = new LoaderStyle()) {
        this.style = new LoaderStyle(style);
        setTimeout(() => this.viewErr = false, delay / 2);
        this.status.next(true);
        this.loading = true;
    }

    showStyle(style: LoaderStyle = this.loaderStyle) {
        this.style = new LoaderStyle(style);
        setTimeout(() => this.viewErr = false, delay / 2);
        this.status.next(true);
        this.loading = true;
    }

    hide(d = null) {
        this.loading = false;
        setTimeout(() => {
            this.id = undefined;
            this.style.content = undefined;
            this.status.next(false);
        }, d || delay);
    }

    error(error) {
        if (error instanceof TimeoutError || error.status === 0) {
            this.hide();
            setTimeout(() => this.viewErr = true, delay);
            console.warn(error);
        } else {
            console.error(error);
            this.hide();
        }
    }

    getStatus(): Observable<boolean> {
        return this.status.asObservable();
    }
}
