export class Configuration {
    token: string;
    expire: number;
     /**
     * @description Constructor of class
     * @param o object of type any.
     * @default {}
     */
    constructor(token = null, expire) {
        this.token = token;
        this.expire = expire;
    }
}
