import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpService } from '../../http/http.service';

@Injectable()
export class RecoverService {

  constructor(public http: HttpService) {
  }

  doRecover(form : any) : any {
    // const params = new HttpParams()
    // .append('username', form.username);

    return this.http.postWithoutToken(`${environment.API_URL}/security/forgot-password`, form);
  }
}
