import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient,) { }

    update(body: any, id): Observable<any> {
      return this.http.put(`${environment.API_URL}users/${id}`, body)
    }

    show(id): Observable<any> {
      return this.http.get(`${environment.API_URL}users/${id}`);
    }

    califications(body): Observable<any> {
      return this.http.post(`${environment.API_URL}califications`, body);
    }
}
