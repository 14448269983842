import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { AppConfig } from "@app/app.config";
import { ConfigStorage } from "@app/core/storage";
import { IonContent, IonSlides, ModalController } from "@ionic/angular";

@Component({
  selector: "app-bibliography",
  templateUrl: "./bibliography.component.html",
  styleUrls: ["./bibliography.component.scss"],
})
export class BibliographyComponent implements OnInit {
  @ViewChild("slides", { static: true }) slides: IonSlides;
  @ViewChild(IonContent, { static: true }) content: IonContent;
  logo: string = `${AppConfig.DEFAULT_LOGO}`;
  list: Array<any> = new Array<any>();
  @Input("isModal") isModal: boolean;
  search: any = "";
  menu: string;
  segment: any = 0;
  slideOpts: any = {};
  slider: any;
  constructor(
    private config: ConfigStorage,
    private modalCtrl: ModalController
  ) {}

  ngOnInit() {
    const host = this.config.apiHost;
    this.list = new Array<any>(
      {
        text: "Adams_EuropeanConsensus_2016",
        href: `${host}help?pdf=Adams_EuropeanConsensus_2016`,
      },
      {
        text: "Botkin_EthicalLegalGeneticTesting_2015",
        href: `${host}help?pdf=Botkin_EthicalLegalGeneticTesting_2015`,
      },
      {
        text: "Conceicao_Assessment_2019",
        href: `${host}help?pdf=Conceicao_Assessment_2019`,
      },
      {
        text: "Hartmut_ManagementAsymptomatic_2016",
        href: `${host}help?pdf=Hartmut_ManagementAsymptomatic_2016`,
      },
      {
        text: "Kansas City Cardiomyopathy Questionnaire",
        href: `${host}help?pdf=KCCQ`,
      },
      {
        text: "KCCQ12_Kansas-City-Questionaire_",
        href: `${host}help?pdf=KCCQ12_Kansas-City-Questionaire_`,
      },
      {
        text: "Kittleson_CardiacAmyloidosis_2020",
        href: `${host}help?pdf=Kittleson_CardiacAmyloidosis_2020`,
      },
      {
        text: "NIS_SCALA",
        href: `${host}help?pdf=Inotersen_Treatment_for_Patients_NEJM`,
      },
      {
        text: "Inotersen Treatment for Patients_NEJM",
        href: `${host}help?pdf=Inotersen Treatment for Patients_NEJM`,
      },
      {
        text: "Patisiran, an RNAi Therapeutic, for Hereditary Transthyretin Amyloidosis_NEJM",
        href: `${host}help?pdf=Patisiran, an RNAi Therapeutic, for Hereditary Transthyretin Amyloidosis_NEJM`,
      },
      {
        text: "Tafamidis Treatment for Patients with Transthyretin Amyloid Cardiomyopathy",
        href: `${host}help?pdf=Tafamidis Treatment for Patients with Transthyretin Amyloid Cardiomyopathy`,
      },
      {
        text: "Tafamidis for Transthyretin Familial Amyloid Polineuropathy",
        href: `https://doi.org/10.1212/WNL.0b013e3182661eb1`,
      }
    );
  }

  cerrar() {
    this.modalCtrl.dismiss();
  }
}
