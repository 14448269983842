import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import * as _ from 'lodash';

@Component({
  selector: 'app-select-number',
  templateUrl: './select-number.component.html',
  styleUrls: ['./select-number.component.scss'],
})
export class SelectNumberComponent implements OnInit {

  selectedNumber = 0;
  range = [];
  numbers = [];
  constructor(
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {
    if (this.range.length > 1) {
      this.numbers = _.range(this.range[0] - this.range[1]).map((x, i) => {
        return this.range[0] + i;
      }).reverse();
      this.numbers.unshift(this.range[1]);
    } 
  }

  dismiss(data = null) {
    this.modalCtrl.dismiss(data);
  }

}
