import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  constructor(
    private spinner: NgxSpinnerService
  ) { }


  show () {
    this.spinner.show(undefined, {
      fullScreen: true,
      type: "ball-spin-clockwise-fade",
      size: "medium",
      bdColor: "rgba(0,0,0,0.5)",
      color: "white",
    });
  }

  hide () {
    this.spinner.hide();
  }
}
