import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectorRef } from '@angular/core';
import * as _ from 'lodash';
@Component({
  selector: 'app-input-generic',
  templateUrl: './input-generic.component.html',
  styleUrls: ['./input-generic.component.scss'],
})
export class InputGenericComponent implements OnInit {

  @Input() mode: any;
  @Input() modeEdit: any;
  @Output('actionAntecedent') actionAntecedent: EventEmitter<any>;
  @Output('actionNervous') actionNervous: EventEmitter<any>;
  @Output('actionCardiovascular') actionCardiovascular: EventEmitter<any>;
  @Output('actionGastrointestinal') actionGastrointestinal: EventEmitter<any>;
  @Output('actionGenitourinario') actionGenitourinario: EventEmitter<any>;
  @Output('actionOphthalmus') actionOphthalmus: EventEmitter<any>;
  @Output('actionExam') actionExam: EventEmitter<any>;
  @Output('actionEvaluation') actionEvaluation: EventEmitter<any>;
  @Output('actionComplementary') actionComplementary: EventEmitter<any>;
  @Output('actionScale') actionScale: EventEmitter<any>;
  @Output('actionTreatment') actionTreatment: EventEmitter<any>;

  @Output() actionInputTo: EventEmitter<any>;

  edit: any;

  datePickerObj: any = {
    inputDate: new Date(),
    showTodayButton: false,
    setLabel: 'Guardar',  // default 'Set'
    closeLabel: 'Cerrar', // default 'Close'
    dateFormat: 'YYYY',
    monthsList: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
    btnProperties: {
      expand: 'block',
    }
  };
  
  isPrint: boolean;

  constructor(
    private cdRef: ChangeDetectorRef
  ) {
    this.actionAntecedent = new EventEmitter();
    this.actionNervous = new EventEmitter();
    this.actionExam = new EventEmitter();
    this.actionEvaluation = new EventEmitter();
    this.actionTreatment = new EventEmitter();
    this.actionCardiovascular = new EventEmitter();
    this.actionGastrointestinal = new EventEmitter();
    this.actionGenitourinario = new EventEmitter();
    this.actionOphthalmus = new EventEmitter();
    this.actionComplementary = new EventEmitter();
    this.actionScale = new EventEmitter();
    this.actionInputTo = new EventEmitter();
  }

  ngOnInit() {
    if (this.modeEdit !== undefined) {
      this.edit = this.modeEdit
    }

  }

  bmi() {
    if (!!this.mode.imcHeight && !!this.mode.imcWeight) {
      this.mode.otherValue = window['bmi'](this.mode.imcHeight, this.mode.imcWeight).bmi;
      this.onChangeSecondInput(this.mode)
    } else {
      this.mode.otherValue = null;
      this.onChangeSecondInput(this.mode);
    }
  }

  onlyNumberInt(e, max, min, v) {

    e = (e) ? e : window.event;
    let keyCode = (e.keyCode ? e.keyCode : e.which)

    if (v == 'full') {
      if (keyCode != 45) {
        if (keyCode == 44 || keyCode == 46 || !(keyCode >= 48 && keyCode <= 57)) {
          e.preventDefault();
          return;
        }
      }
    }

    if (v == 'positivo') {
      if (keyCode == 44 || keyCode == 45 || keyCode == 46 || !(keyCode >= 48 && keyCode <= 57)) {
        e.preventDefault();
        return;
      }
    }

    if (v == 'real') {
      if (keyCode != 46) {
        if (keyCode == 44 || keyCode == 45 || !(keyCode >= 48 && keyCode <= 57)) {
          e.preventDefault();
          return;
        }
      }
    }

    /*if (e.key != null) {
      var value = parseInt(e.target.value + e.key);
      // console.log(value, min, max, 'bug')
      if (!_.inRange(value, _.toNumber(min), _.toNumber(max) + 1)) {
        e.preventDefault();
        //  e.target.value = value < min ? min : max;
        return;
      }
    }*/
  }

  ngModelChangeNumber(inputOne) {
    this.mode.data = inputOne.value;
    if (!this.isPrint && !!this.mode.data) {
      setTimeout(() => {
        const value = Number(this.mode.data);
        //console.log(value, this.mode.min , this.mode.max)
        if (value < this.mode.min) {
          this.mode.data = this.mode.min;
        }
        if (value > this.mode.max) {
          this.mode.data = this.mode.max;
        }
        this.isPrint = true;
      }, 700);
    } else {
      this.isPrint = false;      
    }
  }

  onChangeOneInput(e) {

    this.actionAntecedent.emit(e);
    this.actionNervous.emit(e);
    this.actionCardiovascular.emit(e);
    this.actionExam.emit(e);
    this.actionEvaluation.emit(e);
    this.actionTreatment.emit(e);
    this.actionGastrointestinal.emit(e);
    this.actionGenitourinario.emit(e);
    this.actionOphthalmus.emit(e);
    this.actionComplementary.emit(e);
    this.actionScale.emit(e);



  }

  onChangeSecondInput(e) {
    this.actionAntecedent.emit(e);
    this.actionNervous.emit(e);
    this.actionCardiovascular.emit(e);
    this.actionExam.emit(e);
    this.actionEvaluation.emit(e);
    this.actionTreatment.emit(e);
    this.actionGastrointestinal.emit(e);
    this.actionGenitourinario.emit(e);
    this.actionOphthalmus.emit(e);
    this.actionComplementary.emit(e);
    this.actionScale.emit(e);
  }

  onChangeArea(e) {
    this.actionAntecedent.emit(e);
    this.actionNervous.emit(e);
    this.actionCardiovascular.emit(e);
    this.actionExam.emit(e);
    this.actionEvaluation.emit(e);
    this.actionTreatment.emit(e);
    this.actionGastrointestinal.emit(e);
    this.actionGenitourinario.emit(e);
    this.actionOphthalmus.emit(e);
    this.actionComplementary.emit(e);
    this.actionScale.emit(e);
  }

  onChangeSelect(e) {
    this.actionAntecedent.emit(e);
    this.actionNervous.emit(e);
    this.actionCardiovascular.emit(e);
    this.actionExam.emit(e);
    this.actionEvaluation.emit(e);
    this.actionTreatment.emit(e);
    this.actionGastrointestinal.emit(e);
    this.actionGenitourinario.emit(e);
    this.actionOphthalmus.emit(e);
    this.actionComplementary.emit(e);
    this.actionScale.emit(e);
  }

  onChangeRadio(e) {
    if (e.valueRadio === '2' || e.valueRadio === undefined) {
      this.mode.data = '';
    }
    if (this.mode.transplant === 'yes') {
      this.mode.donor = null;
    }
    this.actionAntecedent.emit(e);
    this.actionNervous.emit(e);
    this.actionCardiovascular.emit(e);
    this.actionExam.emit(e);
    this.actionEvaluation.emit(e);
    if ('numberTo' in e) {
      this.actionInputTo.next(e)
    }
    this.actionTreatment.emit(e);
    this.actionGastrointestinal.emit(e);
    this.actionGenitourinario.emit(e);
    this.actionOphthalmus.emit(e);
    this.actionComplementary.emit(e);
    this.actionScale.emit(e);
  }
}
