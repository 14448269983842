import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { LanguageService } from './core/services';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';

import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    public updates: SwUpdate,
    private androidPermissions: AndroidPermissions,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private languageService: LanguageService,
  ) {
    updates.available.subscribe(event => {
      console.log('current version is', event.current);
      console.log('available version is', event.available);
    });
    updates.activated.subscribe(event => {
      console.log('old version was', event.previous);
      console.log('new version is', event.current);
    });

    updates.available.subscribe(event => {
      updates.activateUpdate().then(() => {
        var r = confirm(`🚀 EN HORA BUENA UNA NUEVA VERSION DISPONIBLE.`);
        if (!!r) {
          document.location.reload();
        }
        console.log("The app is updating right now");
      });
    });
    this.initializeApp();
    this.requestPermissions();
  }

  initializeApp() {
    try {
      this.languageService.init();
    } catch (err) {
      console.error('error', err);
    }
    this.platform.ready().then(() => {
      if (this.platform.is('cordova') && (this.platform.is('android') || this.platform.is('ios'))) {
        this.statusBar.styleDefault();
        this.statusBar.backgroundColorByHexString('#201E1E');
        this.statusBar.overlaysWebView(false);
        this.statusBar.styleLightContent();
        this.splashScreen.hide();
      }
    });
  }

  /**
   * @description Request permissions access to the platform android
   */
  requestPermissions() {
    if (this.platform.is('cordova') && this.platform.is('android')) {
      this.androidPermissions.requestPermissions([
        //  this.androidPermissions.PERMISSION.ACCESS_COARSE_LOCATION,
        //  this.androidPermissions.PERMISSION.ACCESS_FINE_LOCATION,
        //  this.androidPermissions.PERMISSION.LOCATION_HARDWARE,
        this.androidPermissions.PERMISSION.INTERNET,
        this.androidPermissions.PERMISSION.CAMERA,
        this.androidPermissions.PERMISSION.RECORD_AUDIO,
        //  this.androidPermissions.PERMISSION.ACCESS_NETWORK_STATE,
        //  this.androidPermissions.PERMISSION.ACCESS_WIFI_STATE
      ]).then((request) => {
        localStorage.setItem('requestPermissions', JSON.stringify(request));
      }, (err) => {
        // console.warn('err requestPermissions', err);
      });
    }
  }
}
