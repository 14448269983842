import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[empty]'
})
export class InputEmptyDirective {

  @Input() classNameEmpty = 'empty';

  @Input() set empty(empty: any) {
    this.init(empty);
  };

  constructor(
    private el: ElementRef,
    private redender: Renderer2
  ) { }

  init(empty): void {
    if (this.el.nativeElement) {
      if (!empty || Array.isArray(empty) && empty.length === 0) {
        this.redender.addClass(this.el.nativeElement, this.classNameEmpty)
      } else {
        this.redender.removeClass(this.el.nativeElement, this.classNameEmpty)
      }
    }
  }

}
