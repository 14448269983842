import { Injectable } from '@angular/core';
import { LanguageService } from '../language/language.service';
// import { Plugins, ToastShowOptions } from '@capacitor/core';
// const { Toast } = Plugins;
import * as _ from 'lodash';
import { ToastController } from '@ionic/angular';

@Injectable()
export class ToastService {

  private styling: any = {
    opacity: 0.8, // 0.0 (transparent) to 1.0 (opaque). Default 0.8
    backgroundColor: '#333333', // make sure you use #RRGGBB. Default #333333
    textColor: '#FFFFFF', // Ditto. Default #FFFFFF
    textSize: 13, // Default is approx. 13.
  }

  constructor(
    private languageService: LanguageService,
    public toastController: ToastController
    ) {
  }

  success(key: string, position: string = 'top') {

    let message = this.languageService.getKey(key);
    let options: any = {
      text: message,
      // showCloseButton: true,
      position: position,
      color: 'toast-success',
      // closeButtonText: 'Cerrar',
      duration: 10000,
    }

    this.presentToast(options);
  }

  error(key: string, position: string = 'top') {
    let message = this.languageService.getKey(key);
    let options: any = {
      text: message,
      // showCloseButton: true,
      position: position,
      color: 'toast-alert',
      // closeButtonText: 'Cerrar',
      duration: 10000,
    }

    this.presentToast(options)
  }

  // async render(options: ToastShowOptions) { 
  //   return await Toast.show(options);
  // }

  async presentToast(options) {
    const toast = await this.toastController.create({
      message: options.text,
      duration: options.duration || 2000,
      cssClass: options.color,
      mode: "md",
      position: "top"
    });
    toast.present();
  }

  async presentToastWithOptions() {
    const toast = await this.toastController.create({
      header: 'Toast header',
      message: 'Click to Close',
      position: 'top',
      buttons: [
        {
          side: 'start',
          icon: 'star',
          text: 'Favorite',
          handler: () => {
            console.log('Favorite clicked');
          }
        }, {
          text: 'Done',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        }
      ]
    });
    toast.present();
  }
}
