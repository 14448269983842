import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IonContent, IonSlides } from '@ionic/angular';

@Component({
  selector: 'app-history-second',
  templateUrl: './history-second.component.html',
  styleUrls: ['./history-second.component.scss'],
})
export class HistorySecondComponent implements OnInit {
  @ViewChild('slides', { static: false }) slides: IonSlides;
  @ViewChild(IonContent, { static: false }) content: IonContent;

  @Input('toScroll') toScroll: any;
  @Output("actionScroll") actionScroll: EventEmitter<any>;

  @Input('update') update: any;
  @Input('exam') exam: any;
  @Input('inputExam') inputExam: any;
  @Input('evaluation') evaluation: any;
  @Input('inputEvaluation') inputEvaluation: any;
  @Input('complementary') complementary: any;
  @Input('inputComplementary') inputComplementary: any;
  @Input('scale') scale: any;
  @Input('inputScale') inputScale: any;

  @Output("actionExam") actionExam: EventEmitter<any>;
  @Output("actionEvaluation") actionEvaluation: EventEmitter<any>;
  @Output("actionComplementary") actionComplementary: EventEmitter<any>;
  @Output("actionScale") actionScale: EventEmitter<any>;

  menu: string;
  segment: any = 0;
  class = 'seg-1';
  slideOpts: any = {
  };
  public slider: any;


  menuOptions: Array<any> = [
    {
      value: '1',
      name: 'Examenes',
      class: 'center'
    },
    {
      value: '2',
      name: 'Laboratorio',
      class: 'center'
    },
    {
      value: '3',
      name: 'Estudios complementarios',
      class: 'left'
    },
    {
      value: '4',
      name: 'Escalas',
      class: 'center'
    },
  ]
  constructor() {
    this.actionExam = new EventEmitter();
    this.actionEvaluation = new EventEmitter();
    this.actionComplementary = new EventEmitter();
    this.actionScale = new EventEmitter();

    this.actionScroll = new EventEmitter();
  }

  ngOnInit() {
    this.segment = 0;
    this.menu = this.menuOptions[0].value;
    this.slideOpts = {
      onlyExternal: true,
      pager: false,
      allowTouchMove: false,
      onInit: (slides: any) =>
        this.slider = slides
    }
  }
  logScrolling(e) {
  }
  updatePage() {
    this.segment = 0;
    this.menu = '1';
    this.class = 'seg-1';
  }

  nextEvaluacion() {
    this.segment = 1;
    this.menu = '2';
    this.class = 'seg-2';
    this.actionScroll.emit(1);
  }

  nextComplementario() {
    this.segment = 2;
    this.menu = '3';
    this.class = 'seg-3';
    this.actionScroll.emit(1);
  }

  nextEscalas() {
    this.segment = 3;
    this.menu = '4';
    this.class = 'seg-4';
    this.actionScroll.emit(1);
  }

  segmentChanged() {
    switch (this.menu) {
      case '1':
        this.segment = 0;
        this.class = 'seg-1';
        break;

      case '2':
        this.segment = 1;
        this.class = 'seg-2';
        break;

      case '3':
        this.segment = 2;
        this.class = 'seg-3';
        break;

      case '4':
        this.segment = 3;
        this.class = 'seg-4';
        break;
    }
    this.slides.update();
    this.slides.slideTo(this.segment);
  }

  ionSlideDidChange() {
    this.slides.getActiveIndex()
      .then((value) => {
        this.content.scrollToTop();
        this.segment = value;

        switch (this.segment) {
          case 0:
            this.menu = '1';
            break;

          case 1:
            this.menu = '2';
            break;

          case 2:
            this.menu = '3';
            break;

          case 3:
            this.menu = '4';
            break;

          default:
            this.menu = '1';
            break;

        }
      });
  }

  async loadExam(e) {
    switch (e.number) {
      case 36:
        this.exam.physical_exam = e.data
        break;
      case 37:
        this.exam.pacemaker_exam_option = e.valueRadio
        this.exam.pacemaker_exam = e.data
        break;
      case 39:
        this.exam.molecular_study_option = e.valueRadio
        this.exam.molecular_study = e.data
        break;
    }
    await this.actionExam.emit(this.exam);
  }

  async loadEvaluation(e) {
    switch (e.number) {
      case 38:
        this.evaluation.laboratory_option = e.valueRadio
        break;
      case 39:
        this.evaluation.hto = e.data
        break;
      case 40:
        this.evaluation.gr = e.data
        break;
      case 41:
        this.evaluation.gb = e.data
        break;
      case 42:
        this.evaluation.platelet = e.data
        break;
      case 43:
        this.evaluation.glucose = e.data
        break;
      case 44:
        this.evaluation.urea = e.data
        break;
      case 45:
        this.evaluation.creatinina = e.data
        break;
      case 46:
        this.evaluation.tgo = e.data
        break;
      case 47:
        this.evaluation.tgp = e.data
        break;
      case 48:
        this.evaluation.proteinuria_evaluation = e.data
        break;
      case 49:
        this.evaluation.vit = e.data
        break;
      case 50:
        this.evaluation.prealbumina = e.data
        break;
      case 51:
        this.evaluation.albulmina = e.data
        break;
      case 52:
        this.evaluation.laboratory = e.data
        break;
      case 53:
        this.evaluation.micro_albulmina = e.data
        break;
      case 54:
        this.evaluation.diuresis = e.data
        break;
      case 55:
        this.evaluation.peptido_natriuretico = e.data
        break;
      case 56:
        this.evaluation.cpk = e.data
        break;
      case 57:
        this.evaluation.troponina = e.data
        break;
      case 58:
        this.evaluation.micro_albulmina_day = e.data
        break;
    }
    await this.actionEvaluation.emit(this.evaluation);
  }

  async loadComplementary(e) {
    switch (e.number) {
      case 53:
        this.complementary.emg_option = e.valueRadio
        this.complementary.emg = e.data
        break;
      case 54:
        this.complementary.qst_option = e.valueRadio
        this.complementary.qst = e.data
        break;
      case 55:
        this.complementary.ecg_option = e.valueRadio
        this.complementary.ecg = e.data
        break;
      case 56:
        this.complementary.cardiaca_option = e.valueRadio
        this.complementary.cardiaca = e.data
        break;
      case 57:
        this.complementary.resonancia_option = e.valueRadio
        this.complementary.resonancia = e.data
        break;
      case 58:
        this.complementary.biopsy_option = e.valueRadio
        this.complementary.biopsy = e.data
        break;
      case 59:
        this.complementary.echocardiogram_option = e.valueRadio
        this.complementary.echocardiogram = e.data
        break;
      case 61:
        this.complementary.cardiac_holter_option = e.valueRadio
        this.complementary.cardiac_holter = e.data
        break;
      case 60:
        // this.complementary.other_complementary_option = e.valueRadio
        this.complementary.other_complementary = e.data
        break;
    }
    await this.actionComplementary.emit(this.complementary);
  }

  async loadScale(e) {
    switch (e.number) {
      case 61:
        this.scale.norfolk_option = e.valueRadio
        this.scale.norfolk = e.data
        break;
      case 62:
        this.scale.nis_option = e.valueRadio
        this.scale.nis = e.data
        break;
      case 63:
        this.scale.nis_ll_option = e.valueRadio
        this.scale.nis_ll = e.data
        break;
      case 64:
        this.scale.compass_option = e.valueRadio
        this.scale.compass = e.data
        break;
      case 65:
        this.scale.other_scale = e.data
        break;
    }
    await this.actionScale.emit(this.scale);
  }
}
