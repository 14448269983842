import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { IonSearchbar } from '@ionic/angular';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-search-text',
  templateUrl: './search-text.component.html',
  styleUrls: ['./search-text.component.scss'],
})
export class SearchTextComponent implements OnInit, OnDestroy {

  k: string;
  search: string;
  keyTime: any;

  @Input() isSetFocus = true;

  @Input() placeholder: string;
  @Input() class: boolean;
  @Input() class2 = false;
  @Input() withSpace: boolean;
  @Input() isK: boolean;
  @Input() errorText: string;
  @Input() syncText: string;
  @Input() color: string;
  @Input() className: string;
  @Input() searchIcon = 'search';

  @Output() searchText = new EventEmitter<string>();

  @ViewChild(IonSearchbar) searchbar: IonSearchbar;

  searchSub: Subscription;

  constructor() {
  }

  ngOnInit(): void {
    if (this.isSetFocus) {
      // setTimeout(() => {
      //   this.searchbar.getInputElement().then((input: HTMLInputElement) => {
      //     input.focus();
      //   });
      // }, 1000);
    }
  }

  ionChange(event: any) {
    if (this.isK) {
      const value = event.detail.value;
      const k = value.split(' ').filter((x) => x.length >= 2).map((x) => x.toLowerCase()).toString();
      if (k !== this.k) {
        this.k = k;
        this.searchText.emit(this.k);
      }
    }
  }

  onSearch(event: any) {
    if (!this.isK) {
      this.search = this.formatSearch(event);
      clearTimeout(this.keyTime);
      this.keyTime = setTimeout(() => {
        this.searchbar.setFocus().then();
        this.searchText.emit(this.search);
      }, 500);
    }
  }

  formatSearch(event: any) {
    let str: string = !this.withSpace ? event.target.value.replace(' ', '') : event.target.value;
    str = str.replace('Unidentified', '');
    event.target.value = str || '';
    const ev: boolean = this.withSpace || event.key !== 'Backspace';
    const key = (event.key !== 'Unidentified' || event.key !== 'Enter') ? event.key : '';
    const text = ev ? str + key : str.slice(0, str.length - 1);
    return text.replace('Unidentified', '')
      .replace('Enter', '')
      .replace('Escape', '')
      .replace('ArrowDown', '')
      .replace('ArrowUp', '')
      .replace('ArrowLeft', '')
      .replace('ArrowRight', '');
  }

  ngOnDestroy(): void {
    if (this.searchSub) {
      this.searchSub.unsubscribe();
    }
  }

}
