import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IonContent, IonSlides, ModalController } from '@ionic/angular';
@Component({
  selector: 'app-history-first',
  templateUrl: './history-first.component.html',
  styleUrls: ['./history-first.component.scss'],
})
export class HistoryFirstComponent implements OnInit {
  @ViewChild('slides', { static: true }) slides: IonSlides;
  @ViewChild(IonContent, { static: true }) content: IonContent;


  @Input('toScroll') toScroll: any;
  @Output("actionScroll") actionScroll: EventEmitter<any>;

  @Input('update') update: any;
  // @Input('history_date') history_date: any;
  // @Output("actionHistoryDate") actionHistoryDate: EventEmitter<any>;
  @Input('antecedent') antecedent: any;
  @Input('inputAntecedent') inputAntecedent: Array<any>;
  @Input('nervous') nervous: any;
  @Input('inputNervous') inputNervous: Array<any>;
  @Input('cardiovascular') cardiovascular: any;
  @Input('inputCardiovascular') inputCardiovascular: any;
  @Input('gastrointestinal') gastrointestinal: any;
  @Input('inputGastrointestinal') inputGastrointestinal: any;
  @Input('genitourinario') genitourinario: any;
  @Input('inputGenitourinario') inputGenitourinario: any;
  @Input('ophthalmus') ophthalmus: any;
  @Input('inputOphthalmus') inputOphthalmus: any;
  @Input('inputPsychiatrics') inputPsychiatrics: any;

  @Output('actionAntecedent') actionAntecedent: EventEmitter<any>;
  @Output("actionNervous") actionNervous: EventEmitter<any>;
  @Output("actionCardiovascular") actionCardiovascular: EventEmitter<any>;
  @Output("actionGastrointestinal") actionGastrointestinal: EventEmitter<any>;
  @Output("actionGenitourinario") actionGenitourinario: EventEmitter<any>;
  @Output("actionOphthalmus") actionOphthalmus: EventEmitter<any>;

  class = 'segment-1';
  menu: string;
  segment: any = 0;
  minBirth: any;
  segmentHeight: any;
  slideOpts: any = {
  };
  public slider: any;
  menuOptions: Array<any> = [
    {
      value: '1',
      name: 'Antecedentes / Comorbilidades'
    },
    {
      value: '2',
      name: 'Sistema nervioso'
    },
    {
      value: '3',
      name: 'Sistema cardiovascular'
    },
    {
      value: '4',
      name: 'Sistema gastrointestinal'
    },
    {
      value: '5',
      name: 'Sistema genitourinario'
    },
    {
      value: '6',
      name: 'Sistema Oftalmo'
    },
    {
      value: '7',
      name: 'Sistema Psiquiatría'
    }
  ];

  datePickerObj: any = {
    inputDate: new Date(),
    showTodayButton: false,
    setLabel: 'Guardar',  // default 'Set'
    closeLabel: 'Cerrar', // default 'Close'
    dateFormat: 'DD/MM/YYYY',
    monthsList: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
    weeksList: ["D", "L", "M", "M", "J", "V", "S"],
    btnProperties: {
      expand: 'block',
    }
  };

  constructor(
    public modalCtrl: ModalController) {
    this.actionAntecedent = new EventEmitter();
    this.actionNervous = new EventEmitter();
    this.actionCardiovascular = new EventEmitter();
    this.actionGastrointestinal = new EventEmitter();
    this.actionGenitourinario = new EventEmitter();
    this.actionOphthalmus = new EventEmitter();
    // this.actionHistoryDate = new EventEmitter();


    this.actionScroll = new EventEmitter();
  }
  ngOnInit() {
    this.segment = 0;
    this.segmentHeight = 'segment1';
    this.menu = this.menuOptions[0].value;

    this.slideOpts = {
      autoHeight: true,
      onlyExternal: true,
      pager: false,
      allowTouchMove: false,
      onInit: (slides: any) =>
        this.slider = slides
    }
  }

  async onDate() {
    //   await this.actionHistoryDate.emit(this.history_date);
  }

  updatePage() {
    this.segment = 0;
    this.class = 'segment-1';
    this.menu = '1';
    this.actionScroll.emit(0);
  }

  nextNervioso() {
    this.segment = 1;
    this.class = 'segment-2';
    this.menu = '2';
    this.actionScroll.emit(0);
  }
  nextCardiovascular() {
    this.segment = 2;
    this.class = 'segment-3';
    this.menu = '3';
    this.actionScroll.emit(0);
  }

  nextGastrointestinal() {
    this.segment = 3;
    this.class = 'segment-4';
    this.menu = '4';
    this.actionScroll.emit(0);
  }

  nextGenitourinario() {
    this.segment = 4;
    this.class = 'segment-5';
    this.menu = '5';
    this.actionScroll.emit(0);
  }

  nextPsiquiatria() {
    this.segment = 5;
    this.class = 'segment-6';
    this.menu = '6';
    this.actionScroll.emit(0);
  }

  nextPsiquiatria2() {
    this.segment = 6;
    this.class = 'segment-7';
    this.menu = '7';
    this.actionScroll.emit(0);
  }

  segmentChanged() {
    switch (this.menu) {
      case '1':
        this.segment = 0;
        this.class = 'segment-1';
        this.actionScroll.emit(0);
        break;

      case '2':
        this.segment = 1;
        this.class = 'segment-2';
        this.actionScroll.emit(0);
        break;

      case '3':
        this.segment = 2;
        this.class = 'segment-3';
        this.actionScroll.emit(0);
        break;

      case '4':
        this.segment = 3;
        this.class = 'segment-4';
        this.actionScroll.emit(0);
        break;

      case '5':
        this.segment = 4;
        this.class = 'segment-5';
        this.actionScroll.emit(0);
        break;

      case '6':
        this.segment = 5;
        this.class = 'segment-6';
        this.actionScroll.emit(0);
        break;
      case '7':
        this.segment = 6;
        this.class = 'segment-7';
        this.actionScroll.emit(0);
        break;
    }
    this.slides.update();
    this.slides.slideTo(this.segment);
  }

  ionSlideDidChange() {
    this.slides.getActiveIndex()
      .then((value) => {
        this.content.scrollToTop();
        this.segment = value;

        switch (this.segment) {
          case 0:
            this.menu = '1';
            break;

          case 1:
            this.menu = '2';
            break;

          case 2:
            this.menu = '3';
            break;

          case 3:
            this.menu = '4';
            break;

          case 4:
            this.menu = '5';
            break;

          case 5:
            this.menu = '6';
            break;

          case 6:
            this.menu = '7';
            break;

          default:
            this.menu = '1';
            break;

        }
      });
  }

  async loadDataAntecedent(e) {
    switch (e.number) {
      case 1:
        this.antecedent.antecedent = e.data
        break;
      case 2:
        this.antecedent.physical_exam_antecedent = e.data
        break;
      case 3:
        this.antecedent.toxic_habits = e.data
        break;
      case 4:
        this.antecedent.usual_medication = e.data
        break;
    }
    await this.actionAntecedent.emit(this.antecedent);
  }

  async loadDataNervous(e) {
    switch (e.number) {
      case 3:
        this.nervous.neuropathy_option = e.valueRadio
        this.nervous.neuropathy = e.data
        break;
      case 4:
        this.nervous.dysautonomia_option = e.valueRadio
        this.nervous.dysautonomia = e.data
        break;
      case 5:
        this.nervous.march_option = e.valueRadio
        this.nervous.march = e.data
        break;
      case 6:
        this.nervous.carpal_option = e.valueRadio
        this.nervous.carpa = e.data
        break;
      case 7:
        this.nervous.ulcers_option = e.valueRadio
        this.nervous.ulcers = e.data
        break;
      case 8:
        this.nervous.snc_option = e.valueRadio
        this.nervous.snc = e.data
        break;
      case 9:
        this.nervous.cognitive_option = e.valueRadio
        this.nervous.cognitive = e.data
        break;
    }
    await this.actionNervous.emit(this.nervous);
  }

  async loadCardiovascular(e) {
    switch (e.number) {
      case 10:
        this.cardiovascular.hta_option = e.valueRadio
        this.cardiovascular.hta = e.data
        break;
      case 11:
        this.cardiovascular.dbt_option = e.valueRadio
        this.cardiovascular.dbt = e.data
        break;
      case 12:
        this.cardiovascular.orthostatic_option = e.valueRadio
        this.cardiovascular.orthostatic = e.data
        break;
      case 13:
        this.cardiovascular.dyspnoea_option = e.valueRadio
        this.cardiovascular.dyspnoea = e.data
        break;
      case 14:
        this.cardiovascular.edema_option = e.valueRadio
        this.cardiovascular.edema = e.data
        break;
      case 15:
        this.cardiovascular.hypertrophic_option = e.valueRadio
        this.cardiovascular.hypertrophic = e.data
        break;
      case 16:
        this.cardiovascular.cardiac_option = e.valueRadio
        this.cardiovascular.cardiac = e.data
        break;
      case 17:
        this.cardiovascular.arrhythmia_option = e.valueRadio
        this.cardiovascular.arrhythmia = e.data
        break;
      case 18:
        this.cardiovascular.pacemaker_option = e.valueRadio
        this.cardiovascular.pacemaker = e.data
        break;
      case 19:
        this.cardiovascular.coronary_option = e.valueRadio
        this.cardiovascular.coronary = e.data
        break;
      case 20:
        this.cardiovascular.death_option = e.valueRadio
        this.cardiovascular.death = e.data
        break;
    }
    await this.actionCardiovascular.emit(this.cardiovascular);
  }

  async loadGastrointestinal(e) {
    switch (e.number) {
      case 21:
        this.gastrointestinal.imc = e.data
        this.gastrointestinal.data_imc = e.otherValue
        this.gastrointestinal.imc_height = e.imcHeight
        this.gastrointestinal.imc_weight = e.imcWeight
        break;
      case 22:
        this.gastrointestinal.diarrhea_option = e.valueRadio
        this.gastrointestinal.diarrhea = e.data
        break;
      case 23:
        this.gastrointestinal.constipation_option = e.valueRadio
        this.gastrointestinal.constipation = e.data
        break;
      case 24:
        this.gastrointestinal.weight_option = e.valueRadio
        this.gastrointestinal.weight = e.data
        break;
      case 25:
        this.gastrointestinal.early_satiety_option = e.valueRadio
        this.gastrointestinal.early_satiety = e.data
        break;
      case 26:
        this.gastrointestinal.postprandial_nausea_vomiting_option = e.valueRadio
        this.gastrointestinal.postprandial_nausea_vomiting = e.data
        break;
    }
    await this.actionGastrointestinal.emit(this.gastrointestinal);
  }

  async loadGenitourinario(e) {
    switch (e.number) {
      case 25:
        this.genitourinario.erectile_option = e.valueRadio
        this.genitourinario.erectile = e.data
        break;
      case 26:
        this.genitourinario.neurogenic_option = e.valueRadio
        this.genitourinario.neurogenic = e.data
        break;
      case 27:
        this.genitourinario.itus_option = e.valueRadio
        this.genitourinario.itus = e.data
        break;
      case 28:
        this.genitourinario.proteinuria_option = e.valueRadio
        this.genitourinario.proteinuria = e.data
        break;
      case 29:
        this.genitourinario.renal_option = e.valueRadio
        this.genitourinario.renal = e.data
        this.genitourinario.renal_other = e.otherValue

        break;
    }
    await this.actionGenitourinario.emit(this.genitourinario);
  }

  async loadOphthalmus(e) {
    switch (e.number) {
      case 30:
        this.ophthalmus.eye_option = e.valueRadio
        this.ophthalmus.eye = e.data
        break;
      case 31:
        this.ophthalmus.vitreous_option = e.valueRadio
        this.ophthalmus.vitreous = e.data
        break;
      case 32:
        this.ophthalmus.pupillary_option = e.valueRadio
        this.ophthalmus.pupillary = e.data
        break;
      case 33:
        this.ophthalmus.glaucoma_option = e.valueRadio
        this.ophthalmus.glaucoma = e.data
        break;
      case 34:
        this.ophthalmus.anxiety_option = e.valueRadio
        this.ophthalmus.anxiety = e.data
        break;
      case 35:
        this.ophthalmus.other_option = e.valueRadio
        this.ophthalmus.other_ophthalmus = e.data
        break;
    }
    await this.actionOphthalmus.emit(this.ophthalmus);
  }
}