import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { ConfigStorage } from './config/config.service';

@Injectable({
  providedIn: 'root'
})
export class StorageMasterService {
  storageLoader: boolean = false;
  isAuth: boolean;

  constructor(
    private navCtrl: NavController,
    private config: ConfigStorage) {
  }

  async init() {
    await this.config.init().then((config) => this.isAuth = !!config.token);
    if (this.isAuth) {
         
    }
    this.storageLoader = true;
    return true;
  }

  async clearSession() {
    await this.config.clear();
    this.navCtrl.navigateRoot('/login', {
      skipLocationChange: true
    });
  }
}
