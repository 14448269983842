import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { Storage } from '@ionic/storage';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MedicalHistoryService {

  constructor(
    private http: HttpClient,
    ) { }

  update(body: any, id): Observable<any> {
    return this.http.put(`${environment.API_URL}medicalHistories/${id}`, body)
  }

  save(body: any): Observable<any> {
    return this.http.post(`${environment.API_URL}medicalHistories`, body);
  }

  medicalHistories():  Observable<any> {
    return this.http.get(`${environment.API_URL}medicalHistories`);
  }

  consultationSave(body: any): Observable<any> {
    return this.http.post(`${environment.API_URL}consultation`, body);
  }

  antecedentSave(body: any): Observable<any> {
    return this.http.post(`${environment.API_URL}antecedents`, body);
  }

  antecedentUpdate(body: any, id): Observable<any> {
    return this.http.put(`${environment.API_URL}antecedents/${id}`, body)
  }

  nervousSave(body: any): Observable<any> {
    return this.http.post(`${environment.API_URL}nervouses`, body);
  }

  nervousUpdate(body: any, id): Observable<any> {
    return this.http.put(`${environment.API_URL}nervouses/${id}`, body)
  }
  
  cardiovascularSave(body: any): Observable<any> {
    return this.http.post(`${environment.API_URL}cardiovasculars`, body);
  }

  cardiovascularUpdate(body: any, id): Observable<any> {
    return this.http.put(`${environment.API_URL}cardiovasculars/${id}`, body)
  }

  gastrointestinalSave(body: any): Observable<any> {
    return this.http.post(`${environment.API_URL}gastrointestinals`, body);
  }

  gastrointestinalUpdate(body: any, id): Observable<any> {
    return this.http.put(`${environment.API_URL}gastrointestinals/${id}`, body)
  }

  genitourinarioSave(body: any): Observable<any> {
    return this.http.post(`${environment.API_URL}genitourinarios`, body);
  }

  genitourinarioUpdate(body: any, id): Observable<any> {
    return this.http.put(`${environment.API_URL}genitourinarios/${id}`, body)
  }

  ophthalmusSave(body: any): Observable<any> {
    return this.http.post(`${environment.API_URL}ophthalmuses`, body);
  }

  ophthalmusUpdate(body: any, id): Observable<any> {
    return this.http.put(`${environment.API_URL}ophthalmuses/${id}`, body)
  }

  examSave(body: any): Observable<any> {
    return this.http.post(`${environment.API_URL}exams`, body);
  }

  examUpdate(body: any, id): Observable<any> {
    return this.http.put(`${environment.API_URL}exams/${id}`, body)
  }

  evaluationSave(body: any): Observable<any> {
    return this.http.post(`${environment.API_URL}evaluations`, body);
  }

  evaluationUpdate(body: any, id): Observable<any> {
    return this.http.put(`${environment.API_URL}evaluations/${id}`, body)
  }

  complementarySave(body: any): Observable<any> {
    return this.http.post(`${environment.API_URL}complementaries`, body);
  }

  complementaryUpdate(body: any, id): Observable<any> {
    return this.http.put(`${environment.API_URL}complementaries/${id}`, body)
  }

  scaleSave(body: any): Observable<any> {
    return this.http.post(`${environment.API_URL}scales`, body);
  }

  scaleUpdate(body: any, id): Observable<any> {
    return this.http.put(`${environment.API_URL}scales/${id}`, body)
  }

  treatmentSave(body: any): Observable<any> {
    return this.http.post(`${environment.API_URL}treatments`, body);
  }

  treatmentUpdate(body: any, id): Observable<any> {
    return this.http.put(`${environment.API_URL}treatments/${id}`, body)
  }
  
  deleteConsultation(body: any): Observable<any> {
    return this.http.post(`${environment.API_URL}delete-consultation`, body)
  }
   
  // pdfConsultation(body: any): Observable<any> {
  //   return this.http.post(`${environment.API_URL}pdf-consultation`, body)
  // }

     
  pdfConsultation(body: any): Observable<any> {  
    let headers;
    headers = new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('token')});
    headers.set('Content-Type', 'application/pdf');
    headers.set('Accept', 'application/pdf');
    
    return this.http.post(`${environment.API_URL}pdf-consultation`, body,
    {
      headers: headers,
      responseType: "blob",
      
    });
  }
}
