import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { HttpService } from '../../http/http.service';

@Injectable()
export class RegisterService {

  constructor(
    private httpService : HttpService,
    private http: HttpClient,
    ) {
  }

  // doRegister(form : any) : any {
  //   const params : HttpParams =  new HttpParams()
  //   .append("username", form.username)
  //   .append("email", form.email)
  //   .append("password", form.password);    

  //   return this.httpService.postWithoutToken(`user`, params);
  // }
  doRegister(form : any): Observable<any> {
    return this.http.post(`${environment.API_URL}security/register`, form);
  }
}
