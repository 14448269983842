import { Injectable } from '@angular/core';
import { CanLoad } from '@angular/router';
import { ConfigStorage } from '@app/core/storage';
import { StorageMasterService } from '@app/core/storage/storage-master.service';

/**
 * @class StorageGuard
 * @description Guard of service storage
 */
@Injectable({
    providedIn: 'root'
})
export class StorageGuard implements CanLoad {

    /**
     * Constructor of class
     * @param storageService service Storage
     */
    constructor(
        private storageMasterService: StorageMasterService
    ) { }

    /**
     * @function canLoad
     * @returns {Promise<boolean>}
     * @description load initial values from the `Storage`
     */
    async canLoad() {
        if (!this.storageMasterService.storageLoader) {
            await this.storageMasterService.init();
            return true;
        }
        return true;
    }

}
