import { Component, HostListener, Input, OnInit } from '@angular/core';
import { AppConfig } from '@app/app.config';
import { ModalController, NavController, Platform } from '@ionic/angular';

export class MenuOption {
  id: number;
  title: string;
  btnEndIcon: string;
  constructor(o: any = {}) {
    this.id = o.id;
    this.title = o.title;
    this.btnEndIcon = o.btnEndIcon;
  }
}

@Component({
  selector: 'app-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss'],
})
export class DrawerComponent implements OnInit {

  @Input() options: Array<MenuOption> = Array<MenuOption>();
  logo: string = `${AppConfig.DEFAULT_LOGO}`;

  /*@HostListener('window:resize', ['$event']) public onResize(): void {
    if (this.platform.width() > 768) {
      this.modalCtrl.dismiss();
    }
  }*/

  @HostListener('window:resize', ['$event']) public onResize(event /*provide $event to method here */): void {
    if (this.platform.width() > 768) {
      this.modalCtrl.dismiss();
    }
  }
  
  constructor(
    private modalCtrl: ModalController,
    private platform: Platform,
    private navCtrl: NavController) {
  }

  ngOnInit() {
  }

  action(o) {
    this.modalCtrl.dismiss({option: o.id})
  }

  exit(data = null) {
    this.modalCtrl.dismiss(data)
  }


}
