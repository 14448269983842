import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor(
    private router : Router,
    private menuController : MenuController
  ) { }

    navigate(option: any, params? : any) {
      this.menuController.close();
      if(params) {
        this.router.navigate([option, {params : JSON.stringify(params)}], { replaceUrl: true });
      }
      else {
        this.router.navigate([option]);
      }
    }
}
