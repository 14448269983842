import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { Ionic4DatepickerModule } from '@logisticinfotech/ionic4-datepicker';
import {
  BibliographyComponent,
  BirthdayComponent,
  DrawerComponent,
  FilesComponent,
  GenogramComponent,
  HelpComponent,
  HistoryFirstComponent,
  HistorySecondComponent,
  HistoryThirdComponent,
  ImportTreeComponent,
  InputGenericComponent,
  LoaderComponent,
  NavBarComponent,
  OutSesionPopoverComponent,
  ProfileComponent,
  RatingComponent,
  RegisterConsultionComponent,
  SearchTextComponent,
  SelectNumberComponent,
} from './components';
import { IonMediaCacheModule } from 'ion-media-cache';
import { BrMaskerModule } from 'br-mask';
import { InputEmptyDirective } from './directives/input-empty/input-empty.directive';
import { FixIconDirective } from './directives/fix-icon.directive';

@NgModule({
  imports: [
    BrMaskerModule,
    CommonModule,
    FormsModule,
    IonMediaCacheModule,
    Ionic4DatepickerModule,
    IonicModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  exports: [
    BibliographyComponent,
    BirthdayComponent,
    DrawerComponent,
    FilesComponent,
    GenogramComponent,
    HelpComponent,
    HistoryFirstComponent,
    HistorySecondComponent,
    HistoryThirdComponent,
    ImportTreeComponent,
    InputGenericComponent,
    LoaderComponent,
    NavBarComponent,
    OutSesionPopoverComponent,
    ProfileComponent,
    RatingComponent,
    RegisterConsultionComponent,
    SearchTextComponent,
    SelectNumberComponent,
    /**Directive */
    InputEmptyDirective,
    FixIconDirective
  ],
  declarations: [
    BibliographyComponent,
    BirthdayComponent,
    DrawerComponent,
    FilesComponent,
    GenogramComponent,
    HelpComponent,
    HistoryFirstComponent,
    HistorySecondComponent,
    HistoryThirdComponent,
    ImportTreeComponent,
    InputGenericComponent,
    LoaderComponent,
    NavBarComponent,
    OutSesionPopoverComponent,
    ProfileComponent,
    RatingComponent,
    RegisterConsultionComponent,
    SearchTextComponent,
    SelectNumberComponent,
    /**Directive */
    InputEmptyDirective,
    FixIconDirective
  ],
  entryComponents: [
    BibliographyComponent,
    BirthdayComponent,
    DrawerComponent,
    FilesComponent,
    GenogramComponent,
    HelpComponent,
    HistoryFirstComponent,
    HistorySecondComponent,
    HistoryThirdComponent,
    ImportTreeComponent,
    InputGenericComponent,
    LoaderComponent,
    NavBarComponent,
    OutSesionPopoverComponent,
    ProfileComponent,
    RatingComponent,
    RegisterConsultionComponent,
    SearchTextComponent,
    SelectNumberComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }