import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { ConfigStorage } from '@app/core/storage';
import { StorageMasterService } from '@app/core/storage/storage-master.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
   /**
     * Constructor of class
     * @param configStorage config storage used the token to validate
     * @param storageMasterService service used to navegate login
     */
  constructor(
    private configStorage: ConfigStorage,
    private storageMasterService: StorageMasterService) {
  }

   /**
   * @async method
   * @function canActivate validate if token if not back to login
   * @returns {Promise<boolean>}
   */
  async canActivate() {
    if (this.configStorage.get().token) {
      return true;
    }
    this.storageMasterService.clearSession();
    return false;
  }
}
