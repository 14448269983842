import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));



window['bmi'] = function (height,weight){

    if(isNaN(height) || isNaN(weight)){
        return console.error("ERR ! \n The height or weight isn't a number")
    }

    let bmi = Math.round(weight/(height * height) * 10)/10
    
    var str;

    if(bmi < 18.5){
        str = 0;
    } else if(18.5 <= bmi && bmi < 25){
        str = 1
    } else if( 25 <= bmi && bmi < 30){
        str = 2
    } else if( 35 <= bmi && bmi < 40){
        str = 3
    } else if(40 <= bmi){
        str = 4
    }


    return {
        bmi : bmi,
        type : str
    }


}
