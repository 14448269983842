import { Component, EventEmitter, HostListener, Input, Output, Renderer2 } from '@angular/core';
import { GlobalService } from '@app/core/services/global/global.service';
import { BinnaclePage } from '@app/pages/home/binnacle/binnacle.page';
import { SearchPage } from '@app/pages/home/search/search.page';
import { ModalController, Platform } from '@ionic/angular';
import * as _ from 'lodash';
import * as printJS from "print-js";
declare var go;
declare var window;
const goKey = 'genogram';
const keys = ["key", "n", "s", "f", "m", "ux", "tw", "vir", "cs", "a", "type_relationship", "birthdate", "pregnant", "state_test", "stroke", "tooltips", "gen", 'city', 'country', 'city_name']; // "loc"
@Component({
  selector: 'genogram',
  templateUrl: './genogram.component.html',
  styleUrls: ['./genogram.component.scss'],
})
export class GenogramComponent {

  isModal: boolean;
  height: number;
  isAlert: boolean;
  viewInfoMessage: boolean = false;
  @Input() isGeneration;
  @Input() set isLocation(isLocation: boolean) {
    if (!window[goKey]) {
      window[goKey] = {};
    }
    window[goKey]['isLocation'] = isLocation;
  };
  @Input() blockAxiseY;

  @Input() selectOption;
  @Input('patient') patient;
  @Input('readonly') readonly;
  @Output() onSelectNode = new EventEmitter<any>();
  @Output() onSelectNodeMove = new EventEmitter<any>();
  @Output() onDropTree = new EventEmitter<any>();

  //@HostListener('window:resize', ['$event']) public onResize(): void {
  @HostListener('window:resize', ['$event']) public onResize(event /*provide $event to method here */): void {
    this.setHeight();
    this.setWidth();
  }

  constructor(
    private platform: Platform,
    private render: Renderer2,
    private global: GlobalService,
    private modalCtrl: ModalController) {
    this.setWidth();
  }

  get mobileweb(): boolean {
    return this.platform.is('mobileweb');
  }

  setHeight() {
    const col = document.getElementById('col-options');
    const height = this.platform.height() - (this.mobileweb ? 185 : 174);
    this.height = !col ? height : height - col.offsetHeight;
  }

  setWidth() {
    if (this.platform.width() > 576) {
      this.viewInfoMessage = false;
    } else {
      this.viewInfoMessage = false;
    }
  }

  refresh(nodeDataArray, layoutTitle = '') {
    this.setHeight();
    if (!window[goKey]) {
      window[goKey] = {};
    }
    window[goKey].nodeDataFullArray = nodeDataArray;

    const findGenF = (arr, key = 1, i = 0, gen = 0) => {
      const n = _.find(arr, {key});      
      if (n) {
        if (!!n && !!n.f) {
          i = i + 1;
          gen = Math.max(findGenF(arr, n.f, i, gen), i);
        }
      }
      return gen;
    }
    const findGenM = (arr, key = 1, i = 0, gen = 0) => {
      const n = _.find(arr, {key});      
      if (n) {
        if (!!n && !!n.m) {
          i = i + 1;
          gen = Math.max(findGenM(arr, n.m, i, gen), i);
        }
      }
      return gen;
    }


    const findGen = (arr, key = 1, i = 0, gen = 0) => {
      const n = _.find(arr, {key});      
      if (n) {
        if (!!n && !!n.f && !!n.m) {
          i = i + 1;
          gen = Math.max(findGen(arr, n.m, i, gen), findGen(arr, n.f, i, gen), i);
        }
      }
      return gen;
    }
    
    if (window[goKey].diagram && window[goKey].diagram.div) {
      window[goKey].diagram.div = null;
      window[goKey].layoutTitle = layoutTitle;
    }
    this.init(nodeDataArray.map((x) => {
      const arr = [
        findGenF(nodeDataArray.map((x) => {
        return _.pick(x, ['key', 'm', 'f', 'ux', 'vir'])
      }), x.key) + 1,
      findGenM(nodeDataArray.map((x) => {
        return _.pick(x, ['key', 'm', 'f', 'ux', 'vir'])
      }), x.key) + 1,
      findGen(nodeDataArray.map((x) => {
        return _.pick(x, ['key', 'm', 'f', 'ux', 'vir'])
      }), x.key) + 1];
      x.gen = Math.max(arr[0], arr[1], arr[2]);
      // console.log(arr, x.gen);
      x.ux = typeof x.ux === 'number' ? [x.ux] : x.ux || [];
      x.vir = typeof x.vir === 'number' ? [x.vir] : x.vir || [];
      x.ux = x.ux.filter((key) => {
        return !!_.find(nodeDataArray, { key });
      });
      x.vir = x.vir.filter((key) => {
        return !!_.find(nodeDataArray, { key });
      });
      if (!x.a) {
        x.a = [];
      }
      if (x.state === '2' && x.a.indexOf('S') === -1) {
        x.a.push('S');
      }
      return _.pick(_.pickBy(x, _.identity), keys.concat(window[goKey].isLocation ? ['loc'] : []));
    }));
  }

  init(nodeDataArray) {
    window[goKey].visibleGen = _.map(_.range((_.maxBy(nodeDataArray, 'gen') || {}).gen || 0), () => true);
    window[goKey].isGeneration = this.isGeneration;
    window[goKey].blockAxiseY = this.blockAxiseY;
    window[goKey].allowMove = this.readonly ? false : window[goKey].isLocation;
    // console.log(this.selectOption, 'this.selectOption');
    window[goKey].selectOption = this.selectOption;
    window[goKey].nodeDataArray = nodeDataArray;
    window[goKey].nodeDataArray.map((x) => {
      x.loc = x.loc || null;
      if (this.selectOption === '2' || this.selectOption === '3') {
        x.ax = x.a.slice(0, 4).map((value, index) => {
          return {
            value,
            index
          };
        });
      } else {
        x.a = x.a || [];
        if (x.a.indexOf('S') > -1) {
          x.a = ['S'];
          x.ax = [{
            value: 'S',
            index: 0
          }]
        } else {
          x.a = x.ax = [];
        }
      }
      return x;
    });
    window[goKey].phenotypeList = this.global.phenotypeList;
    window[goKey].onSelectNode = this.onSelectNode;
    window[goKey].onSelectNodeMove = this.onSelectNodeMove;
    window[goKey].zoomInButtonClickCount = 1; // number of zooms
    window[goKey].scaleLevelChange = 0.1; // number scale of zooms 
    window[goKey].init = function init() {
      var $ = go.GraphObject.make;
      // window[goKey].licenseKey = "YourKeyHere";
      if (!!window[goKey].diagram) {
        window[goKey].diagram.div = null;
      }
      window[goKey].diagram =
        $(go.Diagram, "myDiagramDiv",
          {
            initialAutoScale: go.Diagram.Uniform, allowMove: window[goKey].allowMove,
            "undoManager.isEnabled": true,
            // when a node is selected, draw a big yellow circle behind it
            nodeSelectionAdornmentTemplate:
              $(go.Adornment, "Auto",
                { layerName: "Grid" },  // the predefined layer that is behind everything else
                $(go.Shape, "Circle", { fill: "#c1cee3", stroke: null }),
                $(go.Placeholder, { margin: 2 })
              ),
            layout:  // use a custom layout, defined below
              $(GenogramLayout, { direction: 90, layerSpacing: 52, columnSpacing: 21 })
          });
      window[goKey].zoom = function (scaleLevel) {
        if (typeof scaleLevel === 'number' && window['genogram'].diagram.commandHandler.canIncreaseZoom()) {
          // window['genogram'].diagram.commandHandler.reset();
          window['genogram'].diagram.commandHandler.increaseZoom(scaleLevel);
          window['genogram'].diagram.scale = scaleLevel;
        }
      }

      // determine the color for each attribute shape
      function attrFill(a) {
        if (window[goKey].selectOption === '2') {
          if (a.value === 'S') {
            return '#000000';
          }
          return window[goKey].phenotypeList[['A', 'B', 'C', 'D'].indexOf(a.value)].stroke || '#840000';
        }
        switch (a.value) {
          case "A": return "#d4071c"; // red
          case "B": return "#f27935"; // orange
          case "C": return "#7adca6"; // green
          case "D": return "#70bdc2"; // cyan
          case "E": return "#fcf384"; // gold
          case "F": return "#0f54a5"; // blue
          case "G": return "#858585"; // gray
          case "H": return "#866310"; // brown
          case "I": return "#9270c2"; // purple
          case "J": return "#a3cf62"; // chartreuse
          case "K": return "#f9d31d"; // yellow
          case "L": return "#af70c2"; // magenta
          case "M": return "#C0C0C0"; // Silver
          case "N": return "#800000"; // Maroon
          case "O": return "#808000"; // Olive
          case "P": return "#00FF00"; // Lime
          case "Q": return "#00FFFF"; // Aqua
          case "R": return "#008080"; // Teal
          case "S": return "#000000"; // Navy
          case "T": return "#FF00FF"; // Fuchsia
          case "U": return "#DC143C"; // crimson
          case "V": return "#00008B"; // darkblue
          case "W": return "#B8860B"; // darkgoldenrod
          case "X": return "#E9967A"; // darksalmon
          case "Y": return "#20B2AA"; // lightseagreen
          case "Z": return "#48D1CC"; // mediumturquoise
          default: return "transparent";
        }
      }

      // determine the geometry for each attribute shape in a male;
      // except for the slash these are all squares at each of the four corners of the overall square
      var tlsq = go.Geometry.parse("F M1 1 l19 0 0 19 -19 0z");
      var trsq = go.Geometry.parse("F M20 1 l19 0 0 19 -19 0z");
      var brsq = go.Geometry.parse("F M20 20 l19 0 0 19 -19 0z");
      var blsq = go.Geometry.parse("F M1 20 l19 0 0 19 -19 0z");

      var slash = go.Geometry.parse("F M38 0 L40 0 40 2 2 40 0 40 0 38z");
      function maleGeometry(a) {
        if (window[goKey].selectOption === '2') {
          switch (a.value) {
            case "A": return tlsq;
            case "B": return trsq;
            case "C": return brsq;
            case "D": return blsq;
            case "S": return slash;
            default: return tlsq;
          }
        }
        if (a.value === 'S') {
          return slash;
        }
        return [tlsq, trsq, brsq, blsq][a.index];
      }

      // determine the geometry for each attribute shape in a female;
      // except for the slash these are all pie shapes at each of the four quadrants of the overall circle
      var tlarc = go.Geometry.parse("F M20 20 B 180 90 20 20 19 19 z");
      var trarc = go.Geometry.parse("F M20 20 B 270 90 20 20 19 19 z");
      var brarc = go.Geometry.parse("F M20 20 B 0 90 20 20 19 19 z");
      var blarc = go.Geometry.parse("F M20 20 B 90 90 20 20 19 19 z");
      var slash = go.Geometry.parse("F M38 0 L40 0 40 2 2 40 0 40 0 38z");

      function femaleGeometry(a) {
        if (window[goKey].selectOption === '2') {
          switch (a.value) {
            case "A": return tlarc;
            case "B": return trarc;
            case "C": return brarc;
            case "D": return blarc;
            case "S": return slash;
            default: return tlarc;
          }
        }
        if (a.value === 'S') {
          return slash;
        }
        return [tlarc, trarc, brarc, blarc][a.index];
      }

      // determine the geometry for each attribute shape in a female;
      // except for the slash these are all pie shapes at each of the four quadrants of the overall diamond
      var ntlarc = go.Geometry.parse("F M1 1 l0 0 0 20 -20 0z");
      var ntrarc = go.Geometry.parse("F M20 1 l0 0 0 20 20 0z");
      var nbrarc = go.Geometry.parse("F M20 20 l0 20 20 -20 1 0z");
      var nblarc = go.Geometry.parse("F M1 20 l20 0 0 19 -1 0z");
      var ntlarc1 = go.Geometry.parse("F M20 20 B 180 22.5 20 20 15 15 z");
      var slash = go.Geometry.parse("F M38 0 L40 0 40 2 2 40 0 40 0 38z");
      
      function noGenderGeometry(a) {
        if (window[goKey].selectOption === '2') {
          switch (a.value) {
            case "A": return ntlarc;
            case "B": return ntrarc;
            case "C": return nbrarc;
            case "D": return nblarc;
            case "S": return slash;
            default: return ntlarc1;
          }
        }
        if (a.value === 'S') {
          return slash;
        }
        return [ntlarc, ntrarc, nbrarc, nblarc][a.index];
      }

      // 'M 99.600,345.800 40.011799,297.89116 176.42132,163.50189 86.328211,128.72762 350.27967,49.535242 276.59474,270.22749 225.78192,205.84351 Z')) 
      function arrow(node) {
        return node.key === 1 ? go.Geometry.parse(go.Geometry.fillPath('M 99.877538,345.959 43.011799,297.89116 177.42132,163.50189 86.328211,128.72762 350.27967,49.535242 268.59474,270.22749 226.78192,205.84351 Z')) : '';//"F M120 0 L80 80 0 50z"
      }

      function attrStrok(s) {
        return s.stroke ?? '#424242';
      }

      function attrKey(data) {
        return data.stroke;
        return data.key == 1 ? 'lightgray' : 'white';
      }

      function findState(data) {
        switch (data.state_test) {
          case '2':
            return '+';
          case '1':
            return '-';
          default:
            return '';
        }
      }

      window[goKey].diagram.addDiagramListener("ViewportBoundsChanged", function (e) {
        e.diagram.commit(function (dia) {
          // only iterates through simple Parts in the diagram, not Nodes or Links
          dia.parts.each(function (part) {
            // console.log(part)
            // and only on those that have the "_viewPosition" property set to a Point
            if (part._viewPosition) {
              part.position = dia.transformViewToDoc(part._viewPosition);
              part.scale = 1 / dia.scale;
            }
          })
        }, "fix Parts");
      });

      if (window[goKey].isLocation) {
        window[goKey].diagram.addDiagramListener("SelectionMoved", function (e) {
          let part = e.subject.first();
          window[goKey].onSelectNodeMove.emit({ position: part.position, node: part.data });
        });
      }

      window[goKey].diagram.commandHandler.doKeyDown = function () {
        return;
      };

      window[goKey].diagram.addDiagramListener("ChangedSelection", function (e) {
        var part = e.subject.first(); // e.subject is the myDiagram.selection collection,
        if (part instanceof go.Node) {
          const key = part.lb.key;
          const node = _.find(window[goKey].nodeDataFullArray, { key });
          window[goKey].selectedNode = node;
          localStorage.setItem('genogramIndex', `${node.key}`);
          window[goKey].onSelectNode.emit(node);
        }
      })
      window[goKey].marginGenV = 61; 
      window[goKey].marginGen = new go.Margin(window[goKey].marginGenV, window[goKey].marginGenV, window[goKey].marginGenV, window[goKey].marginGenV);

      window[goKey].diagram.add(
        $(go.Part, "Table",
          { position: new go.Point(-150, -60), selectable: false, visible: window[goKey].isGeneration },
          $(go.TextBlock, "Generaciones",
            { row: 0, font: "700 14px Droid Serif, sans-serif" }),  // end row 0
          $(go.Panel, "Horizontal",
            { row: 1, alignment: go.Spot.Left, visible: window[goKey].visibleGen[0] },
            $(go.TextBlock, "I",
              { font: "700 13px Droid Serif, sans-serif", margin: window[goKey].marginGen })
          ),  // end row 1
          $(go.Panel, "Horizontal",
            { row: 2, alignment: go.Spot.Left, visible: window[goKey].visibleGen[1] },
            $(go.TextBlock, "II",
              { font: "700 13px Droid Serif, sans-serif", margin: window[goKey].marginGen })
          ),  // end row 2
          $(go.Panel, "Horizontal",
            { row: 3, alignment: go.Spot.Left, visible: window[goKey].visibleGen[2] },
            $(go.TextBlock, "III",
              { font: "700 13px Droid Serif, sans-serif", margin: window[goKey].marginGen })
          ),  // end row 3
          $(go.Panel, "Horizontal",
            { row: 4, alignment: go.Spot.Left, visible: window[goKey].visibleGen[3] },
            $(go.TextBlock, "IV",
              { font: "700 13px Droid Serif, sans-serif", margin: window[goKey].marginGen })
          ),  // end row 4
          $(go.Panel, "Horizontal",
            { row: 5, alignment: go.Spot.Left, visible: window[goKey].visibleGen[4] },
            $(go.TextBlock, "V",
              { font: "700 13px Droid Serif, sans-serif", margin: window[goKey].marginGen })
          ),  // end row 5
          $(go.Panel, "Horizontal",
            { row: 6, alignment: go.Spot.Left, visible: window[goKey].visibleGen[5] },
            $(go.TextBlock, "VI",
              { font: "700 13px Droid Serif, sans-serif", margin: window[goKey].marginGen })
          ),  // end row 6
          $(go.Panel, "Horizontal",
          { row: 7, alignment: go.Spot.Left, visible: window[goKey].visibleGen[6] },
          $(go.TextBlock, "VII",
            { font: "700 13px Droid Serif, sans-serif", margin: window[goKey].marginGen })
        )  // end row 7
        ));

      // two different node templates, one for each sex,
      // named by the category value in the node data object
      window[goKey].diagram.nodeTemplateMap.add("M",  // male
        $(go.Node, "Vertical",
          { locationSpot: go.Spot.Center, locationObjectName: "ICON", selectionObjectName: "ICON",minLocation: new go.Point(-Infinity, window[goKey].blockAxiseY ? NaN : -Infinity), maxLocation: new go.Point(Infinity, window[goKey].blockAxiseY ? NaN : Infinity) },
          new go.Binding('position', 'loc', go.Point.parse).makeTwoWay(go.Point.stringify),
          $(go.Panel,
            { name: "ICON" },
            $(go.Shape, "Square",
              { width: 40, height: 40, strokeWidth: 2, fill: "white", stroke: '#919191', portId: "" },
              new go.Binding("stroke", "", attrStrok),
              new go.Binding("fill", "", attrKey),
            ),
            $(go.Panel,
              { // for each attribute show a Shape at a particular place in the overall square
                itemTemplate:
                  $(go.Panel,
                    $(go.Shape,
                      { stroke: null, strokeWidth: 0 },
                      new go.Binding("fill", "", attrFill),
                      new go.Binding("geometry", "", maleGeometry),
                      // new go.Binding("location", "", attrLocation)
                    ),
                  ),
                margin: 1
              },
              new go.Binding("itemArray", "ax"),
            ),
            $(go.TextBlock,
              { verticalAlignment: go.Spot.Center, textAlign: "center", width: 40, height: 40, font: "bold 14pt serif" },
              new go.Binding("text", (data) => {
                return data.type_relationship == '3' ? 'E' : data.n;
              })),
            {
              toolTip:  // define a tooltip for each node that displays the color as text
                $("ToolTip",
                  $(go.TextBlock, { margin: 4 },
                    new go.Binding("text", "tooltips"))
                )  // end of Adornment
            },
          ),
          $(go.TextBlock,
            { alignment: new go.Spot(1, 1, 0, -5), font: "bold 15pt serif" },
            new go.Binding("text", "", findState)
          ),
          $(go.Shape,
            {
              width: 0, height: 0, strokeWidth: 0, margin: 1, alignment: new go.Spot(0, 0, 0, -22),
              // but disallow drawing links from or to this shape:
              fromLinkable: false, toLinkable: false
            },
            new go.Binding("width", "", (node) => {
              return node.key === 1 ? 15 : 0
            }),
            new go.Binding("height", "", (node) => {
              return node.key === 1 ? 15 : 0
            }),
            new go.Binding("geometry", "", arrow)
          ),
        ));

      window[goKey].diagram.nodeTemplateMap.add("F",  // female
        $(go.Node, "Vertical",
          { locationSpot: go.Spot.Center, locationObjectName: "ICON", selectionObjectName: "ICON",minLocation: new go.Point(-Infinity, window[goKey].blockAxiseY ? NaN : -Infinity), maxLocation: new go.Point(Infinity, window[goKey].blockAxiseY ? NaN : Infinity) },
          new go.Binding('position', 'loc', go.Point.parse).makeTwoWay(go.Point.stringify),
          $(go.Panel,
            { name: "ICON" },
            $(go.Shape, "Circle",
              { width: 40, height: 40, strokeWidth: 2, fill: "white", portId: "" },
              new go.Binding("stroke", "", attrStrok),
              new go.Binding("fill", "", attrKey),
            ),
            $(go.Panel,
              { // for each attribute show a Shape at a particular place in the overall circle
                itemTemplate:
                  $(go.Panel,
                    $(go.Shape,
                      { stroke: null, strokeWidth: 0 },
                      new go.Binding("fill", "", attrFill),
                      new go.Binding("geometry", "", femaleGeometry)
                    )
                  ),
                margin: 1
              },
              new go.Binding("itemArray", "ax")
            ),
            $(go.TextBlock,
              { verticalAlignment: go.Spot.Center, textAlign: "center", width: 40, height: 40, font: "bold 14pt serif" },
              new go.Binding("text", (data) => {
                return data.type_relationship == '3' ? 'E' : data.n;
              })),
            /*  $(go.TextBlock,
                { verticalAlignment: go.Spot.Center, textAlign: "center", width: 11, height: 15, font: "bold 11pt serif", margin: -2 },
                new go.Binding("text", "pregnant"),
                new go.Binding("background", "pregnant", function (p) {
                  return p === 'E' ? 'white' : 'transparent'
                })),*/
            //$(go.Shape, "Circle",
            //  { stroke: null, strokeWidth: 0, width: 10, height: 10 }),
            {
              toolTip:  // define a tooltip for each node that displays the color as text
                $("ToolTip",
                  $(go.TextBlock, { margin: 4 },
                    new go.Binding("text", "tooltips"))
                )  // end of Adornment
            }
          ),
          $(go.Shape,
            {
              width: 0, height: 0, strokeWidth: 0, margin: 1, alignment: go.Spot.TopLeft,
              // but disallow drawing links from or to this shape:
              fromLinkable: false, toLinkable: false
            },
            new go.Binding("width", "", (node) => {
              return node.key === 1 ? 15 : 0
            }),
            new go.Binding("height", "", (node) => {
              return node.key === 1 ? 15 : 0
            }),
            new go.Binding("geometry", "", arrow)
          ),
          $(go.TextBlock,
            { alignment: new go.Spot(1, 1, 0, -10), font: "bold 15pt serif" },
            new go.Binding("text", "", findState)
          ),
        ));

      window[goKey].diagram.nodeTemplateMap.add("N",  // No Gender
        $(go.Node, "Vertical",
          { locationSpot: go.Spot.Center, locationObjectName: "ICON", selectionObjectName: "ICON",minLocation: new go.Point(-Infinity, window[goKey].blockAxiseY ? NaN : -Infinity), maxLocation: new go.Point(Infinity, window[goKey].blockAxiseY ? NaN : Infinity) },
          new go.Binding('position', 'loc', go.Point.parse).makeTwoWay(go.Point.stringify),
          $(go.Panel,
            { name: "ICON" },
            $(go.Shape, "Diamond",
              { width: 40, height: 40, strokeWidth: 2, fill: "white", stroke: "#a1a1a1", portId: "" },
              new go.Binding("stroke", "", attrStrok),
              new go.Binding("fill", "", attrKey)
            ),
            $(go.Panel,
              { // for each attribute show a Shape at a particular place in the overall diamond
                itemTemplate:
                  $(go.Panel,
                    $(go.Shape,
                      { stroke: null, strokeWidth: 0 },
                      new go.Binding("fill", "", attrFill),
                      new go.Binding("geometry", "", noGenderGeometry))
                  ),
                margin: 1
              },
              new go.Binding("itemArray", "ax")
            ),
            $(go.TextBlock,
              { verticalAlignment: go.Spot.Center, textAlign: "center", width: 40, height: 40, font: "bold 13pt serif" },
              new go.Binding("text", (data) => {
                return data.type_relationship == '3' ? 'E' : data.n;
              })),
            {
              toolTip:  // define a tooltip for each node that displays the color as text
                $("ToolTip",
                  $(go.TextBlock, { margin: 4 },
                    new go.Binding("text", "tooltips"))
                )  // end of Adornment
            }
          ),
          $(go.Shape,
            {
              width: 0, height: 0, strokeWidth: 0, margin: 1, alignment: go.Spot.TopLeft,
              // but disallow drawing links from or to this shape:
              fromLinkable: false, toLinkable: false
            },
            new go.Binding("width", "", (node) => {
              return node.key === 1 ? 15 : 0
            }),
            new go.Binding("height", "", (node) => {
              return node.key === 1 ? 15 : 0
            }),
            new go.Binding("geometry", "", arrow),
          ),
          $(go.TextBlock,
            { alignment: new go.Spot(1, 1, 0, -15), font: "bold 15pt serif" },
            new go.Binding("text", "", findState)
          ),

        ));

      window[goKey].diagram.nodeTemplateMap.add("NB",  // No Gender
        $(go.Node, "Vertical",
          { locationSpot: go.Spot.Center, locationObjectName: "ICON", selectionObjectName: "ICON",minLocation: new go.Point(-Infinity, window[goKey].blockAxiseY ? NaN : -Infinity), maxLocation: new go.Point(Infinity, window[goKey].blockAxiseY ? NaN : Infinity) },
          new go.Binding('position', 'loc', go.Point.parse).makeTwoWay(go.Point.stringify),
          $(go.Panel,
            { name: "ICON" },
            $(go.Shape, "Diamond",
              { width: 40, height: 45, strokeWidth: 2, fill: "white", stroke: "#a1a1a1", portId: "" },
              new go.Binding("stroke", "", attrStrok),
              new go.Binding("fill", "", attrKey)
            ),
            $(go.Panel,
              { // for each attribute show a Shape at a particular place in the overall diamond
                itemTemplate:
                  $(go.Panel,
                    $(go.Shape,
                      { stroke: null, strokeWidth: 0 },
                      new go.Binding("fill", "", attrFill),
                      new go.Binding("geometry", "", noGenderGeometry))
                  ),
                margin: 1
              },
              new go.Binding("itemArray", "ax")
            ),
            $(go.TextBlock,
              { verticalAlignment: go.Spot.Center, textAlign: "center", width: 40, height: 48, font: "bold 14pt serif" },
              new go.Binding("text", (data) => {
                return data.type_relationship == '3' ? 'E' : data.n;
              })),
            {
              toolTip:  // define a tooltip for each node that displays the color as text
                $("ToolTip",
                  $(go.TextBlock, { margin: 4 },
                    new go.Binding("text", "tooltips"))
                )  // end of Adornment
            }
          ),
          $(go.Shape,
            {
              width: 0, height: 0, strokeWidth: 0, margin: 1, alignment: go.Spot.TopLeft,
              // but disallow drawing links from or to this shape:
              fromLinkable: false, toLinkable: false
            },
            new go.Binding("width", "", (node) => {
              return node.key === 1 ? 15 : 0
            }),
            new go.Binding("height", "", (node) => {
              return node.key === 1 ? 15 : 0
            }),
            new go.Binding("geometry", "", arrow)
          ),
          $(go.TextBlock,
            { alignment: new go.Spot(1, 1, 0, -15), font: "bold 15pt serif" },
            new go.Binding("text", "", findState)
          ),
        ));

      // the representation of each label node -- nothing shows on a Marriage Link
      window[goKey].diagram.nodeTemplateMap.add("LinkLabel", $(go.Node,
        { selectable: false, width: 1, height: 1, fromEndSegmentLength: 20,minLocation: new go.Point(-Infinity, window[goKey].blockAxiseY ? NaN : -Infinity), maxLocation: new go.Point(Infinity, window[goKey].blockAxiseY ? NaN : Infinity) })
      );

      window[goKey].diagram.linkTemplateMap.add("Consanguineous",  // for consanguineous relationships
        $(go.Link,
          { layerName: "Background", selectable: false, isTreeLink: false, isLayoutPositioned: false },
          $(go.Shape, {
            isPanelMain: true, stroke: "#efeee9",
            pathPattern: $(go.Shape,
              {
                geometryString: `M0 0 L1 0 M0 3 L1 3`,
                fill: `transparent`,
                stroke: `#424242`,
                // strokeWidth: 1.25,
              }
            )
          })
        ));
      window[goKey].diagram.linkTemplateMap.add("Marriage",  // for marriage relationships
        $(go.Link,
          { layerName: "Background", selectable: false, isTreeLink: false, isLayoutPositioned: false },
          // $(go.Shape, { strokeWidth: 2, stroke: "#424242" })
          $(go.Shape, {
            isPanelMain: true, stroke: "#efeee9",
            pathPattern: $(go.Shape,
              {
                geometryString: `M0 0 L1 0 M0 0 L1 0`,
                fill: `transparent`,
                stroke: `#424242`,
                strokeWidth: 1.25,
              }
            ),
          })
        ));

      window[goKey].diagram.linkTemplateMap.add("MarriageCs",  // for marriage relationships
        $(go.Link,
          { layerName: "Background", selectable: false, isTreeLink: false, isLayoutPositioned: false },
          // $(go.Shape, { strokeWidth: 2, stroke: "#424242" })
          $(go.Shape, {
            isPanelMain: true, stroke: "#efeee9",
            pathPattern: $(go.Shape,
              {
                geometryString: `M0 0 L1 0 M0 3 L1 3`,
                fill: `transparent`,
                stroke: `#424242`,
                strokeWidth: 1.25,
              }
            ),
          })
        ));

      window[goKey].diagram.linkTemplate =  // for parent-child relationships
        $(TwinLink,  // custom routing for same birth siblings
          {
            routing: go.Link.Orthogonal,
            layerName: "Background",
            selectable: false,
            isTreeLink: true,
            fromSpot: go.Spot.Bottom,
            toSpot: go.Spot.Top,
          },
          $(go.Shape, { strokeWidth: 2, stroke: "#424242" })
        );

      window[goKey].diagram.linkTemplateMap.add("TwinLinkIdentical",  // for connecting twins/triplets
        $(go.Link,
          { selectable: false, isTreeLink: false, isLayoutPositioned: false },
          {
            fromSpot: go.Spot.Top,
            toSpot: go.Spot.Top,
            fromEndSegmentLength: 10,
            toEndSegmentLength: 10,
            //      curve: go.Link.Bezier
          },
          $(go.Shape, { strokeWidth: 2, stroke: "transparent" }),

          $(go.Shape, 'Diamond', { width: 90, height: 1 }),


          /* $(go.Shape, {
             isPanelMain: true, stroke: "#efeee9",
             pathPattern: $(go.Shape,
               {
                 width: 25,
                 height: 10,
                 geometryString: 'M -18 50 L -40 50 z',//`M 85 50 L -40 50 z`,
                 fill: `green`,
                 stroke: `#424242`,
                 background: 'green',
                 strokeWidth: 2,
               }
             )
           }),*/


        ));

      window[goKey].diagram.add(
        $(go.Part, {
          layerName: "Grid",  // must be in a Layer that is Layer.isTemporary,
          // to avoid being recorded by the UndoManager
          _viewPosition: new go.Point(10, 0)  // some position in the viewport,
          // not in document coordinates
        },
          $(go.TextBlock, window[goKey].layoutTitle || '', { font: "bold 12pt sans-serif", stroke: "black" }),
        )
      );

      // n: name, s: sex, m: mother, f: father, ux: wife, vir: husband, a: attributes/markers
      setupDiagram(window[goKey].diagram, parseInt(localStorage.getItem('genogramIndex') || '1') /* focus on this person */);
    }

    // create and initialize the Diagram.model given an array of node data representing people
    function setupDiagram(diagram, focusId) {
      //Primer pase, se usa para jinicializar el diagrama con la data enlazada
      diagram.model =
        go.GraphObject.make(go.GraphLinksModel,
          { // declare support for link label nodes
            linkLabelKeysProperty: "labelKeys",
            // this property determines which template is used
            nodeCategoryProperty: "s",
            // if a node data object is copied, copy its data.a Array
            copiesArrays: true,
            // create all of the nodes for people
            nodeDataArray: window[goKey].nodeDataArray
          });

      let nr = setupMarriages(diagram);
      //se actualiza con los datos de las parejas cargadas
      for (var q = 0; q < nodeDataArray.length; q++) {
        nodeDataArray[q].loc = nr[q].loc;
      }

      nr = setupParents(diagram);
      //se actualiza con los datos de los padres cargados
      for (var q = 0; q < nodeDataArray.length; q++) {
        nodeDataArray[q].loc = nr[q].loc;
      }
      //se actualiza con los datos de los gemelos cargados
      nr = setupIdenticalTwins(diagram);
      for (var q = 0; q < nodeDataArray.length; q++) {
        nodeDataArray[q].loc = nr[q].loc;
      }

      //se actualiza con los datos de los hermanos cargados
      for (var q = 0; q < nodeDataArray.length; q++) {
        if(nodeDataArray[q].m != undefined) {
          for (var w = (q+1); w < nodeDataArray.length; w++) {
            if((nodeDataArray[q].m == nodeDataArray[w].m) && !!nodeDataArray[q].loc && !!nodeDataArray[w].loc) {

              let loc = nodeDataArray[q].loc.split(" ");
              let loc2 = nodeDataArray[w].loc.split(" ");
              loc[1] = loc2[1];
    
              nodeDataArray[q].loc = loc.join(" ");
              nodeDataArray[w].loc = loc2.join(" ");
            }
          }
        }
        nodeDataArray[q].loc = nr[q].loc;
      }

      diagram.model =
      go.GraphObject.make(go.GraphLinksModel,
        { // declare support for link label nodes
          linkLabelKeysProperty: "labelKeys",
          // this property determines which template is used
          nodeCategoryProperty: "s",
          // if a node data object is copied, copy its data.a Array
          copiesArrays: true,
          // create all of the nodes for people
          nodeDataArray: window[goKey].nodeDataArray
        });

      //se actualiza con los datos de las gemelos cargados
      var node = diagram.findNodeForKey(focusId);
      if (node !== null) {
        diagram.select(node);
        // remove any spouse for the person under focus:
        //node.linksConnected.each(function(l) {
        //  if (!l.isLabeledLink) return;
        //  l.opacity = 0;
        //  var spouse = l.getOtherNode(node);
        //  spouse.opacity = 0;
        //  spouse.pickable = false;
        //});
      }
      
      setupMarriagesSimple(diagram);
      setupParentsSimple(diagram);
      setupIdenticalTwinsSimple(diagram);
      
    }

    window[goKey].findCategory = (a, b) => {
      const cs = _.filter(nodeDataArray, (x) => {
        if ((x.cs || []).length > 0) {
          if ((x.cs.indexOf(a) > -1 || x.cs.indexOf(b) > -1)) {
            console.log(x.cs)
          }
        }
        return (x.cs || []).length > 0 && ((x.cs.indexOf(a) > -1 || x.cs.indexOf(b) > -1));
      });
      if (cs.length === 2 && (cs[0].key === a || cs[0].key === b) && (cs[1].key === a || cs[1].key === b)) {
        return "MarriageCs";
      }
      return "Marriage";
    }

    function findMarriage(diagram, a, b, category = "Marriage") {  // A and B are node keys
      var nodeA = diagram.findNodeForKey(a);
      var nodeB = diagram.findNodeForKey(b);
      if (nodeA !== null && nodeB !== null) {
        var it = nodeA.findLinksBetween(nodeB);  // in either direction
        while (it.next()) {
          var link = it.value;
          // Link.data.category === "Marriage" means it's a marriage relationship
          if (link.data !== null && link.data.category === category) return link;
        }
      }
      return null;
    }

    // now process the node data to determine marriages
    function setupMarriages(diagram) {
      var model = diagram.model;
      var nodeDataArray = model.nodeDataArray;

      for (var i = 0; i < nodeDataArray.length; i++) {
        var data = nodeDataArray[i];
        var key = data.key;
        var uxs = data.ux;
        if (uxs !== undefined) {
          if (typeof uxs === "number") uxs = [uxs];
          for (var j = 0; j < uxs.length; j++) {
            var wife = uxs[j];
            if (key === wife) {
              // or warn no reflexive marriages
              continue;
            }
            var category = window[goKey].findCategory(key, wife);
            var link = findMarriage(diagram, key, wife, category);
            if (link === null) {
              for (var k = 0; k < nodeDataArray.length; k++) {
                if(nodeDataArray[k].key == wife && nodeDataArray[k].loc && data.loc)
                {
                  let loc = data.loc.split(" ");
                  let loc2 = nodeDataArray[k].loc.split(" ");
                  let loc3 = [0,0]; 
                  loc3[0] = loc2[0];
                  loc3[1] = loc[1];
                  nodeDataArray[k].loc = loc3.join(" ");
                  break;
                }
              }

              // add a label node for the marriage link
              var mlab: any = { s: "LinkLabel" };
              model.addNodeData(mlab);
              // add the marriage link itself, also referring to the label node
              var mdata = { from: key, to: wife, labelKeys: [mlab.key], category: category };
              model.addLinkData(mdata);
            }
          }
        }
        var virs = data.vir;
        if (virs !== undefined) {
          if (typeof virs === "number") virs = [virs];
          for (var j = 0; j < virs.length; j++) {
            var husband = virs[j];
            if (key === husband) {
              // or warn no reflexive marriages
              continue;
            }
            var category = window[goKey].findCategory(key, husband);
            var link = findMarriage(diagram, key, husband, category);
            if (link === null) {
              for (var k = 0; k < nodeDataArray.length; k++) {
                if(nodeDataArray[k].key == husband && !!data.loc && !!nodeDataArray[k].loc)
                {
                  let loc = data.loc.split(" ");
                  let loc2 = nodeDataArray[k].loc.split(" ");
                  let loc3 = [0,0]; 
                  loc3[0] = loc2[0];
                  loc3[1] = loc[1];
                  nodeDataArray[k].loc = loc3.join(" ");
                  break;
                }
              }
              // add a label node for the marriage link
              var mlab: any = { s: "LinkLabel" };
              model.addNodeData(mlab);
              // add the marriage link itself, also referring to the label 
              const cs = _.filter(nodeDataArray, (x) => {
                return (x.cs || []).length > 0 && (x.vir.indexOf(key) > -1 || x.ux.indexOf(husband) > -1 || x.vir.indexOf(husband) > -1 || x.ux.indexOf(key) > -1);
              });
              var mdata = { from: key, to: husband, labelKeys: [mlab.key], category: category };
              model.addLinkData(mdata);
            }
          }
        }
      }
      return nodeDataArray;
    }

    function setupMarriagesSimple(diagram) {
      var model = diagram.model;
      var nodeDataArray = model.nodeDataArray;

      for (var i = 0; i < nodeDataArray.length; i++) {
        var data = nodeDataArray[i];
        var key = data.key;
        var uxs = data.ux;
        if (uxs !== undefined) {
          if (typeof uxs === "number") uxs = [uxs];
          for (var j = 0; j < uxs.length; j++) {
            var wife = uxs[j];
            if (key === wife) {
              // or warn no reflexive marriages
              continue;
            }
            var category = window[goKey].findCategory(key, wife);
            var link = findMarriage(diagram, key, wife, category);
            if (link === null) {
              // add a label node for the marriage link
              var mlab: any = { s: "LinkLabel" };
              model.addNodeData(mlab);
              // add the marriage link itself, also referring to the label node
              var mdata = { from: key, to: wife, labelKeys: [mlab.key], category: category };
              model.addLinkData(mdata);
            }
          }
        }
        var virs = data.vir;
        if (virs !== undefined) {
          if (typeof virs === "number") virs = [virs];
          for (var j = 0; j < virs.length; j++) {
            var husband = virs[j];
            if (key === husband) {
              // or warn no reflexive marriages
              continue;
            }
            var category = window[goKey].findCategory(key, husband);
            var link = findMarriage(diagram, key, husband, category);
            if (link === null) {
              // add a label node for the marriage link
              var mlab: any = { s: "LinkLabel" };
              model.addNodeData(mlab);
              // add the marriage link itself, also referring to the label 
              const cs = _.filter(nodeDataArray, (x) => {
                return (x.cs || []).length > 0 && (x.vir.indexOf(key) > -1 || x.ux.indexOf(husband) > -1 || x.vir.indexOf(husband) > -1 || x.ux.indexOf(key) > -1);
              });
              var mdata = { from: key, to: husband, labelKeys: [mlab.key], category: category };
              model.addLinkData(mdata);
            }
          }
        }
      }
      return nodeDataArray;
    }

    // process parent-child relationships once all marriages are known
    function setupParents(diagram) {
      var model = diagram.model;
      var nodeDataArray = model.nodeDataArray;
      for (var i = 0; i < nodeDataArray.length; i++) {
        var data = nodeDataArray[i];
        var key = data.key;
        var mother = data.m;
        var father = data.f;

        if (mother !== undefined && father !== undefined) {
          var category = window[goKey].findCategory(mother, father);
          var link = findMarriage(diagram, mother, father, category);
          if (link === null) {
            // or warn no known mother or no known father or no known marriage between them
            if (window.console) window.console.log("unknown marriage: " + mother + " & " + father);
            continue;
          }
          let auxmotherindex = -1;
          let auxfatherindex = -1;

          for (var k = 0; k < nodeDataArray.length; k++) 
          {
            if(nodeDataArray[k].key == mother && nodeDataArray[k].loc != undefined)
            {
              auxmotherindex = k;
            }

            if(nodeDataArray[k].key == father && nodeDataArray[k].loc != undefined)
            {
              auxfatherindex = k;
            }

            if(auxfatherindex != -1 && auxmotherindex != -1) 
            {
              let loc = nodeDataArray[auxmotherindex].loc.split(" ");
              let loc2 = nodeDataArray[auxfatherindex].loc.split(" ");
              loc[1] = loc2[1];
    
              nodeDataArray[auxmotherindex].loc = loc.join(" ");
              nodeDataArray[auxfatherindex].loc = loc2.join(" ");
              break;
            }
          }

          var mdata = link.data;
          // console.log(model.findNodeDataForKey(mdata.from), model.findNodeDataForKey(mdata.to), model.findNodeDataForKey(key))
          var mlabkey = mdata.labelKeys[0];
          var cdata = { from: mlabkey, to: key };
          window[goKey].diagram.model.addLinkData(cdata);
        }
      }

      return nodeDataArray;
    }

    function setupParentsSimple(diagram) {
      var model = diagram.model;
      var nodeDataArray = model.nodeDataArray;
      for (var i = 0; i < nodeDataArray.length; i++) {
        var data = nodeDataArray[i];
        var key = data.key;
        var mother = data.m;
        var father = data.f;

        if (mother !== undefined && father !== undefined) {
          var category = window[goKey].findCategory(mother, father);
          var link = findMarriage(diagram, mother, father, category);
          if (link === null) {
            // or warn no known mother or no known father or no known marriage between them
            if (window.console) window.console.log("unknown marriage: " + mother + " & " + father);
            continue;
          }

          var mdata = link.data;
          // console.log(model.findNodeDataForKey(mdata.from), model.findNodeDataForKey(mdata.to), model.findNodeDataForKey(key))
          var mlabkey = mdata.labelKeys[0];
          var cdata = { from: mlabkey, to: key };
          window[goKey].diagram.model.addLinkData(cdata);
        }
      }

      return nodeDataArray;
    }

    function setupIdenticalTwins(diagram) {
      var model = diagram.model;
      var nodeDataArray = model.nodeDataArray;
      if (!window[goKey].twKey) {
        window[goKey].twKey = {};
      }
      for (var i = 0; i < nodeDataArray.length; i++) {
        var data = nodeDataArray[i];
        if (typeof data.tw === "number") {
          var key = data.key;
          var ikey = data.tw;
          var idata = model.findNodeDataForKey(ikey);
          if (idata !== null && data.m === idata.m && data.f === idata.f) {
            var node = diagram.findNodeForData(data);
            var inode = diagram.findNodeForData(idata);
            let keytw = [Math.min(data.key, idata.key), Math.max(data.key, idata.key)].toString();
            if (node !== null && inode !== null) {// && !link

              let auxnode = -1;
              let auxinode = -1;
              for (var k = 0; k < nodeDataArray.length; k++) 
              {
                if(nodeDataArray[k].key == node && nodeDataArray[k].loc != undefined)
                {
                  auxnode = k;
                }
    
                if(nodeDataArray[k].key == inode && nodeDataArray[k].loc != undefined)
                {
                  auxinode = k;
                }
    
                if(auxinode != -1 && auxnode != -1) 
                {
                  let loc = nodeDataArray[auxnode].loc.split(" ");
                  let loc2 = nodeDataArray[auxinode].loc.split(" ");
                  loc[1] = loc2[1];
        
                  nodeDataArray[auxnode].loc = loc.join(" ");
                  nodeDataArray[auxinode].loc = loc2.join(" ");
                  break;
                }
              }

              window[goKey].twKey[keytw] = {
                tw1: data, tw2: idata
              };
              var tlinktempl = diagram.linkTemplateMap.get("TwinLinkIdentical");
              var tlink = tlinktempl.copy();

              tlink.fromNode = node;
              tlink.toNode = inode;
              diagram.add(tlink);
            }
          }
        }
      }

      return nodeDataArray;
    }

    function setupIdenticalTwinsSimple(diagram) {
      var model = diagram.model;
      var nodeDataArray = model.nodeDataArray;
      if (!window[goKey].twKey) {
        window[goKey].twKey = {};
      }
      for (var i = 0; i < nodeDataArray.length; i++) {
        var data = nodeDataArray[i];
        if (typeof data.tw === "number") {
          var key = data.key;
          var ikey = data.tw;
          var idata = model.findNodeDataForKey(ikey);
          if (idata !== null && data.m === idata.m && data.f === idata.f) {
            var node = diagram.findNodeForData(data);
            var inode = diagram.findNodeForData(idata);
            let keytw = [Math.min(data.key, idata.key), Math.max(data.key, idata.key)].toString();
            if (node !== null && inode !== null) {// && !link
              window[goKey].twKey[keytw] = {
                tw1: data, tw2: idata
              };
              var tlinktempl = diagram.linkTemplateMap.get("TwinLinkIdentical");
              var tlink = tlinktempl.copy();

              tlink.fromNode = node;
              tlink.toNode = inode;
              diagram.add(tlink);
            }
          }
        }
      }

      return nodeDataArray;
    }

    function validPoint(loc, enc = false) {
      /*if (enc && window[goKey].isGeneration) {
        return;
      }*/
      const split = (loc || '').split(' ').map((x) => {
        return _.toNumber(x);
      });
      return _.isNumber(split[0]) && _.isNumber(split[1]);
    }

    // A custom layout that shows the two families related to a person's parents
    function GenogramLayout() {
      go.LayeredDigraphLayout.call(this);
      this.initializeOption = go.LayeredDigraphLayout.InitDepthFirstIn; // InitNaive; // InitDepthFirstOut; // InitDepthFirstIn;
      this.spouseSpacing = 60;  // minimum space between spouses
    }
    go.Diagram.inherit(GenogramLayout, go.LayeredDigraphLayout);

    GenogramLayout.prototype.makeNetwork = function (coll) {
      // generate LayoutEdges for each parent-child Link
      var net = this.createNetwork();
      if (coll instanceof go.Diagram) {
        this.add(net, coll.nodes, true);
        this.add(net, coll.links, true);
      } else if (coll instanceof go.Group) {
        this.add(net, coll.memberParts, false);
      } else if (coll.iterator) {
        this.add(net, coll.iterator, false);
      }
      return net;
    };

    // internal method for creating LayeredDigraphNetwork where husband/wife pairs are represented
    // by a single LayeredDigraphVertex corresponding to the label Node on the marriage Link
    GenogramLayout.prototype.add = function (net, coll, nonmemberonly) {
      var multiSpousePeople = new go.Set();
      // consider all Nodes in the given collection
      var it = coll.iterator;
      while (it.next()) {
        var node = it.value;
        if (!(node instanceof go.Node)) continue;
        if (!node.isLayoutPositioned || !node.isVisible()) continue;
        if (nonmemberonly && node.containingGroup !== null) continue;
        // if it's an unmarried Node, or if it's a Link Label Node, create a LayoutVertex for 
        if (node.isLinkLabel) {
          // get marriage Link
          var link = node.labeledLink;
          var spouseA = link.fromNode;
          var spouseB = link.toNode;
          // create vertex representing both husband and wife
          var vertex = net.addNode(node);
          // now define the vertex size to be big enough to hold both spouses
          vertex.width = spouseA.actualBounds.width + this.spouseSpacing + spouseB.actualBounds.width;
          vertex.height = Math.max(spouseA.actualBounds.height, spouseB.actualBounds.height);
          if (!validPoint(spouseA.data.loc)) {
            vertex.focus = new go.Point(spouseA.actualBounds.width + this.spouseSpacing / 2, vertex.height / 2);
          }
        } else {
          // don't add a vertex for any married person!
          // instead, code above adds label node for marriage link
          // assume a marriage Link has a label Node
          var marriages = 0;
          node.linksConnected.each(function (l) { if (l.isLabeledLink) marriages++; });
          if (marriages === 0) {
            var vertex = net.addNode(node);
          } else if (marriages > 1) {
            multiSpousePeople.add(node);
          }
        }
      }
      // now do all Links
      it.reset();
      while (it.next()) {
        var link = it.value;
        if (!(link instanceof go.Link)) continue;
        if (!link.isLayoutPositioned || !link.isVisible()) continue;
        if (nonmemberonly && link.containingGroup !== null) continue;
        // if it's a parent-child link, add a LayoutEdge for it
        if (!link.isLabeledLink) {
          var parent = net.findVertex(link.fromNode);  // should be a label node
          var child = net.findVertex(link.toNode);
          if (child !== null) {  // an unmarried child
            net.linkVertexes(parent, child, link);
          } else {  // a married child
            link.toNode.linksConnected.each(function (l) {
              if (!l.isLabeledLink) return;  // if it has no label node, it's a parent-child link
              // found the Marriage Link, now get its label Node
              var mlab = l.labelNodes.first();
              // parent-child link should connect with the label node,
              // so the LayoutEdge should connect with the LayoutVertex representing the label node
              var mlabvert = net.findVertex(mlab);
              if (mlabvert !== null) {
                net.linkVertexes(parent, mlabvert, link);
              }
            });
          }
        }
      }

      while (multiSpousePeople.count > 0) {
        // find all collections of people that are indirectly married to each other
        var node = multiSpousePeople.first();
        var cohort = new go.Set();
        this.extendCohort(cohort, node);
        // then encourage them all to be the same generation by connecting them all with a common vertex
        var dummyvert = net.createVertex();
        net.addVertex(dummyvert);
        var m = new go.Set();
        cohort.each(function (n) {
          n.linksConnected.each(function (l) {
            m.add(l);
          })
        });
        m.each(function (link) {
          // find the vertex for the marriage link (i.e. for the label node)
          var mlab = link.labelNodes.first()
          var v = net.findVertex(mlab);
          if (v !== null) {
            net.linkVertexes(dummyvert, v, null);
          }
        });
        // done with these people, now see if there are any other multiple-married people
        multiSpousePeople.removeAll(cohort);
      }
    };

    // collect all of the people indirectly married with a person
    GenogramLayout.prototype.extendCohort = function (coll, node) {
      if (coll.has(node)) return;
      coll.add(node);
      var lay = this;
      node.linksConnected.each(function (l) {
        if (l.isLabeledLink) {  // if it's a marriage link, continue with both spouses
          lay.extendCohort(coll, l.fromNode);
          lay.extendCohort(coll, l.toNode);
        }
      });
    };

    GenogramLayout.prototype.assignLayers = function () {
      go.LayeredDigraphLayout.prototype.assignLayers.call(this);
      var horiz = this.direction == 0.0 || this.direction == 180.0;
      // for every vertex, record the maximum vertex width or height for the vertex's layer
      var maxsizes = [];
      this.network.vertexes.each(function (v) {
        var lay = v.layer;
        var max = maxsizes[lay];
        if (max === undefined) max = 0;
        var sz = (horiz ? v.width : v.height);
        if (sz > max) maxsizes[lay] = sz;
      });
      // now make sure every vertex has the maximum width or height according to which layer it is in,
      // and aligned on the left (if horizontal) or the top (if vertical)
      this.network.vertexes.each(function (v) {
        var lay = v.layer;
        var max = maxsizes[lay];
        if (v.node && v.node.data && !validPoint(v.node.data.loc, true)) {
          if (horiz) {
            v.focus = new go.Point(0, v.height / 2);
            v.width = max;
          } else {
            v.focus = new go.Point(v.width / 2, 0);
            v.height = max;
          }
        } else {
          if (v.node && validPoint(v.node.data.loc)) {
            const split = (v.node.data.loc || '').split(' ').map((x) => {
              return _.toNumber(x);
            });
            v.node.position = new go.Point(split[0], split[1]);
          }
        }
      });
      // from now on, the LayeredDigraphLayout will think that the Node is bigger than it really is
      // (other than the ones that are the widest or tallest in their respective layer).
    };

    GenogramLayout.prototype.commitNodes = function () {
      go.LayeredDigraphLayout.prototype.commitNodes.call(this);
      // position regular nodes
      this.network.vertexes.each(function (v) {
        if (v.node !== null && !v.node.isLinkLabel) {
          if (!validPoint(v.node.data.loc, true)) {
            v.node.position = new go.Point(v.x, v.y);
          } else {
            const split = (v.node.data.loc || '').split(' ').map((x) => {
              return _.toNumber(x);
            });
            v.node.position = new go.Point(split[0], split[1]);
          }
        }
      });
      // position the spouses of each marriage vertex
      var layout = this;
      this.network.vertexes.each(function (v) {
        if (v.node === null) return;
        if (!v.node.isLinkLabel) return;
        var labnode = v.node;
        var lablink = labnode.labeledLink;
        // In case the spouses are not actually moved, we need to have the marriage link
        // position the label node, because LayoutVertex.commit() was called above on these vertexes.
        // Alternatively we could override LayoutVetex.commit to be a no-op for label node vertexes.
        lablink.invalidateRoute();
        var spouseA = lablink.fromNode;
        var spouseB = lablink.toNode;
        // prefer fathers on the left, mothers on the right
        if (spouseA.data.s === "F" || spouseA.data.s === "N" || spouseA.data.s === "NB") {  // sex is female
          var temp = spouseA;
          spouseA = spouseB;
          spouseB = temp;
        }
        // see if the parents are on the desired sides, to avoid a link crossing
        var aParentsNode = layout.findParentsMarriageLabelNode(spouseA);
        var bParentsNode = layout.findParentsMarriageLabelNode(spouseB);
        if (aParentsNode !== null && bParentsNode !== null && aParentsNode.position.x > bParentsNode.position.x) {
          // swap the spouses
          var temp = spouseA;
          spouseA = spouseB;
          spouseB = temp;
        }
        const x = v.x;
        const fixY = 20;
        if (!validPoint(spouseA.data.loc)) {
          spouseA.position = new go.Point(x, v.y - fixY);
        }
        if (!validPoint(spouseB.data.loc)) {
          spouseB.position = new go.Point(x + spouseA.actualBounds.width + layout.spouseSpacing, v.y - fixY);
        }
        // console.log(spouseA.data.n, v.x, v.y, spouseB.data.n, v.x + spouseA.actualBounds.width + layout.spouseSpacing, v.y);
      });
      // position only-child nodes to be under the marriage label node
      this.network.vertexes.each(function (v) {
        if (v.node === null || v.node.linksConnected.count > 1) return;
        var mnode = layout.findParentsMarriageLabelNode(v.node);
        if (mnode !== null && mnode.linksConnected.count === 1) {  // if only one child
          var mvert = layout.network.findVertex(mnode);
          var newbnds = v.node.actualBounds.copy();
          newbnds.x = mvert.centerX - v.node.actualBounds.width / 2;
          // see if there's any empty space at the horizontal mid-point in that layer
          var overlaps = layout.diagram.findObjectsIn(newbnds, function (x) { return x.part; }, function (p) { return p !== v.node; }, true);
          if (overlaps.count === 0 && v.node.data && !v.node.data.loc) {
            v.node.move(newbnds.position);
          }
        } else {
          var diagram = window[goKey].diagram;
          var model = diagram.model;
          var nodeDataArray = model.nodeDataArray;
          var mvert = layout.network.findVertex(mnode);
          var newbnds = v.node.actualBounds.copy();
          if (mvert) {
            newbnds.x = mvert.centerX - v.node.actualBounds.width;
            var overlaps = layout.diagram.findObjectsIn(newbnds, function (x) { return x.part; }, function (p) { return p !== v.node; }, true);
            var brothers = _.filter(nodeDataArray, (x) => {
              return x.key !== v.node.data.key && v.node.data.m === x.m && v.node.data.f === x.f;
            });
            if (brothers.length === 1 && overlaps.count === 1 &&
              (!v.node.data.vir || v.node.data.vir.length === 0) &&
              (!brothers[0].ux || brothers[0].ux.length === 0) &&
              (!brothers[0].vir || brothers[0].vir.length === 0) &&
              (!brothers[0].ux || brothers[0].ux.length === 0)) {
              var bnode = diagram.findNodeForData(brothers[0]);

              //  var link = findMarriage(diagram, v.node.data.f, v.node.data.m);
              //  if (!!link) {
              //    console.log(link);
              //  }

              v.node.move(new go.Point(newbnds.position.x - 10, newbnds.position.y));
              bnode.move(new go.Point(newbnds.position.x + 50, newbnds.position.y));
              //  console.log(overlaps.count, v.node.data.n, bnode.data.n);
            }
          }
        }
      });
    };

    GenogramLayout.prototype.findParentsMarriageLabelNode = function (node) {
      var it = node.findNodesInto();
      while (it.next()) {
        var n = it.value;
        if (n.isLinkLabel) return n;
      }
      return null;
    };
    // end GenogramLayout class

    // custom routing for same birth siblings
    function TwinLink() {
      go.Link.call(this);
    }
    go.Diagram.inherit(TwinLink, go.Link);

    TwinLink.prototype.computePoints = function () {
      var result = go.Link.prototype.computePoints.call(this);
      var pts = this.points;
      if (pts.length >= 4) {
        var birthId = this.toNode.data["birthdate"];
        if (birthId) {
          var parents = this.fromNode;
          var sameBirth = 0;
          var sumX = 0;
          var it = parents.findNodesOutOf();
          while (it.next()) {
            var child = it.value;
            if (child.data["birthdate"] === birthId) {
              sameBirth++;
              sumX += child.location.x;
            }
          }
          if (sameBirth > 0 && sameBirth > 1) {
            var midX = sumX / sameBirth;
            var oldp = pts.elt(pts.length - 3);
            pts.setElt(pts.length - 3, new go.Point(midX, oldp.y));
            pts.setElt(pts.length - 2, pts.elt(pts.length - 1));
          }
        }
      }
      return result;
    };
    // end TwinLink class
    window[goKey].init();
  }

  download() {
    // When the blob is complete, make an anchor tag for it and use the tag to initiate a download
    // Works in Chrome, Firefox, Safari, Edge, IE11
    const myCallback = (blob) => {
      var url = window.URL.createObjectURL(blob);
      var filename = "genogram.jpeg";

      var a: any = document.createElement("a");
      a.style = "display: none";
      a.href = url;
      a.download = filename;

      // IE 11
      if (window.navigator.msSaveBlob !== undefined) {
        window.navigator.msSaveBlob(blob, filename);
        return;
      }

      document.body.appendChild(a);
      requestAnimationFrame(function () {
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      });
    }
    window[goKey].diagram.makeImageData({ background: "white", returnType: "blob", callback: myCallback });
  }

  print() {
    const data = _.find(window[goKey].nodeDataFullArray, { key: 1 });
    const diagram = document.getElementById('myDiagramDiv');
    const div = document.createElement('div');
    div.id = 'tmp-title';
    div.innerHTML = `
      <p>
        <br>Paciente: ${data.firstname} ${data.lastname}<br>
      <p>
    `;
    diagram.appendChild(div);
    this.render.setStyle(div, 'color', 'transparent');
    console.log(diagram, data);
    printJS({
      printable: 'myDiagramDiv',
      type: 'html',
    });
    setTimeout(() => {
      diagram.removeChild(div);
    }, 5000);
  }

  async daily() {
    if (this.isModal) {
      return;
    }
    this.isModal = true;
    const modal = await this.modalCtrl.create({
      component: BinnaclePage,
      componentProps: {
        isModal: true,
        patient: this.patient.id
      }
    });
    modal.onDidDismiss().then((data: any) => {
      if (data && data.data) {
        console.log(data.data);
      }
      this.isModal = false;
    });
    await modal.present();

  }

  async share() {
    if (this.isModal) {
      return;
    }
    this.isModal = true;
    const modal = await this.modalCtrl.create({
      component: SearchPage,
      componentProps: {
        isModal: true,
        patient: this.patient.id
      }
    });
    modal.onDidDismiss().then((data: any) => {
      if (data && data.data) {
      }
      this.isModal = false;
    });
    await modal.present();
  }

  zoom(type) {
    if (type === 'in') {
      window[goKey].zoomInButtonClickCoun = window['genogram'].diagram.scale + window[goKey].zoomInButtonClickCount * window[goKey].scaleLevelChange;
    } else {
      window[goKey].zoomInButtonClickCoun = window['genogram'].diagram.scale - window[goKey].zoomInButtonClickCount * window[goKey].scaleLevelChange;
    }
    window[goKey].zoom(window[goKey].zoomInButtonClickCoun);
  }

  async trash() {
    this.onDropTree.emit(true);
  }

  infoMesage() {
    this.viewInfoMessage = this.viewInfoMessage ? false : true;
  }

}
