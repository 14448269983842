import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[fix-icon]'
})
export class FixIconDirective implements OnInit {

  constructor(private el: ElementRef) { }

  ngOnInit(){
    const removeTitle = () => {
      if(this.el.nativeElement && this.el.nativeElement.shadowRoot && this.el.nativeElement.shadowRoot.querySelector('.icon-inner svg title') ) {
        this.el.nativeElement.shadowRoot.querySelector('.icon-inner svg title').innerHTML = '';
      } else {
        setTimeout( () => {
          removeTitle();
        }, 500 );
      }
    }
    removeTitle()
  }
}
