const ip = {
    API_URL: "https://apipt.ttreeapp.com/api/",
    production: false
};

const local = {
    API_URL: "https://apipt.ttreeapp.com/api/",
    production: false
};

const dev = {
    API_URL: "https://apipt.ttreeapp.com/api/",
    production: false
};

const prod = {
    API_URL: "https://apipt.ttreeapp.com/api/",
    production: true
};

/**
 * local 
 * dev
 * prod
 */
export const env = prod;
