import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Storage } from '@ionic/storage';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable()
export class PatientService {

  refresh: Subject<any> = new Subject();
  onKindred: Subject<any>;
  selectedNode: Subject<any> = new Subject();

  constructor(
    private http: HttpClient,
  ) {
  }

  reset(body:any, id): Observable<any> {
    return this.http.put(`${environment.API_URL}reset-kindred/${id}`, body)
  }

  update(body: any, id): Observable<any> {
    return this.http.put(`${environment.API_URL}patients/${id}`, body)
  }

  nodePosition(body: any, id): Observable<any> {
    return this.http.put(`${environment.API_URL}kindred/${id}/position`, body)
  }

  updateImageDiagram(body: any, id): Observable<any> {
    return this.http.put(`${environment.API_URL}patients/${id}/diagram`, body)
  }

  deleteKindred(patient_id: number, kindred_id: number): Observable<any> {
    return this.http.delete(`${environment.API_URL}patients/${patient_id}/kindred/${kindred_id}`)
  }

  save(body: any): Observable<any> {
    return typeof body.id === 'number' ? this.update(body, body.id) : this.http.post(`${environment.API_URL}patients`, body);
  }

  patients(): Observable<any> {
    return this.http.get(`${environment.API_URL}patients`);
  }

  show(id): Observable<any> {
    return this.http.get(`${environment.API_URL}patients/${id}`);
  }

  medicalCountries(): Observable<any> {
    return this.http.get(`${environment.API_URL}country-users`);
  }

  medicalSpecialty(): Observable<any> {
    return this.http.get(`${environment.API_URL}specialty-users`);
  }

  kindred(kindred, patient): Observable<any> {
    return this.http.get(`${environment.API_URL}kindreds?kindred=${kindred}&patient=${patient}`);
  }

  tree(id): Observable<any> {
    return this.http.get(`${environment.API_URL}patients/${id}/tree`);
  }

  showUsers(id = null, email = ''): Observable<any> {
    return this.http.get(`${environment.API_URL}all-user?patient=${id}&email=${email}`);
  }

  saveSearch(data): Observable<any> {
    return this.http.post(`${environment.API_URL}save-search`, data);
  }

  getSearch(id = null): Observable<any> {
    return this.http.get(`${environment.API_URL}all-select?patient=${id}`);
  }

  change(data): Observable<any> {
    return this.http.post(`${environment.API_URL}change-select`, data);
  }

  activity(id = null): Observable<any> {
    return this.http.get(`${environment.API_URL}activities?patient=${id}`);
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`${environment.API_URL}patients/${id}`);
  }

  generateFiles(id, files: FileList): Observable<any> {
    const file = files.item(0);
    const formData = new FormData();
    formData.append('files', file);
    formData.append('name', file.name);
    // const headers = new HttpHeaders({'Content-Type': 'multipart/form-data'});
    return this.http.post(`${environment.API_URL}kindred/${id}/generarFiles`, formData,  {
      reportProgress: true,
      observe: 'events'
    });
  }

  deleteFiles(id, id_file): Observable<any> {
    return this.http.delete(`${environment.API_URL}kindred/${id}/delete_file/${id_file}`);
  }

}
