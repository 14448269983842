import { Injectable } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
export const NA = 'Desconocido(a)';
const n = (a, b = '') => {
  a = a || '';
  b = b || '';
  const enc = (a === b && !a) || a === NA || b === NA;// + a.slice(1).toLowerCase(); + b.slice(1).toLowerCase()
  const x = `${a ? a.charAt(0).toUpperCase() : ''}${b ? b.charAt(0).toUpperCase() : ''}`;
  return (x !== ' ' && !enc ? x : 'D').replace(/\s|\s\s+/g, '');
}
const name = (a) => {
  a = a || '';
  const x = `${a ? a.charAt(0).toUpperCase() + a.slice(1).toLowerCase() : ''}`.replace(/\s\s+/g, '');
  return x || NA;
}
const initials = (a = '', b = '') => {
  a = a || '';
  b = b || '';
  return (a.charAt(0).toUpperCase() + b.charAt(0).toUpperCase()).replace(/\s|\s\s+/g, '');
}
const tooltips = (a, b) => {
  a = a || '';
  b = b || '';
  const enc = a === b && a === NA;
  const x = `${a} ${b}`;
  return (x !== ' ' && !enc ? x : NA);
}
@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  isCs: boolean = false;

  listSelectType = {
    1: [{
      value: "1",
      text: "vivo"
    }, {
      value: "2",
      text: "cadaverico"
    }],
    2: [{
      value: "1",
      text: "Si estoy en lista de espera."
    }, {
      value: "2",
      text: "No estoy en lista de espera."
    }]
  };

  datePickerObj: any = {
    inputDate: new Date(),
    clearButton: false,
    showTodayButton: false,
    arrowNextPrev: 'search',
    fromDate: moment('01/01/1800').format('DD/MM/YYYY'),
    setLabel: 'Guardar',  // default 'Set'
    closeLabel: 'Cerrar', // default 'Close'
    dateFormat: 'DD/MM/YYYY',
    monthsList: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
    weeksList: ["D", "L", "M", "M", "J", "V", "S"],
    btnProperties: {
      expand: 'block',
    }
  };
  whiteOrRead: Array<any> = [
    { name: 'Solo lectura', value: 0 },
    { name: 'Lectura y escritura', value: 1 },
  ];

  gender: Array<any> = [
    { name: 'Masculino', value: 'M' },
    { name: 'Femenino', value: 'F' },
    { name: 'Sexo no especificado', value: 'N' },
    { name: 'Genero no binario', value: 'NB' },
  ];

  conditions: Array<any> = [{
    name: 'Asintomático',
    value: 0,
    stroke: "#7adca6",
    select: true
  }, {
    name: 'Sintomático',
    value: 1,
    stroke: "#0f54a5",
    select: true
  }, {
    name: 'Con signos',
    value: 2,
    stroke: "#d1adf2",
    select: true
  }];

  diseaseState: Array<any> = [
    {
      name: 'Asin',
      value: '0',
      stroke: "#d4071c",
      select: true
    }, {
      name: 'I',
      value: '1',
      stroke: "#7adca6",
      select: true
    }, {
      name: 'II',
      value: '2',
      stroke: "#0f54a5",
      select: true
    }, {
      name: 'III',
      value: '3',
      stroke: "#ffae00",
      select: true
    }];

  stateTest: Array<any> = [{
    value: '1',
    name: 'Negativo',
    stroke: "#7adca6",
    select: true
  }, {
    value: '2',
    name: 'Positivo',
    stroke: "#0f54a5",
    select: true
  }, {
    value: '5',
    name: 'Testeado resultado pendiente',
    stroke: '#99cef5',
    select: true
  }, {
    value: '3',
    name: 'No testeado',
    stroke: "#ffae00",
    select: true
  }, {
    value: '4',
    name: 'N/A',
    stroke: "#858585",
    select: true
  }];

  mutations: Array<any> = [{
    name: 'Val50Met',
    value: '1',
    stroke: "#d4071c",
    select: true,
    status: 1
  }, {
    name: 'Ile93Val',
    value: '2',
    stroke: "#f27935",
    select: true,
    status: 1
  }, {
    name: 'Phe84Leu',
    value: '3',
    stroke: "#7adca6",
    select: true,
    status: 1
  }, {
    name: 'Glu62ASp',
    value: '4',
    stroke: "#70bdc2",
    select: true,
    status: 1
  }, {
    name: 'Thr80Ala',
    value: '5',
    stroke: '#fcf384',
    select: true,
    status: 1
  }, {
    name: 'Val142Ile',
    value: '6',
    stroke: '#0f54a5',
    select: true,
    status: 1
  }, {
    name: 'Cys30Arg',
    value: '7',
    stroke: '#858585',
    select: true,
    status: 1
  }, {
    name: 'Arg54Thr',
    value: '8',
    stroke: '#866310',
    select: true,
    status: 1
  }, {
    name: 'Gly67Ala',
    value: '9',
    stroke: '#9270c2',
    select: true,
    status: 1
  }, {
    name: 'Leu78His',
    value: '11',
    stroke: '#f9d31d',
    select: true,
    status: 1
  }, {
    name: 'Glu109Gln',
    value: '12',
    stroke: '#af70c2',
    select: true,
    status: 1
  }, {
    name: 'Ile127Val',
    value: '13',
    stroke: '#C0C0C0',
    select: true,
    status: 1
  }, {
    name: 'Ser43Asn',
    value: '14',
    stroke: '#800000',
    select: true,
    status: 1
  }, {
    name: 'Leu131Met',
    value: '15',
    stroke: '#808000',
    select: true,
    status: 1
  }, {
    name: 'ASp58Ala',
    value: '16',
    stroke: '#00FF00',
    select: true,
    status: 1
  }, {
    name: 'Phe53Leu',
    value: '17',
    stroke: '#00FFFF',
    select: true,
    status: 1
  }, {
    name: 'Ser70Arg',
    value: '18',
    stroke: '#008080',
    select: true,
    status: 1
  }, {
    name: 'Ser97Tyr',
    value: '19',
    stroke: '#000080',
    select: true,
    status: 1
  }, {
    name: 'Ala56Pro',
    value: '20',
    stroke: '#FF00FF',
    select: true,
    status: 1
  }, {
    name: 'Thr69Ala',
    value: '21',
    stroke: '#DC143C',
    select: true,
    status: 1
  }, {
    name: 'Ala45Thr',
    value: '22',
    stroke: '#00008B',
    select: true,
    status: 1
  }, {
    name: 'His108Arg',
    value: '23',
    stroke: '#B8860B',
    select: true,
    status: 1
  }, {
    name: 'Trp61Leu',
    value: '24',
    stroke: '#E9967A',
    select: true,
    status: 1
  }, {
    name: 'Val142del',
    value: '25',
    stroke: '#20B2AA',
    select: true,
    status: 1
  }];


  phenotypeList: Array<any> = [{
    name: 'Temprano',
    value: '1',
    stroke: "#d4071c",
    select: true
  }, {
    name: 'Tardío',
    value: '2',
    stroke: "#f27935",
    select: true
  },
  // {name: 'Antes o después de los 50 años', value : 3}, 
  {
    name: 'Polineuropatíco',
    value: '3',
    stroke: "#7adca6",
    select: true
  }, {
    name: 'Cardiomiopático',
    value: '4',
    stroke: "#70bdc2",
    select: true
  }, {
    name: 'Mixto',
    value: '5',
    stroke: '#840000',
    select: true
  }];

  states: Array<any> = [{
    name: 'Vivo(a)',
    value: '1'
  }, {
    name: 'Fallecido(a)',
    value: '2'
  }, {
    name: 'No especificado',
    value: '3'
  }, {
    name: 'Desconocido',
    value: '4'
  }];

  manifestationList: Array<any> = [{
    name: 'Cardíaca',
    value: '1'
  }, {
    name: 'Neurológica',
    value: '2'
  }, {
    name: 'Mixta',
    value: '3'
  }];

  relationshipList: Array<any> = [{
    name: 'Consanguinidad',
    value: '1'
  }, {
    name: 'Adoptado(a)',
    value: '2'
  }, {
    name: 'Embarazo',
    value: '3'
  }];

  twinsList: Array<any> = [{
    name: 'Dicigóticos',
    value: '1'
  }, {
    name: 'Monocigóticos',
    value: '2'
  }];

  descendantList: Array<any> = [{
    name: 'Fallecido',
    value: '1'
  }, {
    name: 'Afectado con rasgos hereditarios',
    value: '2'
  }];

  nervous: Array<any> = [{
    value: '1',
    text: 'Sensitiva',
    name: 'Sensitiva',
    stroke: "#7adca6",
    select: true,
    grouped: {}
  }, {
    value: '2',
    text: 'Motora',
    name: 'Motora',
    stroke: "#0f54a5",
    select: true,
    grouped: {}
  }, {
    value: '3',
    text: 'Sensitivo-motora',
    name: 'Sensitivo-motora',
    stroke: "#ffae00",
    select: true,
    grouped: {}
  }, {
    value: '4',
    text: 'Fibra fina',
    name: 'Fibra fina',
    stroke: "#ffae00",
    select: true,
    grouped: {}
  }]

  renal: Array<any> = [{
    value: '1',
    text: 'I',
    name: 'I',
    stroke: "#7adca6",
    select: true,
    grouped: {}
  }, {
    value: '2',
    text: 'II',
    name: 'II',
    stroke: "#0f54a5",
    select: true,
    grouped: {}
  }, {
    value: '3',
    text: 'III',
    name: 'III',
    stroke: "#ffae00",
    select: true,
    grouped: {}
  }, {
    value: '4',
    text: 'IV',
    name: 'IV',
    stroke: "#ffae00",
    select: true,
    grouped: {}
  }]

  tunnel_carpal: Array<any> = [{
    value: '1',
    text: 'Unilateral',
    name: 'Unilateral',
    stroke: "#7adca6",
    select: true
  }, {
    value: '2',
    text: 'Bilateral',
    name: 'Bilateral',
    stroke: "#0f54a5",
    select: true
  }, {
    value: '0',
    name: 'N/A',
    stroke: "#858585",
    select: true
  }];

  transplats: Array<any> = [{
    value: '1',
    text: 'Higado',
    name: 'Higado',
    stroke: "#7adca6",
    select: true
  }, {
    value: '2',
    text: 'Riñon',
    name: 'Riñon',
    stroke: "#0f54a5",
    select: true
  }, {
    value: '3',
    text: 'Corazón',
    name: 'Corazón',
    stroke: "#ffae00",
    select: true
  }, {
    value: '4',
    text: 'Higado+Riñon',
    name: 'Higado+Riñon',
    stroke: "#eb445a",
    select: true
  }, {
    value: '5',
    text: 'Higado+Corazón',
    name: 'Higado+Corazón',
    stroke: '#840000',
    select: true
  }, {
    value: '6',
    text: 'Corazón+Riñon',
    name: 'Corazón+Riñon',
    stroke: '#772586',
    select: true
  }];

  treatments: Array<any> = [{
    value: '1',
    text: 'tafamidis',
    name: 'tafamidis',
    stroke: "#7adca6",
    select: true
  }, {
    value: '2',
    text: 'inotersen',
    name: 'inotersen',
    stroke: "#0f54a5",
    select: true
  }, {
    value: '3',
    text: 'patisiran',
    name: 'patisiran',
    stroke: "#ffae00",
    select: true
  }, {
    value: '4',
    text: 'otro tratamiento',
    name: 'otro tratamiento',
    stroke: "#eb445a",
    select: true
  }, {
    value: '5',
    text: 'ninguno',
    name: 'ninguno',
    stroke: '#840000',
    select: true
  }];

  platelets: Array<any> = [{
    value: '1',
    min: 0,
    max: 24.9,
    name: '0 - 24.9',
    stroke: "#7adca6",
    select: true
  }, {
    value: '2',
    min: 25,
    max: 49.9,
    name: '25 - 49.9',
    stroke: "#0f54a5",
    select: true
  }, {
    value: '3',
    min: 50,
    max: 74.9,
    name: '50 - 74.9',
    stroke: "#ffae00",
    select: true
  }, {
    value: '4',
    min: 75,
    max: 99.9,
    name: '75 - 99.9',
    stroke: "#eb445a",
    select: true
  }, {
    value: '5',
    min: 100,
    max: 100000000,
    name: '> 100',
    stroke: "#840000",
    select: true
  }, {
    value: '0',
    min: 0,
    max: 0,
    name: 'N/A',
    stroke: "#858585",
    select: true
  }]

  imc: Array<any> = [{
    value: '1',
    min: 0,
    max: 18.5,
    name: 'Insuficiente',
    stroke: "#7adca6",
    select: true
  }, {
    value: '2',
    min: 18.6,
    max: 24.9,
    name: 'Normal',
    stroke: "#0f54a5",
    select: true
  }, {
    value: '3',
    min: 25,
    max: 29.9,
    name: 'Sobrepeso',
    stroke: "#ffae00",
    select: true
  }, {
    value: '4',
    min: 30,
    max: 39.9,
    name: 'Obesidad',
    stroke: "#eb445a",
    select: true
  }, {
    value: '0',
    min: 40,
    max: 100000000,
    name: 'N/A',
    stroke: "#858585",
    select: true
  }];

  rangeHto: Array<any> = [{
    value: '1',
    min: 0,
    max: 24.9,
    name: '0 - 24.9',
    stroke: "#7adca6",
    select: true
  }, {
    value: '2',
    min: 25,
    max: 49.9,
    name: '25 - 49.9',
    stroke: "#0f54a5",
    select: true
  }, {
    value: '3',
    min: 50,
    max: 74.9,
    name: '50 - 74.9',
    stroke: "#ffae00",
    select: true
  }, {
    value: '4',
    min: 75,
    max: 99.9,
    name: '75 - 99.9',
    stroke: "#eb445a",
    select: true
  }, {
    value: '5',
    min: 100,
    max: 100000000,
    name: '> 100',
    stroke: "#840000",
    select: true
  }, {
    value: '0',
    min: 0,
    max: 0,
    name: 'N/A',
    stroke: "#858585",
    select: true
  }];

  rangeGr: Array<any> = [{
    value: '1',
    min: 0,
    max: 2.4,
    name: '0 - 2.4',
    stroke: "#7adca6",
    select: true
  }, {
    value: '2',
    min: 2.5,
    max: 4.9,
    name: '2.5 - 4.9',
    stroke: "#0f54a5",
    select: true
  }, {
    value: '3',
    min: 5,
    max: 7.4,
    name: '5 - 7.4',
    stroke: "#ffae00",
    select: true
  }, {
    value: '4',
    min: 7,
    max: 10,
    name: '7 - 10',
    stroke: "#eb445a",
    select: true
  }, {
    value: '5',
    min: 10.1,
    max: 100000000,
    name: '> 10',
    stroke: "#840000",
    select: true
  }, {
    value: '0',
    min: 0,
    max: 0,
    name: 'N/A',
    stroke: "#858585",
    select: true
  }];

  rangeGb: Array<any> = [{
    value: '1',
    min: 0,
    max: 12.4,
    name: '0 - 12.4',
    stroke: "#7adca6",
    select: true
  }, {
    value: '2',
    min: 12.5,
    max: 24.9,
    name: '12.5 - 24.9',
    stroke: "#0f54a5",
    select: true
  }, {
    value: '3',
    min: 25,
    max: 37.4,
    name: '50 - 74.9',
    stroke: "#ffae00",
    select: true
  }, {
    value: '4',
    min: 37.5,
    max: 50,
    name: '37.5 - 50',
    stroke: "#eb445a",
    select: true
  }, {
    value: '5',
    min: 50.1,
    max: 100000000,
    name: '> 50.1',
    stroke: "#840000",
    select: true
  }, {
    value: '0',
    min: 0,
    max: 0,
    name: 'N/A',
    stroke: "#858585",
    select: true
  }];

  rangeCreatinina: Array<any> = [{
    value: '1',
    min: 0,
    max: 12.4,
    name: '0 - 12.4',
    stroke: "#7adca6",
    select: true
  }, {
    value: '2',
    min: 12.5,
    max: 24.9,
    name: '12.5 - 24.9',
    stroke: "#0f54a5",
    select: true
  }, {
    value: '3',
    min: 25,
    max: 37.4,
    name: '50 - 74.9',
    stroke: "#ffae00",
    select: true
  }, {
    value: '4',
    min: 37.5,
    max: 50,
    name: '37.5 - 50',
    stroke: "#eb445a",
    select: true
  }, {
    value: '5',
    min: 50.1,
    max: 100000000,
    name: '> 50.1',
    stroke: "#840000",
    select: true
  }, {
    value: '0',
    min: 0,
    max: 0,
    name: 'N/A',
    stroke: "#858585",
    select: true
  }];

  rangeGlucose: Array<any> = [{
    value: '1',
    min: 0,
    max: 24.9,
    name: '0 - 24.9',
    stroke: "#7adca6",
    select: true
  }, {
    value: '2',
    min: 25,
    max: 49.9,
    name: '25 - 49.9',
    stroke: "#0f54a5",
    select: true
  }, {
    value: '3',
    min: 50,
    max: 74.9,
    name: '50 - 74.9',
    stroke: "#ffae00",
    select: true
  }, {
    value: '4',
    min: 75,
    max: 99.9,
    name: '75 - 99.9',
    stroke: "#eb445a",
    select: true
  }, {
    value: '5',
    min: 100,
    max: 100000000,
    name: '> 100',
    stroke: "#840000",
    select: true
  }, {
    value: '0',
    min: 0,
    max: 0,
    name: 'N/A',
    stroke: "#858585",
    select: true
  }];

  rangeUrea: Array<any> = [{
    value: '1',
    min: 0,
    max: 99.9,
    name: '0 - 99.9',
    stroke: "#7adca6",
    select: true
  }, {
    value: '2',
    min: 100,
    max: 199.9,
    name: '100 - 199.9',
    stroke: "#0f54a5",
    select: true
  }, {
    value: '3',
    min: 200,
    max: 299.9,
    name: '200 - 299.9',
    stroke: "#ffae00",
    select: true
  }, {
    value: '4',
    min: 300,
    max: 399.9,
    name: '300 - 399.9',
    stroke: "#eb445a",
    select: true
  }, {
    value: '5',
    min: 400,
    max: 499.9,
    name: '400 - 499.9',
    stroke: '#840000',
    select: true
  }, {
    value: '6',
    min: 500,
    max: 100000000,
    name: '> 100',
    stroke: "#840000",
    select: true
  }, {
    value: '0',
    min: 0,
    max: 0,
    name: 'N/A',
    stroke: "#858585",
    select: true
  }];

  rangeTgo: Array<any> = [{
    value: '1',
    min: 0,
    max: 499.9,
    name: '0 - 499.9',
    stroke: "#7adca6",
    select: true
  }, {
    value: '2',
    min: 500,
    max: 999.9,
    name: '500 - 999.9',
    stroke: "#0f54a5",
    select: true
  }, {
    value: '3',
    min: 1000,
    max: 1499.9,
    name: '1000 - 1499.9',
    stroke: "#ffae00",
    select: true
  }, {
    value: '4',
    min: 1500,
    max: 2000,
    name: '1500 - 2000',
    stroke: "#eb445a",
    select: true
  }, {
    value: '6',
    min: 2000,
    max: 100000000,
    name: '> 2000',
    stroke: "#840000",
    select: true
  }, {
    value: '0',
    min: 0,
    max: 0,
    name: 'N/A',
    stroke: "#858585",
    select: true
  }];

  rangeTgp: Array<any> = [{
    value: '1',
    min: 0,
    max: 499.9,
    name: '0 - 499.9',
    stroke: "#7adca6",
    select: true
  }, {
    value: '2',
    min: 500,
    max: 999.9,
    name: '500 - 999.9',
    stroke: "#0f54a5",
    select: true
  }, {
    value: '3',
    min: 1000,
    max: 1499.9,
    name: '1000 - 1499.9',
    stroke: "#ffae00",
    select: true
  }, {
    value: '4',
    min: 1500,
    max: 2000,
    name: '1500 - 2000',
    stroke: "#eb445a",
    select: true
  }, {
    value: '6',
    min: 2000,
    max: 100000000,
    name: '> 2000',
    stroke: "#840000",
    select: true
  }, {
    value: '0',
    min: 0,
    max: 0,
    name: 'N/A',
    stroke: "#858585",
    select: true
  }];

  rangeProteinuriaEvaluation: Array<any> = [{
    value: '1',
    min: 0,
    max: 2.4,
    name: '0 - 2.4',
    stroke: "#7adca6",
    select: true
  }, {
    value: '2',
    min: 2.5,
    max: 4.9,
    name: '2.5 - 4.9',
    stroke: "#0f54a5",
    select: true
  }, {
    value: '3',
    min: 5,
    max: 7.4,
    name: '5 - 7.4',
    stroke: "#ffae00",
    select: true
  }, {
    value: '4',
    min: 7,
    max: 10,
    name: '7 - 10',
    stroke: "#eb445a",
    select: true
  }, {
    value: '5',
    min: 10.1,
    max: 100000000,
    name: '> 10',
    stroke: "#840000",
    select: true
  }, {
    value: '0',
    min: 0,
    max: 0,
    name: 'N/A',
    stroke: "#858585",
    select: true
  }];

  rangeNorfolk: Array<any> = [{
    value: '1',
    min: -4,
    max: 34.9,
    name: '0 - 34.9',
    stroke: "#7adca6",
    select: true
  }, {
    value: '2',
    min: 35,
    max: 69.9,
    name: '35 - 69.9',
    stroke: "#0f54a5",
    select: true
  }, {
    value: '3',
    min: 70,
    max: 104.9,
    name: '70 - 104.9',
    stroke: "#ffae00",
    select: true
  }, {
    value: '4',
    min: 105,
    max: 136,
    name: '105 - 136',
    stroke: "#eb445a",
    select: true
  }, {
    value: '5',
    min: 136.1,
    max: 100000000,
    name: '> 136.1',
    stroke: "#840000",
    select: true
  }, {
    value: '0',
    min: 0,
    max: 0,
    name: 'N/A',
    stroke: "#858585",
    select: true
  }];

  rangeNis: Array<any> = [{
    value: '1',
    min: 0,
    max: 64.9,
    name: '0 - 64.9',
    stroke: "#7adca6",
    select: true
  }, {
    value: '2',
    min: 65,
    max: 129.9,
    name: '65 - 129.9',
    stroke: "#0f54a5",
    select: true
  }, {
    value: '3',
    min: 130,
    max: 94.9,
    name: '130 - 94.9',
    stroke: "#ffae00",
    select: true
  }, {
    value: '4',
    min: 95,
    max: 244,
    name: '95 - 244',
    stroke: "#eb445a",
    select: true
  }, {
    value: '5',
    min: 100,
    max: 100000000,
    name: '> 245',
    stroke: "#840000",
    select: true
  }, {
    value: '0',
    min: 0,
    max: 0,
    name: 'N/A',
    stroke: "#858585",
    select: true
  }];

  rangeCompass: Array<any> = [{
    value: '1',
    min: 0,
    max: 24.9,
    name: '0 - 24.9',
    stroke: "#7adca6",
    select: true
  }, {
    value: '2',
    min: 25,
    max: 49.9,
    name: '25 - 49.9',
    stroke: "#0f54a5",
    select: true
  }, {
    value: '3',
    min: 50,
    max: 74.9,
    name: '50 - 74.9',
    stroke: "#ffae00",
    select: true
  }, {
    value: '4',
    min: 75,
    max: 99.9,
    name: '75 - 99.9',
    stroke: "#eb445a",
    select: true
  }, {
    value: '5',
    min: 100,
    max: 100000000,
    name: '> 100',
    stroke: "#840000",
    select: true
  }, {
    value: '0',
    min: 0,
    max: 0,
    name: 'N/A',
    stroke: "#858585",
    select: true
  }];

  options: Array<any> = [{
    value: '1',
    name: 'Si',
    stroke: "#7adca6",
    select: true
  }, {
    value: '2',
    name: 'No',
    stroke: "#0f54a5",
    select: true
  }, {
    value: '0',
    name: 'N/A',
    stroke: "#858585",
    select: true
  }]

  auxPhoto: Array<any> = [
    '/assets/img/guide/1.jpg',
    '/assets/img/guide/2.jpg',
    '/assets/img/guide/3.jpg',
    '/assets/img/guide/4.jpg',
    '/assets/img/guide/5.jpg',
    '/assets/img/guide/6.jpg',
    '/assets/img/guide/7.jpg',
    '/assets/img/guide/8.jpg',
    '/assets/img/guide/9.jpg',
    '/assets/img/guide/10.jpg',
    '/assets/img/guide/11.jpg',
    '/assets/img/guide/12.jpg',
    '/assets/img/guide/13.jpg',
    '/assets/img/guide/14.jpg',
    '/assets/img/guide/15.jpg',
    '/assets/img/guide/16.jpg',
    '/assets/img/guide/17.jpg',
    '/assets/img/guide/18.jpg',
    '/assets/img/guide/19.jpg',
    '/assets/img/guide/20.jpg',
    '/assets/img/guide/21.jpg',
    '/assets/img/guide/22.jpg',
    '/assets/img/guide/23.jpg',
    '/assets/img/guide/24.jpg',
    '/assets/img/guide/25.jpg',
    '/assets/img/guide/26.jpg',
    '/assets/img/guide/27.jpg',
  ];
  auxMenu: Array<any> = [
    {
      id: 1,
      text: '¿Cómo me registro en la app?',
      img: '/assets/img/guide/01.png',
    },
    {
      id: 2,
      text: '¿Cómo ingreso a la app?',
      img: '/assets/img/guide/02.png',
    },
    {
      id: 3,
      text: '¿Cómo doy de alta a un paciente?',
      img: '/assets/img/guide/03.png',
    },
    {
      id: 4,
      text: '¿Como crear un embarazo?',
      img: '/assets/img/guide/04.png',
    },
    {
      id: 5,
      text: '¿Cómo doy de alta la historia clínica de un paciente?',
      img: '/assets/img/guide/05.png',
    },
    {
      id: 6,
      text: '¿Cómo doy de alta una nueva consulta dentro de la historia clínica del paciente?',
      img: '/assets/img/guide/06.png',
    },
    {
      id: 7,
      text: '¿Cómo puedo ver las últimas consultas que realicé?',
      img: '/assets/img/guide/07.png',
    },
    {
      id: 8,
      text: '¿Cómo puedo buscar una consulta en particular?',
      img: '/assets/img/guide/08.png',
    },
    {
      id: 9,
      text: '¿Cómo doy de alta la historia clínica de un familiar del paciente?',
      img: '/assets/img/guide/09.png',
    },
    {
      id: 10,
      text: '¿Cómo doy de alta una nueva consulta dentro de la historia clínica de un familiar del paciente?',
      img: '/assets/img/guide/10.png',
    },
    {
      id: 11,
      text: '¿Cómo puedo ver las últimas consultas que realicé en la historia clínica de un familiar del paciente?',
      img: '/assets/img/guide/11.png',
    },
    {
      id: 12,
      text: '¿Cómo accedo al árbol de un paciente?',
      img: '/assets/img/guide/12.png',
    },
    {
      id: 13,
      text: 'Pasos para ir desde el alta del paciente hasta el árbol y viceversa',
      img: '/assets/img/guide/13.png',
    },
    {
      id: 14,
      text: ' ¿Cómo puedo agregar un nuevo familiar del paciente dentro del árbol?',
      img: '/assets/img/guide/14.png',
    },
    {
      id: 15,
      text: 'Pasos para marcar como desconocido a un familiar',
      img: '/assets/img/guide/15.png',
    },
    {
      id: 16,
      text: '¿Cómo hago para editar o actualizar los datos del paciente dentro del árbol?',
      img: '/assets/img/guide/16.png',
    },
    {
      id: 17,
      text: '¿Cómo hago para editar o actualizar los datos del familiar del paciente dentro del árbol?',
      img: '/assets/img/guide/17.png',
    },
    {
      id: 18,
      text: '¿Cómo puedo compartir un árbol?',
      img: '/assets/img/guide/18.png',
    },
    {
      id: 19,
      text: '¿Cómo puedo imprimir el árbol de un paciente?',
      img: '/assets/img/guide/19.png',
    },
    {
      id: 20,
      text: '¿Cómo puedo descargar el árbol?',
      img: '/assets/img/guide/20.png',
    },
    {
      id: 21,
      text: '¿Cómo puedo hacer zoom en el árbol?',
      img: '/assets/img/guide/21.png',
    },
    {
      id: 22,
      text: '¿Cómo puedo filtrar la visualización del árbol?',
      img: '/assets/img/guide/22.png',
    },
    {
      id: 23,
      text: '¿Cómo puedo sumar una nota en el árbol?',
      img: '/assets/img/guide/23.png',
    },
    {
      id: 24,
      text: '¿Cómo puedo importar el árbol?',
      img: '/assets/img/guide/24.png',
    },
    {
      id: 25,
      text: '¿Cómo puedo Eliminar familiares?',
      img: '/assets/img/guide/25.png',
    },
    {
      id: 26,
      text: '¿Cómo puedo ver las estadísticas generales de mis pacientes?',
      img: '/assets/img/guide/26.png',
    },
    {
      id: 27,
      text: '¿Cómo puede ver las estadísticas de un paciente?',
      img: '/assets/img/guide/27.png',
    },
    {
      id: 28,
      text: '¿Cómo puede ver las estadísticas de un familiar del paciente?',
      img: '/assets/img/guide/28.png',
    },
    {
      id: 29,
      text: '¿Cómo puedo enviar una consulta al soporte técnico?',
      img: '/assets/img/guide/29.png',
    },
    {
      id: 30,
      text: '¿Cómo puedo ver la bibliografía de la app?',
      img: '/assets/img/guide/30.png',
    },
    {
      id: 31,
      text: '¿Cómo puedo salir de la aplicación?',
      img: '/assets/img/guide/31.png',
    }
  ];
  constructor() { }

  get uuid(): string {
    const block = () => {
      return (
        moment.now() +
        this.random() +
        this.random() +
        this.random() +
        this.random()
      ).toString(16);
    };
    return block() + '-' + block() + '-' + block();
  }

  private random() {
    // tslint:disable-next-line:no-bitwise
    return ((1 + Math.random()) * 0x100000 | 0);
  }

  validatePathImg(path: string) {
    return !(path.search('.PNG') > -1 || path.search('.JPEG') > -1 ||
      path.search('.JPG') > -1 || path.search('.png') > -1 ||
      path.search('.jpeg') > -1 || path.search('.jpg') > -1);
  }

  resetKindred(patient_id, node: any = {}) {
    const data: any = {
      key: node.key,
      id: node.id,
      firstname: NA,
      lastname: NA,
      patient_id: patient_id,
      n: 'D',
      tooltips: 'DD',
      s: 'N',
      a: [],
      birthdate: null,
      death: null,
      type_relationship: node.type_relationship,
      state: '4',
      f: node.f,
      m: node.m,
      ux: node.ux,
      tw: node.tw,
      cs: node.cs,
      vir: node.vir,
      loc: node.loc,
      // datos medicos
      mutation: [],
      another_mutation: null,
      symptomatic: null,
      age_of_diagnosis: null,
      age_first_symptom: null,
      disease_state: null,
      state_test: '4',
      phenotype: [],
      manifestation_type: null,
      adopted: null,
      pregnant: null,
      cause: null,
      note: null,
    }
    return data;
  }

  fixkeys(resp) {
    let kindreds = resp.kindreds;
    const keys = _.map(kindreds, 'key');
    kindreds = _.map(kindreds, (x) => {
      x.vir = _.intersection(keys, x.vir);
      x.ux = _.intersection(keys, x.ux);
      x.mutation = _.filter(Array.isArray(x.mutation) ? x.mutation : [], (x) => {
        return !!x && x != "null";
      }).map((x) => String(x));
      x.phenotype = _.filter(Array.isArray(x.phenotype) ? x.phenotype : (_.isNumber(_.toNumber(x.phenotype)) ? !!x.phenotype ? [`${x.phenotype}`] : [] : [])).map((x) => {
        return String(x);
      });
      if (['1', '3', '4'].indexOf(x.state_test) > -1) {
        x.mutation = [];
      } else if (x.state === '4') {
        x.mutation = _.difference(x.mutation, ['7']);
        x.mutation = x.mutation.slice(0, 4);
      }
      x.disease_level = x.disease_level || '0';
      return x;
    });
    resp.kindreds = kindreds;
    return resp;
  }

  getKindreds(value, patient_id, initCouples, initGeminis, initSons, owner, kindreds = [], node: any = {}, cs: any[] = []) {
    const data: any = node.key === 1 || !patient_id ? {
      id: value.id,
      uuid: value.uuid,
      owner,
      firstname: value.firstname ? value.firstname.charAt(0).toUpperCase() + value.firstname.slice(1).toLowerCase() : null,
      lastname: value.lastname ? value.lastname.charAt(0).toUpperCase() + value.lastname.slice(1).toLowerCase() : null,
      initials: initials(value.firstname, value.lastname),
      birthdate: value.birthdate || null,
      s: value.s || null,
    } : {};
    if (patient_id) {
      data.id = patient_id;
    } else {
      node.key = 1;
    }

    data.kindreds = [_.assign(node, {
      n: n(value.firstname, value.lastname),
      tooltips: tooltips(name(value.firstname), name(value.lastname)),
      s: value.s || null,
      a: _.difference(node.a, ['C', 'F', 'K', 'G', 'S']),
      patient_id,
      firstname: name(value.firstname),
      lastname: name(value.lastname),
      initials: initials(value.firstname, value.lastname),
      birthdate: value.birthdate ? value.birthdate : null,
      death: value.death ? value.death : null,
      // datos medicos
      mutation: value.mutation ? value.mutation : [],
      another_mutation: value.another_mutation ? value.another_mutation : null,
      symptomatic: value.symptomatic,
      age_of_diagnosis: value.age_of_diagnosis ? value.age_of_diagnosis : null,
      age_first_symptom: value.age_first_symptom ? value.age_first_symptom : null,
      disease_state: value.disease_state ? value.disease_state : null,
      state_test: value.state_test ? value.state_test : null,
      phenotype: value.phenotype ? value.phenotype : [],
      manifestation_type: value.manifestation_type ? value.manifestation_type : null,
      adopted: value.adopted || null,
      pregnant: value.pregnant || null,
      cause: value.cause || null,
      note: value.note || null,
      state: value.state || null,
      type_relationship: value.type_relationship || null,
      cs: Array.isArray(value.cs) ? value.cs : [],
      country: value.country || null,
      city: value.city || null,
      city_name: value.city_name || null
    })];

    // Data Fathers
    const node_mother = _.clone(_.find(kindreds, { key: node.m })) || {};
    const node_father = _.clone(_.find(kindreds, { key: node.f })) || {};
    const father = !!value.father_firstname && !!value.father_lastname;
    const mother = !!value.mother_firstname && !!value.mother_lastname;
    // if (!!father && !!mother) {
    if (!!father || !!mother) {
      const dataf = _.assign(node_father, {
        key: node_father.key || Math.max(kindreds.length > 0 ? _.maxBy(kindreds, 'key').key : 0, data.kindreds.length > 0 ? _.maxBy(data.kindreds, 'key').key : 0) + 1,
        n: n(value.father_firstname, value.father_lastname),
        s: node_father.s || 'M',
        tooltips: tooltips(name(value.father_firstname), name(value.father_lastname)),
        a: value.father === '2' ? ['S'] : [],
        state: value.father || null,
        firstname: name(value.father_firstname),
        lastname: name(value.father_lastname),
        initials: initials(value.father_firstname, value.father_lastname),
        birthdate: value.father_birthdate || null,
        cause: value.father_cause || null,
        death: value.father_death || null,
        another_mutation: value.father_another_mutation || null,
        mutation: value.father_mutation || [],
        disease_state: value.father_disease_state ? value.father_disease_state : null,
        symptomatic: value.father === '3' ? null : value.father_symptomatic,
        age_of_diagnosis: value.father_age_of_diagnosis ? value.father_age_of_diagnosis : null,
        age_first_symptom: value.father_age_first_symptom ? value.father_age_first_symptom : null,
        phenotype: value.father_phenotype || [],
        state_test: value.father_state_test || null,
        country: value.father_country || null,
        city: value.father_city || null,
        city_name: value.father_city_name || null
      });
      data.kindreds.push(dataf);

      const datam = _.assign(node_mother, {
        key: node_mother.key || Math.max(kindreds.length > 0 ? _.maxBy(kindreds, 'key').key : 0, data.kindreds.length > 0 ? _.maxBy(data.kindreds, 'key').key : 0) + 1,
        n: n(value.mother_firstname, value.mother_lastname),
        s: node_mother.s || 'F',
        tooltips: tooltips(name(value.mother_firstname), name(value.mother_lastname)),
        a: value.mother === '2' ? ['S'] : [],
        state: value.mother || null,
        firstname: name(value.mother_firstname),
        lastname: name(value.mother_lastname),
        initials: initials(value.mother_firstname, value.mother_lastname),
        birthdate: value.mother_birthdate || null,
        cause: value.mother_cause || null,
        death: value.mother_death || null,
        another_mutation: value.mother_another_mutation || null,
        mutation: value.mother_mutation || [],
        disease_state: value.mother_disease_state ? value.mother_disease_state : null,
        symptomatic: value.mother === '3' ? null : value.mother_symptomatic,
        age_of_diagnosis: value.mother_age_of_diagnosis ? value.mother_age_of_diagnosis : null,
        age_first_symptom: value.mother_age_first_symptom ? value.mother_age_first_symptom : null,
        phenotype: value.mother_phenotype || [],
        state_test: value.mother_state_test || null,
        country: value.mother_country || null,
        city: value.mother_city || null,
        city_name: value.mother_city_name || null
      });
      data.kindreds.push(datam);

      data.kindreds[0].f = dataf.key;
      data.kindreds[0].m = datam.key;

      ['ux', 'vir'].forEach((k) => {
        if (!dataf[k]) {
          dataf[k] = [];
        }
        if (!datam[k]) {
          datam[k] = [];
        }
      })
      dataf.vir = _.difference(dataf.vir, [node.m, node.f]);
      dataf.ux = _.difference(dataf.ux, [node.m, node.f]);
      if (dataf.ux.indexOf(node.m) === -1) {
        dataf.ux.push(node.m);
      }

      datam.vir = _.difference(datam.vir, [node.m, node.f]);
      datam.ux = _.difference(datam.ux, [node.m, node.f]);
      if (datam.vir.indexOf(node.f) === -1) {
        datam.vir.push(node.f);
      }

      const indexF = _.findIndex(data.kindreds, { key: dataf.key });
      if (indexF > -1) {
        data.kindreds[indexF] = dataf;
      }

      const indexM = _.findIndex(data.kindreds, { key: datam.key });
      if (indexM > -1) {
        data.kindreds[indexM] = datam;
      }

    } else if (!!father && !mother) {
      /*this.toastService.presentToast({
        text: 'No se puede logro guardar los datos del padre debe agregar tambien los datos de la madre'
      });*/
      console.warn('NO TIENE MADRE CARGADA QUE HACER');
    } else if (!!mother && !father) {
      /*this.toastService.presentToast({
        text: 'No se puede logro guardar los datos de la madre debe agregar tambien los datos del padre'
      });*/
      console.warn('NO TIENE PADRE CARGADO QUE HACER');
    }
    // Data Couples
    const couplesUUID = {};
    initCouples.value.forEach(couple => {
      if (couple.state !== '5') {
        if (!!couple.firstname && !!couple.lastname) {
          couple.key = typeof couple.key === 'string' ? null : couple.key;
          const node_couple = _.clone(_.find(kindreds, { key: couple.key })) || {};
          const updateOrCreate = _.assign(node_couple, {
            key: node_couple.key || Math.max(kindreds.length > 0 ? _.maxBy(kindreds, 'key').key : 0, data.kindreds.length > 0 ? _.maxBy(data.kindreds, 'key').key : 0) + 1,
            n: n(couple.firstname, couple.lastname),
            s: couple.s,
            tooltips: tooltips(name(couple.firstname), name(couple.lastname)),
            a: couple.state === '2' ? (Array.isArray(couple.a) && couple.a.indexOf('S') === -1 ? couple.a.concat(['S']) : couple.a) : [],
            state: couple.state,
            firstname: name(couple.firstname),
            lastname: name(couple.lastname),
            initials: initials(couple.firstname, couple.lastname),
            patient_id,
            birthdate: couple.birthdate || null,
            death: couple.death || null,
            cause: couple.cause || null,
            state_test: couple.state_test || null,
            mutation: couple.mutation ? couple.mutation : [],
            another_mutation: couple.another_mutation ? couple.another_mutation : null,
            symptomatic: couple.state === '3' ? null : couple.symptomatic,
            age_of_diagnosis: couple.age_of_diagnosis ? couple.age_of_diagnosis : null,
            age_first_symptom: couple.age_first_symptom ? couple.age_first_symptom : null,
            phenotype: couple.phenotype || [],
            disease_state: couple.disease_state || null,
            country: couple.country || null,
            city: couple.city || null,
            city_name: couple.city_name || null
          });

          ['ux', 'vir'].forEach((k) => {
            if (!updateOrCreate[k]) {
              updateOrCreate[k] = [];
            }
            if (!data.kindreds[0][k]) {
              data.kindreds[0][k] = [];
            }
          });
          const sons: any[] = _.filter(kindreds, (x) => {
            return (x.f === node.key && x.m === updateOrCreate.key) || (x.m === node.key && x.f === updateOrCreate.key);
          });

          const isSons = sons.length > 0, isGM = value.s == 'M';
          const f = isSons ? sons[0].f : (isGM ? data.kindreds[0].key : updateOrCreate.key), m = isSons ? sons[0].m : (!isGM ? data.kindreds[0].key : updateOrCreate.key);
          const isF = node.key === f, isM = node.key === m;

          data.kindreds[0].vir = _.difference(data.kindreds[0].vir, [f, m]);
          data.kindreds[0].ux = _.difference(data.kindreds[0].ux, [f, m]);
          if (data.kindreds[0][isF ? 'ux' : 'vir'].indexOf(isF ? m : f) === -1) {
            data.kindreds[0][isF ? 'ux' : 'vir'].push(isF ? m : f);
          }

          updateOrCreate.vir = _.difference(updateOrCreate.vir, [f, m]);
          updateOrCreate.ux = _.difference(updateOrCreate.ux, [f, m]);
          if (updateOrCreate[isM ? 'ux' : 'vir'].indexOf(isM ? m : f) === -1) {
            updateOrCreate[isM ? 'ux' : 'vir'].push(isM ? m : f);
          }

          couplesUUID[couple.uuid] = updateOrCreate;
          data.kindreds.push(updateOrCreate);
        }
      }
    });
    data.kindreds[0].cs = Array.isArray(data.kindreds[0].cs) ? data.kindreds[0].cs : [];

    data.kindreds[0].vir = _.difference(data.kindreds[0].vir, cs || []);
    data.kindreds[0].ux = _.difference(data.kindreds[0].ux, cs || []);

    (data.kindreds[0].cs || []).forEach(key => {
      const sons: any[] = _.filter(kindreds, (x) => {
        return (x.f === node.key && x.m === key) || (x.m === node.key && x.f === key);
      });
      const isSons = sons.length > 0, isGM = value.s == 'M';
      const f = isSons ? sons[0].f : (isGM ? data.kindreds[0].key : key), m = isSons ? sons[0].m : (!isGM ? data.kindreds[0].key : key);
      const isF = node.key === f, isM = node.key === m;

      if (data.kindreds[0][isF ? 'ux' : 'vir'].indexOf(isF ? m : f) === -1) {
        data.kindreds[0][isF ? 'ux' : 'vir'].push(isF ? m : f);
      }

      const updateOrCreate = _.find(kindreds, { key });
      updateOrCreate.vir = _.difference(updateOrCreate.vir, [f, m]);
      updateOrCreate.ux = _.difference(updateOrCreate.ux, [f, m]);
      let cs = [];
      if (updateOrCreate[isM ? 'ux' : 'vir'].indexOf(isM ? m : f) === -1) {
        updateOrCreate[isM ? 'ux' : 'vir'].push(isM ? m : f);
        cs.push(isM ? m : f);
      }
      updateOrCreate.cs = Array.isArray(updateOrCreate.cs) ? updateOrCreate.cs : [];
      updateOrCreate.cs = _.difference(updateOrCreate.cs, cs);
      if (updateOrCreate.cs.indexOf(isM ? m : f) === -1) {
        updateOrCreate.cs.push(isM ? m : f);
      }
      data.kindreds.push(updateOrCreate);

    });

    // Data Sons
    const initGeminisObj = {};
    initGeminis.value.forEach(t => {
      if (t.children1 != '' && t.children2 != '' && t.children1 !== t.children2) {
        initGeminisObj[t.children1] = { key: t.children2, identical: t.identical, uuid: t.children2 };
        initGeminisObj[t.children2] = { key: t.children1, identical: t.identical, uuid: t.children1 };
      }
    });
    initSons.value.forEach(son => {
      // if (typeof son.parent === 'string') {
      let couple;
      if (typeof son.parent === 'string') {
        couple = couplesUUID[son.parent];
      } else {
        couple = _.find(_.filter(couplesUUID), { key: son.parent });
      }
      if (!!couple) {
        son.parent = couple.key;
      }
      // }
      // console.log('hijos -------> son', son)
      if (!!son.firstname && !!son.lastname && son.parent > 0) {
        const node_son = _.clone(_.find(kindreds, { key: son.key })) || {};
        const brothers: any[] = _.filter(kindreds, (x) => {
          return (x.f === node.key && x.m === son.parent) || (x.m === node.key && x.f === son.parent);
        });
        const isB = brothers.length > 0, isGM = value.s == 'M';
        let f: number = isB ? brothers[0].f : (isGM ? data.kindreds[0].key : son.parent)
        let m: number = isB ? brothers[0].m : (!isGM ? data.kindreds[0].key : son.parent);
        const updateOrCreate = {
          key: node_son.key || Math.max(kindreds.length ? _.maxBy(kindreds, 'key').key : 0, data.kindreds.length > 0 ? _.maxBy(data.kindreds, 'key').key : 0) + 1,
          n: n(son.firstname, son.lastname),
          tooltips: tooltips(name(son.firstname), name(son.lastname)),
          s: son.s || 'N',
          f,
          m,
          patient_id,
          firstname: name(son.firstname),
          lastname: name(son.lastname),
          initials: initials(son.firstname, son.lastname),
          birthdate: son.birthdate,
          type_relationship: son.type_relationship ? son.type_relationship : null,
          death: son.death || null,
          state_test: son.state_test || null,
          cause: son.cause || null,
          state: son.state || null,
          symptomatic: son.state === '3' ? null : son.symptomatic,
          age_of_diagnosis: son.age_of_diagnosis ? son.age_of_diagnosis : null,
          age_first_symptom: son.age_first_symptom ? son.age_first_symptom : null,
          identical: initGeminisObj[son.uuid] ? initGeminisObj[son.uuid].identical : null,
          phenotype: son.phenotype || [],
          mutation: son.mutation ? son.mutation : [],
          vir: node_son.vir || [],
          ux: node_son.ux || [],
          disease_state: node_son.disease_state || null,
          country: son.country || node_son.country || null,
          city: son.city || node_son.city || null,
          city_name: son.city_name || node_son.city_name || null
        };
        if (!!initGeminisObj[son.uuid]) {
          initGeminisObj[son.uuid].key = updateOrCreate.key;
        }
        data.kindreds.push(updateOrCreate);
      }
    });
    // Data twins
    Object.keys(initGeminisObj).forEach((key) => {
      const obj1 = initGeminisObj[key];
      const obj2 = initGeminisObj[initGeminisObj[key].uuid];
      const twin = (o1, o2) => {
        const index = _.findIndex(data.kindreds, { key: o1.key });
        if (index >= 0) {
          data.kindreds[index].tw = o2.identical === '2' ? o2.key : null;
        }
      }
      twin(obj1, obj2);
      twin(obj2, obj1);
    })
    data.kindreds = data.kindreds.map((x) => {
      if (Array.isArray(x.mutation)) {
        x.mutation = x.mutation.slice(0, 4);
      }
      return x;
    });
    // Descomentar para testear los datos de salida al regitrar o  actualizar
    data.kindreds.forEach(e => {
      // console.log('Descomentar para testear ---->', e)
      // console.log('e----->', _.pick(e, ['key', 'n', 'firstname', 'lastname', 'tooltips', 'f', 'm', 'vir', 'ux', 'a', 'state']))
    });
    return data;
  }
}
