import { Injectable,Injector } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class CustomTranslateService implements TranslateLoader {

   constructor(private inej:Injector){}

  getTranslation(lang: string): Observable<any> {
    let http = this.inej.get(HttpClient);
    switch(lang) {
      case 'es':
      case 'en':
      case 'fr':
        return http.get(`./assets/i18n/${lang}.json`);
      default:
        return http.get(`./assets/i18n/es.json`);
    }
  }

}
