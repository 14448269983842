import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ConfigStorage } from '../../storage/config/config.service';
import { StorageMasterService } from '../../storage/storage-master.service';
// import { StatusBarService } from '@app/shared/components/status-bar/status-bar.service';

/**
 * @class AuthInterceptorService
 * @description Interception the all petition
 */
@Injectable({
    providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

    expires: number;

    constructor(
        private configStorage: ConfigStorage,
        // private statusBarService: StatusBarService,
        private storageMasterService: StorageMasterService) {
    }

    /**
     * @description Method intercept
     * @param request the request
     * @param next
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = (this.configStorage.get() !== undefined) ? this.configStorage.get().token : undefined;
        if (token !== undefined && token !== null && token !== '') {
            const headers = request.headers.set('Authorization', 'Bearer ' + token).set('Accept', 'application/json');
            request = request.clone({ headers });
            return next.handle(request).pipe(
                map((event: HttpEvent<any>) => {
                    return event;
                }),
                catchError((error: HttpErrorResponse) => {
                    this.error(error);
                    return throwError(error);
                })
            );
        } else {
            return next.handle(request).pipe(
                map((event: HttpEvent<any>) => {
                    return event;
                }),
                catchError((error: HttpErrorResponse) => {
                    this.error(error);
                    return throwError(error);
                })
            );
        }
    }

    error(error) {
        /*if (error.status === 0) {
            const timer = (this.expires || 0) - new Date().getTime();
            const enc = timer < 0 ? 0 : Math.trunc(timer / 1000);
            console.log(enc);
            if (enc === 0) {
                this.expires = new Date().getTime() + (60 * 400);
                this.statusBarService.showError('Sin conexión a internet');
                setTimeout(() => {
                    this.statusBarService.hide();
                }, 5000);
            }
        }*/
        if (error.status === 401) {
            this.storageMasterService.clearSession();
        } else if (error.status === 403) {
            // this.globals.disabledUser();
        } else if (error.status === 503) {
            // this.globals.dismissModal();
        }
    }
}
