import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpService {


  constructor(
    private http: HttpClient
    ) {}


  post(url : string, params? : any) : Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.post(`${environment.API_URL}${url}`, params, {params: params})
      .subscribe((response : any) => {
        resolve({data: response.data, msg: response.msg});
        },(error) : any => {
          // console.error('post error: ', error);
          reject({msg : error.msg});
        });
    });
  }

  postWithoutToken(url: string, params?: any) : Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.post(`${environment.API_URL}${url}`, params, {params: params})
      .subscribe((response : any) => {
        resolve({data : response.data});
        },(error) : any => {
          // console.error('postWithoutToken error: ', error);
          reject({msg : error});
        });
    });
  }
}
