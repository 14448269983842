import { createAnimation, Animation } from '@ionic/core';


    export function myLeaveAnimation( rootElement: HTMLElement ): Animation {

        const backdropAnimation = createAnimation()
        .addElement(rootElement.querySelector('ion-backdrop')!)
        .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');
        
        const wrapperAnimation = createAnimation()
        .addElement(rootElement.querySelector('.modal-wrapper')!);

        const wrapperElRect = rootElement!.getBoundingClientRect();

        wrapperAnimation.beforeStyles({ 'opacity': 1 })
        .fromTo('transform', `${window.innerWidth - wrapperElRect.left}px`, 'translateX(-100%)' );
        
        return createAnimation()
        .addElement(rootElement)
        .easing('ease-out')
        .duration(400)
        .beforeAddClass('show-modal')
        .addAnimation([backdropAnimation, wrapperAnimation]
        );
}
