import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { SelectNumberComponent } from '../select-number/select-number.component';
import * as moment from 'moment';
import * as _ from 'lodash';
const age = (value: any []) => {
  const v1 = value[0];
  const v2 = _.toNumber(value[1]);
  let year = _.toNumber(v1);
  const now = (new Date).getFullYear();
  if (moment(v1, "DD/MM/YYYY", true).isValid()) {
    year = _.toNumber(moment(moment(v1, "DD/MM/YYYY")).format('YYYY'));
  } else if (!_.isNumber(year) || !_.isNumber(v2)) {
    return -1;
  }
  return !!v2 ? (year < v2 ? v2 - year : 0) : (year > 0 && now > year ? now - year : 0);
};

const innerHtml = (value): string => {
  let a = age(value);
  return !!value[0] ? `
  Edad ${!!value[0] && !!value[1] && a >= 0 ? 'de obito' : ''} del paciente:
  <b>${a > -1 ? a : 0} Año(s)</b>
  ` : '';
} 
@Component({
  selector: 'birthday',
  templateUrl: './birthday.component.html',
  styleUrls: ['./birthday.component.scss'],
})
export class BirthdayComponent implements OnInit {

  birthdate: any;
  death: any;
  isModal: boolean;
  valueRadio = '1';
  age: string;
  @Input() isDebugger: boolean;
  @Input() readonly;
  @Input() formBirthdate: FormControl;
  @Input() datePickerObj;

  @Input() formState: FormControl;
  @Input() formDeath: FormControl;

  constructor(
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {
    this.init(this.formBirthdate.value);
    this.death = this.formDeath.value;
    this.formBirthdate.valueChanges.subscribe((birthdate) => {
      this.init(birthdate); 
    });
    this.formDeath.valueChanges.subscribe((death) => {
      this.death = death;
      this.setAge();
    });
  }

  init(birthdate) {
    this.birthdate = birthdate;
    this.valueRadio = this.isDateValid() ? '0' : '1';
    this.setAge();
  }

  setAge() {
    this.age = innerHtml([this.formBirthdate.value, this.formDeath.value, this.formState.value]);
    if (this.isDebugger) {
      console.log([this.formBirthdate.value, this.formDeath.value, this.formState.value], this.age)
    }
  }

  isDateValid() {
    return moment(this.formBirthdate.value, "DD/MM/YYYY", true).isValid();
  }

  selectDate(e) {
    if (!!this.birthdate && !this.isDateValid() && e === '0') {
      this.setBirthdate(null);
    }
    this.valueRadio = e;
  }

  myFunction() {
    if (this.birthdate !== this.formBirthdate.value) {
      this.setBirthdate(this.birthdate);
    }
  }

  async openNumbers(value) {
    if (this.isModal || this.readonly) {
      return;
    }
    this.isModal = true;
    const modal = await this.modalCtrl.create({
      component: SelectNumberComponent,
      componentProps: {
        range: [1800, new Date().getFullYear()],
        selectedNumber: new Date().getFullYear(),
      },
      cssClass: 'select-number-modal'
    });
    modal.onDidDismiss().then((data) => {
      this.isModal = false;
      if (data && data.data) {
        if (value === 1) {
          this.setBirthdate(data.data);
        } else {
          this.setDeath(data.data);
        }
      }
    });
    return await modal.present();
  }

  setBirthdate(value = null) {
    this.formBirthdate.setValue(value);
  }

  setDeath(value = null) {
    this.formDeath.setValue(value);
  }

}
