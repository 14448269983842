import { createAnimation, Animation } from '@ionic/core';


    export function myEnterAnimation( rootElement: HTMLElement ): Animation {

        const backdropAnimation = createAnimation()
        .addElement(rootElement.querySelector('ion-backdrop')!)
        .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');
        
        const wrapperAnimation = createAnimation()
        .addElement(rootElement.querySelector('.modal-wrapper')!)
        .beforeStyles({ 'opacity': 1 })
        .fromTo('transform', 'translateX(-100%)', 'translateX(0%)');
        return createAnimation()
        .addElement(rootElement)
        .easing('ease-out')
        .duration(400)
        .beforeAddClass('show-modal')
        .addAnimation([backdropAnimation, wrapperAnimation]
        );
}
