import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ToastService } from '@app/core/services';
import { IonContent, IonSlides } from '@ionic/angular';
import * as moment from 'moment';
@Component({
  selector: 'app-register-consultion',
  templateUrl: './register-consultion.component.html',
  styleUrls: ['./register-consultion.component.scss'],
})
export class RegisterConsultionComponent implements OnInit {
  @ViewChild('slides', { static: true }) slides: IonSlides;
  @ViewChild(IonContent, { static: true }) content: IonContent;
  @ViewChild('bodyContent', { static: false }) bodyContent: ElementRef;
  @ViewChild('targetFirst') targetFirst: ElementRef;
  @ViewChild('selectNotificationList') selectNotificationList: any;

  @Input('selectOption') selectOption: any;
  @Input('saveIn') saveIn: any;
  @Output("actionOnSave") actionOnSave: EventEmitter<any>;
  @Input('valueRadio') valueRadio: any;
  @Output("actionRadio") actionRadio: EventEmitter<any>;
  @Input('history_date') history_date: any;
  @Output("actionHistoryDate") actionHistoryDate: EventEmitter<any>;

  @Input('antecedent') antecedent: any;
  @Input('inputAntecedent') inputAntecedent: Array<any>;
  @Input('antecedentIn') antecedentIn: any;

  @Input('nervous') nervous: any;
  @Input('inputNervous') inputNervous: Array<any>;
  @Input('nervousIn') nervousIn: any;

  @Input('cardiovascular') cardiovascular: any;
  @Input('inputCardiovascular') inputCardiovascular: any;
  @Input('cardiovascularIn') cardiovascularIn: any;

  @Input('gastrointestinal') gastrointestinal: any;
  @Input('inputGastrointestinal') inputGastrointestinal: any;
  @Input('gastrointestinalIn') gastrointestinalIn: any;

  @Input('genitourinario') genitourinario: any;
  @Input('inputGenitourinario') inputGenitourinario: any;
  @Input('genitourinarioIn') genitourinarioIn: any;

  @Input('ophthalmus') ophthalmus: any;
  @Input('inputOphthalmus') inputOphthalmus: any;
  @Input('ophthalmusIn') ophthalmusIn: any;

  @Output('actionAntecedent') actionAntecedent: EventEmitter<any>;
  @Output("actionNervous") actionNervous: EventEmitter<any>;
  @Output("actionCardiovascular") actionCardiovascular: EventEmitter<any>;
  @Output("actionGastrointestinal") actionGastrointestinal: EventEmitter<any>;
  @Output("actionGenitourinario") actionGenitourinario: EventEmitter<any>;
  @Output("actionOphthalmus") actionOphthalmus: EventEmitter<any>;

  @Input('exam') exam: any;
  @Input('inputExam') inputExam: any;

  @Input('evaluation') evaluation: any;
  @Input('inputEvaluation') inputEvaluation: any;

  @Input('complementary') complementary: any;
  @Input('inputComplementary') inputComplementary: any;

  @Input('scale') scale: any;
  @Input('inputScale') inputScale: any;

  @Output("actionExam") actionExam: EventEmitter<any>;
  @Output("actionEvaluation") actionEvaluation: EventEmitter<any>;
  @Output("actionComplementary") actionComplementary: EventEmitter<any>;
  @Output("actionScale") actionScale: EventEmitter<any>;

  @Input('treatment') treatment: any;
  @Input('inputs') inputs: any;

  @Output("actionTreatment") actionTreatment: EventEmitter<any>;

  @Output("actionSave") actionSave: EventEmitter<any>;
  // selectOption: Array<any> = new Array();
  select1: boolean = false
  select2: boolean = false
  select3: boolean = false
  select4: boolean = false
  select5: boolean = false
  select6: boolean = false
  select7: boolean = false
  select8: boolean = false
  select9: boolean = false
  select10: boolean = false
  select11: boolean = false
  colorfirst: any;
  colorsecond: any;
  colorthird: any;
  class = 'segment-1';
  menu: string;
  segment: any = 0;
  minBirth: any;
  segmentHeight: any;
  slideOpts: any = {
  };
  public slider: any;
  menuOptions: Array<any> = [
    {
      value: '1',
      name: 'Antecedentes / Comorbilidades'
    },
    {
      value: '2',
      name: 'Sistema nervioso'
    },
    {
      value: '3',
      name: 'Sistema cardiovascular'
    },
    {
      value: '4',
      name: 'Sistema gastrointestinal'
    },
    {
      value: '5',
      name: 'Sistema genitourinario'
    },
    {
      value: '6',
      name: 'Oftalmo / psiquiatría'
    }
  ]
  datePickerObj: any = {
    inputDate: new Date(),
    showTodayButton: false,
    setLabel: 'Guardar',  // default 'Set'
    closeLabel: 'Cerrar', // default 'Close'
    dateFormat: 'DD/MM/YYYY',
    monthsList: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
    weeksList: ["D", "L", "M", "M", "J", "V", "S"],
    btnProperties: {
      expand: 'block',
    }
  };
  listSelect: Array<any> = [
    { name: 'Antecedentes comorbilidades', value: 1 },
    { name: 'Sistema nervioso', value: 2 },
    { name: 'Sistema cardiovascular', value: 3 },
    { name: 'Sistema gastrointestinal', value: 4 },
    { name: 'Sistema genitourinario', value: 5 },
    { name: 'Oftalmo / psiquiatría', value: 6 },
    { name: 'Examenes', value: 7 },
    { name: 'Evaluaciones', value: 8 },
    { name: 'Estudios complemetarios', value: 9 },
    { name: 'Escalas', value: 10 },
    { name: 'Tratamiento actual', value: 11 },
  ];

  constructor(
    private toastService: ToastService,
  ) {
    this.actionAntecedent = new EventEmitter();
    this.actionNervous = new EventEmitter();
    this.actionCardiovascular = new EventEmitter();
    this.actionGastrointestinal = new EventEmitter();
    this.actionGenitourinario = new EventEmitter();
    this.actionOphthalmus = new EventEmitter();

    this.actionExam = new EventEmitter();
    this.actionEvaluation = new EventEmitter();
    this.actionComplementary = new EventEmitter();
    this.actionScale = new EventEmitter();

    this.actionTreatment = new EventEmitter();

    this.actionRadio = new EventEmitter();
    this.actionHistoryDate = new EventEmitter();
    this.actionSave = new EventEmitter();
    this.actionOnSave = new EventEmitter();

  }

  ngOnInit() {

    this.select1 = false
    this.select2 = false
    this.select3 = false
    this.select4 = false
    this.select5 = false
    this.select6 = false
    this.select7 = false
    this.select8 = false
    this.select9 = false
    this.select10 = false
    this.select11 = false

  }
  ionViewDidEnter() {
  }
  async onDate() {
    await this.actionHistoryDate.emit(this.history_date);
  }

  onChangeRadio(e) {
    this.actionRadio.emit(e);
  }

  onChangeSelect(e) {
    this.selectOption
    console.log(this.bodyContent.nativeElement)

    console.log(this.content);
    this.content.getScrollElement().then(scrollElement => {
      console.log("clientHeight: ", scrollElement.clientHeight);
      // console.log("clientWidth: ", scrollElement.clientWidth);
      console.log("scrollHeight: ", scrollElement.scrollHeight);
      // console.log("scrollWidth: ", scrollElement.scrollWidth);
    });
    // const height = this.targetFirst.offsetHeight;
    // console.log(height);
    // const height = this.targetSecond.offsetHeight;
    // Here you can use the height!
    // this.arrayAux = []


    // this.arrayAux = this.selectOption
    // this.arrayAux.forEach(element => {     

    if (this.selectOption == 1) {
      this.select1 = true;
      //  this.class = 'segment1';
      this.colorfirst = 'item-first';
    }

    if (this.selectOption == 2) {
      this.select2 = true;
      // this.class = 'segment-2';  
      this.colorfirst = 'item-first';

    }

    if (this.selectOption == 3) {
      this.select3 = true;
      // this.class = 'segment-3'; 
      this.colorfirst = 'item-first';
    }

    if (this.selectOption == 4) {
      this.select4 = true;
      // this.class = 'segment-4';  
      this.colorfirst = 'item-first';
    }

    if (this.selectOption == 5) {
      this.select5 = true;
      // this.class = 'segment-5'; 
      this.colorfirst = 'item-first';
    }

    if (this.selectOption == 6) {
      this.select6 = true;
      this.colorfirst = 'item-first';
    }

    if (this.selectOption == 7) {
      this.select7 = true;
      this.class = 'seg-1';
      this.colorsecond = 'item-second';
    }

    if (this.selectOption == 8) {
      this.select8 = true;
      // this.class = 'segm2';  
      this.colorsecond = 'item-second';
    }

    if (this.selectOption == 9) {
      this.select9 = true;
      // this.class = 'seg-3';
      this.colorsecond = 'item-second';
    }

    if (this.selectOption == 10) {
      this.select10 = true;
      // this.class = 'segm-4';  
      this.colorsecond = 'item-second';
    }

    if (this.selectOption == 11) {
      this.select11 = true;
      // this.class = 'segment';  
      this.colorthird = 'item-third';
    }
    // });
    // if (this.select1 && this.select2) {
    //   this.class = 'segment2';
    // }
    // if (this.select1 && this.select3) {
    //   // this.class = 'segment2';
    // }
    // if (this.select1 && this.select2 && this.select3) {
    //   // this.class = 'segment3';
    // }
    // if (this.select1 && this.select2 && this.select3 && this.select4) {
    //   this.class = 'segment4';
    // }
    // if (this.select1 && this.select2 && this.select3 && this.select4 && this.select5) {
    //   this.class = 'segment5';
    // }
    // if (this.select1 && this.select2 && this.select3 && this.select4 && this.select5 && this.select6) {
    //   this.class = 'segment6';
    // }
    // if (this.select1 && this.select2 && this.select3 && this.select4 && this.select5 && this.select6 && this.select7) {
    //   this.class = 'seg1';
    // }
    // if (this.select1 && this.select2 && this.select3 && this.select4 && this.select5 && this.select6 && this.select7 && this.select8) {
    //   this.class = 'seg2';
    // }
    // if (this.select1 && this.select2 && this.select3 && this.select4 && this.select5 && this.select6 && this.select7 && this.select8 && this.select9) {
    //   this.class = 'seg3';
    // }
    // if (this.select1 && this.select2 && this.select3 && this.select4 && this.select5 && this.select6 && this.select7 && this.select8 && this.select9 && this.select10) {
    //   this.class = 'seg4';
    // }
    // if (this.select1 && this.select2 && this.select3 && this.select4 && this.select5 && this.select6 && this.select7 && this.select8 && this.select9 && this.select10 && this.select11) {
    //   this.class = 'segm';
    // }
  }
  async loadDataAntecedent(e) {
    switch (e.number) {
      case 1:
        this.antecedent.antecedent = e.data
        break;
      case 2:
        this.antecedent.physical_exam_antecedent = e.data
        break;
    }
    await this.actionAntecedent.emit(this.antecedent);
  }

  async loadDataNervous(e) {
    switch (e.number) {
      case 3:
        this.nervous.neuropathy_option = e.valueRadio
        this.nervous.neuropathy = e.data
        break;
      case 4:
        this.nervous.dysautonomia_option = e.valueRadio
        this.nervous.dysautonomia = e.data
        break;
      case 5:
        this.nervous.march_option = e.valueRadio
        this.nervous.march = e.data
        break;
      case 6:
        this.nervous.carpal_option = e.valueRadio
        this.nervous.carpa = e.data
        break;
      case 7:
        this.nervous.ulcers_option = e.valueRadio
        this.nervous.ulcers = e.data
        break;
      case 8:
        this.nervous.snc_option = e.valueRadio
        this.nervous.snc = e.data
        break;
      case 9:
        this.nervous.cognitive_option = e.valueRadio
        this.nervous.cognitive = e.data
        break;
    }
    await this.actionNervous.emit(this.nervous);
  }

  async loadCardiovascular(e) {
    switch (e.number) {
      case 10:
        this.cardiovascular.hta_option = e.valueRadio
        this.cardiovascular.hta = e.data
        break;
      case 11:
        this.cardiovascular.dbt_option = e.valueRadio
        this.cardiovascular.dbt = e.data
        break;
      case 12:
        this.cardiovascular.orthostatic_option = e.valueRadio
        this.cardiovascular.orthostatic = e.data
        break;
      case 13:
        this.cardiovascular.dyspnoea_option = e.valueRadio
        this.cardiovascular.dyspnoea = e.data
        break;
      case 14:
        this.cardiovascular.edema_option = e.valueRadio
        this.cardiovascular.edema = e.data
        break;
      case 15:
        this.cardiovascular.hypertrophic_option = e.valueRadio
        this.cardiovascular.hypertrophic = e.data
        break;
      case 16:
        this.cardiovascular.cardiac_option = e.valueRadio
        this.cardiovascular.cardiac = e.data
        break;
      case 17:
        this.cardiovascular.arrhythmia_option = e.valueRadio
        this.cardiovascular.arrhythmia = e.data
        break;
      case 18:
        this.cardiovascular.pacemaker_option = e.valueRadio
        this.cardiovascular.pacemaker = e.data
        break;
      case 19:
        this.cardiovascular.coronary_option = e.valueRadio
        this.cardiovascular.coronary = e.data
        break;
      case 20:
        this.cardiovascular.death_option = e.valueRadio
        this.cardiovascular.death = e.data
        break;
    }
    await this.actionCardiovascular.emit(this.cardiovascular);
  }

  async loadGastrointestinal(e) {
    switch (e.number) {
      case 21:
        this.gastrointestinal.imc = e.data
        this.gastrointestinal.data_imc = e.otherValue
        this.gastrointestinal.imc_height = e.imcHeight
        this.gastrointestinal.imc_weight = e.imcWeight
        break;
      case 22:
        this.gastrointestinal.diarrhea_option = e.valueRadio
        this.gastrointestinal.diarrhea = e.data
        break;
      case 23:
        this.gastrointestinal.constipation_option = e.valueRadio
        this.gastrointestinal.constipation = e.data
        break;
      case 24:
        this.gastrointestinal.weight_option = e.valueRadio
        this.gastrointestinal.weight = e.data
        break;
    }
    await this.actionGastrointestinal.emit(this.gastrointestinal);
  }

  async loadGenitourinario(e) {
    switch (e.number) {
      case 25:
        this.genitourinario.erectile_option = e.valueRadio
        this.genitourinario.erectile = e.data
        break;
      case 26:
        this.genitourinario.neurogenic_option = e.valueRadio
        this.genitourinario.neurogenic = e.data
        break;
      case 27:
        this.genitourinario.itus_option = e.valueRadio
        this.genitourinario.itus = e.data
        break;
      case 28:
        this.genitourinario.proteinuria_option = e.valueRadio
        this.genitourinario.proteinuria = e.data
        break;
      case 29:
        this.genitourinario.renal_option = e.valueRadio
        this.genitourinario.renal = e.data
        break;
    }
    await this.actionGenitourinario.emit(this.genitourinario);
  }

  async loadOphthalmus(e) {
    switch (e.number) {
      case 30:
        this.ophthalmus.eye_option = e.valueRadio
        this.ophthalmus.eye = e.data
        break;
      case 31:
        this.ophthalmus.vitreous_option = e.valueRadio
        this.ophthalmus.vitreous = e.data
        break;
      case 32:
        this.ophthalmus.pupillary_option = e.valueRadio
        this.ophthalmus.pupillary = e.data
        break;
      case 33:
        this.ophthalmus.glaucoma_option = e.valueRadio
        this.ophthalmus.glaucoma = e.data
        break;
      case 34:
        this.ophthalmus.anxiety_option = e.valueRadio
        this.ophthalmus.anxiety = e.data
        break;
      case 35:
        this.ophthalmus.other_option = e.valueRadio
        this.ophthalmus.other_ophthalmus = e.data
        break;
    }
    await this.actionOphthalmus.emit(this.ophthalmus);
  }

  async loadExam(e) {
    switch (e.number) {
      case 36:
        this.exam.physical_exam = e.data
        break;
      case 37:
        this.exam.pacemaker_exam_option = e.valueRadio
        this.exam.pacemaker_exam = e.data
        break;
      case 39:
        this.exam.molecular_study_option = e.valueRadio
        this.exam.molecular_study = e.data
        break;
    }
    await this.actionExam.emit(this.exam);
  }

  async loadEvaluation(e) {
    switch (e.number) {
      case 38:
        this.evaluation.laboratory_option = e.valueRadio
        break;
      case 39:
        this.evaluation.hto = e.data
        break;
      case 40:
        this.evaluation.gr = e.data
        break;
      case 41:
        this.evaluation.gb = e.data
        break;
      case 42:
        this.evaluation.platelet = e.data
        break;
      case 43:
        this.evaluation.glucose = e.data
        break;
      case 44:
        this.evaluation.urea = e.data
        break;
      case 45:
        this.evaluation.creatinina = e.data
        break;
      case 46:
        this.evaluation.tgo = e.data
        break;
      case 47:
        this.evaluation.tgp = e.data
        break;
      case 48:
        this.evaluation.proteinuria_evaluation = e.data
        break;
      case 49:
        this.evaluation.vit = e.data
        break;
      case 50:
        this.evaluation.prealbumina = e.data
        break;
      case 51:
        this.evaluation.albulmina = e.data
        break;
      case 52:
        this.evaluation.laboratory = e.data
        break;
      case 53:
        this.evaluation.micro_albulmina = e.data
        break;
      case 54:
        this.evaluation.diuresis = e.data
        break;
      case 55:
        this.evaluation.peptido_natriuretico = e.data
        break;
      case 56:
        this.evaluation.cpk = e.data
        break;
      case 57:
        this.evaluation.troponina = e.data
        break;
      case 58:
        this.evaluation.micro_albulmina_day = e.data
        break;
    }
    await this.actionEvaluation.emit(this.evaluation);
  }

  async loadComplementary(e) {
    switch (e.number) {
      case 53:
        this.complementary.emg_option = e.valueRadio
        this.complementary.emg = e.data
        break;
      case 54:
        this.complementary.qst_option = e.valueRadio
        this.complementary.qst = e.data
        break;
      case 55:
        this.complementary.ecg_option = e.valueRadio
        this.complementary.ecg = e.data
        break;
      case 56:
        this.complementary.cardiaca_option = e.valueRadio
        this.complementary.cardiaca = e.data
        break;
      case 57:
        this.complementary.resonancia_option = e.valueRadio
        this.complementary.resonancia = e.data
        break;
      case 58:
        this.complementary.biopsy_option = e.valueRadio
        this.complementary.biopsy = e.data
        break;
      case 59:
        this.complementary.echocardiogram_option = e.valueRadio
        this.complementary.echocardiogram = e.data
        break;
      case 61:
        this.complementary.cardiac_holter_option = e.valueRadio
        this.complementary.cardiac_holter = e.data
        break;
      case 60:
        // this.complementary.other_complementary_option = e.valueRadio
        this.complementary.other_complementary = e.data
        break;
    }
    await this.actionComplementary.emit(this.complementary);
  }

  async loadScale(e) {
    switch (e.number) {
      case 61:
        this.scale.norfolk_option = e.valueRadio
        this.scale.norfolk = e.data
        break;
      case 62:
        this.scale.nis_option = e.valueRadio
        this.scale.nis = e.data
        break;
      case 63:
        this.scale.nis_ll_option = e.valueRadio
        this.scale.nis_ll = e.data
        break;
      case 64:
        this.scale.compass_option = e.valueRadio
        this.scale.compass = e.data
        break;
      case 65:
        this.scale.other_scale = e.data
        break;
    }
    await this.actionScale.emit(this.scale);
  }

  async loadTreatment(e) {
    switch (e.number) {
      case 66:
        this.treatment.medication = e.data
        break;
      case 67:
        this.treatment.clinical_study_option = e.valueRadio
        this.treatment.clinical_study = e.data
        break;
      case 68:
        this.treatment.experimental_drug_option = e.valueRadio
        this.treatment.experimental_drug = e.data
        break;
      case 69:
        this.treatment.transplant_option = e.valueRadio
        this.treatment.year_transplant = e.otherValue
        this.treatment.donor = e.donor
        this.treatment.transplant = e.data
        break;
      case 70:
        this.treatment.amyloidosis_option = e.valueRadio
        this.treatment.amyloidosis = e.data
        break;
      case 71:
        this.treatment.nutritional_option = e.valueRadio
        this.treatment.nutritional = e.data
        break;
      case 72:
        this.treatment.rehabilitation_option = e.valueRadio
        this.treatment.rehabilitation = e.data
        break;
      case 73:
        this.treatment.integral_option = e.valueRadio
        this.treatment.integral = e.data
        break;
      case 74:
        this.treatment.inmunosupreso_option = e.valueRadio
        this.treatment.inmunosupreso = e.data
        break;
    }
    await this.actionTreatment.emit(this.treatment);
  }

  onSaveClinicHistory() {
    if (this.selectOption.length === 0) {
      this.toastService.error('Seleccione una evaluación');
      return;
    }
    if (this.valueRadio == '' || this.valueRadio == undefined) {
      this.toastService.error('Seleccione estadio de la enfermedad');
      return;
    }
    this.saveIn = true;
    this.actionSave.emit(this.selectOption);
    this.actionOnSave.emit(this.saveIn);
  }
}