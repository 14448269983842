import { Injectable } from '@angular/core';
import { Configuration } from '@app/core/entities';
import { Storage } from '@ionic/storage';
import { environment } from '../../../../environments/environment';
import * as _ from 'lodash';

const key = 'configuration';
@Injectable({
  providedIn: 'root'
})
export class ConfigStorage {

  config: Configuration;

  constructor(private storage: Storage) {
  }

  get expire(): number {
    return new Date().getTime() + (30 * 1000) * 1; // 1 minite
  }

  async init() {
    this.config = (await this.storage.get(key)) || (this.storage.set(key, new Configuration(null, this.expire)) && new Configuration(null, this.expire));
    if (!this.config.expire) {
      this.config.expire = this.expire;
    }
    return this.config;
  }

  get(): Configuration {
    return this.config;
  }

  set(config: Configuration) {
    this.config = config;
    return this.storage.set(key, config);
  }

  refreshExpire() {
    this.config.expire = this.expire;
    this.set(this.config);
  }

  get apiHost(): string {
    return environment.API_URL;
  }

  // get socketHost(): string {
  //   return environment.SOCKET_HOST;
  // }

  get isModeProduction() {
    return environment.production;
  }

  clear() {
    this.config = new Configuration(null, this.expire);
    this.storage.set(key, this.config);
  }

}

