import { Component, Input, OnInit } from '@angular/core';
import { PatientService, SpinnerService, ToastService } from '@app/core/services';
import { GlobalService } from '@app/core/services/global/global.service';
import { ModalController } from '@ionic/angular';
import * as _ from 'lodash';

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss'],
})
export class FilesComponent implements OnInit {

  @Input() kindred: any;

  constructor(
    private modalCtrl: ModalController,
    private spinner: SpinnerService,
    private patienService: PatientService,
    private global: GlobalService,
    private toast: ToastService
  ) { }

  ngOnInit() { }

  onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      this.onSubmit(event);
    }
  }

  onSubmit(event: any): void {
    if (!this.kindred) {
      event.target.value = null;
      return;
    }
    const files: FileList = event.target.files;
    this.spinner.show();
    const sub = this.patienService.generateFiles(this.kindred.id, files).subscribe((res: any) => {
      console.log(res, 'UPLOAD')
      if (res.type === 4) {
        sub.unsubscribe();
        this.spinner.hide();
        event.target.value = null;
        this.kindred = res.body.data;
        this.patienService.onKindred.next(this.kindred);
      }
    }, () => {
      event.target.value = null;
      // sub.unsubscribe();
      this.spinner.hide();
      this.toast.presentToast({ text: 'Error al procesar' });
    });
  }

  _removeItem(item) {
    const result = window.confirm('Seguro que desea eliminar el archivo ' + item.name);
    if (result) {
      this.spinner.show();
      const sub = this.patienService.deleteFiles(this.kindred.id, item.id).subscribe(() => {
        sub.unsubscribe();
        this.spinner.hide();
        this.kindred.files = _.reject(this.kindred.files, { id: item.id });
        this.patienService.onKindred.next(this.kindred);
      }, () => {
        sub.unsubscribe();
        this.spinner.hide();
        this.toast.presentToast({ text: 'Error al procesar' });
      });
    }
  }


  close() {
    this.modalCtrl.dismiss();
  }

}
