import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { LoaderService } from './loader.service';
import { Subscription, TimeoutError } from 'rxjs';
import * as _ from 'lodash';

const win = window as any;
const SUPPORTS_VARS = !!(win.CSS && win.CSS.supports && win.CSS.supports('--a: 0'));
const SIZE_TO_MEDIA = {
    xs: '(min-width: 0px)',
    sm: '(min-width: 576px)',
    md: '(min-width: 768px)',
    lg: '(min-width: 992px)',
    xl: '(min-width: 1200px)',
};

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit, OnDestroy {

    @Input() force = false;
    @Input() show = false;

    timeoutError: TimeoutError;

    @Input() enabled = true;
    @Input() id: string;
    @Input() spinnerTop = false;
    @Input() eventLoading = false;

    /** response size */
    // tslint:disable-next-line: no-input-rename
    @Input('size-xs') sizeXs: string;
    // tslint:disable-next-line: no-input-rename
    @Input('size-sm') sizeSm: string;
    // tslint:disable-next-line: no-input-rename
    @Input('size-md') sizeMd: string;
    // tslint:disable-next-line: no-input-rename
    @Input('size-lg') sizeLg: string;
    // tslint:disable-next-line: no-input-rename
    @Input('size-xl') sizeXl: string;

    @Output() reload = new EventEmitter<boolean>(false);
    @Output() isLoading = new EventEmitter<boolean>(false);

    loaderServiceSub: Subscription;
    timeoutErrorSub: Subscription;

    constructor(
        public loaderService: LoaderService) {
    }

    // Loop through all of the breakpoints to see if the media query
    // matches and grab the column value from the relevant prop if so
    private getColumns(property: string = 'size') {
        let index = -1;
        _.keys(SIZE_TO_MEDIA).forEach((size) => {
            index = window.matchMedia(SIZE_TO_MEDIA[size]).matches ? index + 1 : index;
        });
        const breakpoint = _.keys(SIZE_TO_MEDIA)[index];
        return (this as any)[property + breakpoint.charAt(0).toUpperCase() + breakpoint.slice(1)];
    }

    get width(): string {
        const columns = this.getColumns();
        if (!!columns) {
            // If the size is set to auto then don't calculate a size
            const colSize = (columns === 'auto')
                ? 'auto'
                // If CSS supports variables we should use the grid columns var
                : SUPPORTS_VARS ? `calc(calc(${columns} / var(--ion-grid-columns, 12)) * 100%)`
                    // Convert the columns to a percentage by dividing by the total number
                    // of columns (12) and then multiplying by 100
                    : ((columns / 12) * 100) + '%';
            return `${colSize}`;
        }
        return '100%';
    }

    ngOnInit() {
        this.timeoutError = undefined;
        this.loaderServiceSub = this.loaderService.status.subscribe((val: boolean) => {
            if (this.eventLoading) {
                this.isLoading.emit(this.show);
            }
            setTimeout(() => {
                this.show = this.force || !!val;
            }, 100);
        });
    }

    get _id(): string {
        return this.loaderService.id;
    }

    get height(): string {
        return this.loaderService.style ? `${this.loaderService.style.height}%` : '100%';
    }

    onReload() {
        this.reload.emit(true);
    }

    ngOnDestroy(): void {
        if (this.loaderServiceSub) {
            this.loaderServiceSub.unsubscribe();
        }
        if (this.timeoutErrorSub) {
            this.timeoutErrorSub.unsubscribe();
        }
    }
}
