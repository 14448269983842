import { Component, OnInit } from '@angular/core';
import { PatientService, SpinnerService, ToastService } from '@app/core/services';
import { GlobalService } from '@app/core/services/global/global.service';
import { IonInput, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-import-tree',
  templateUrl: './import-tree.component.html',
  styleUrls: ['./import-tree.component.scss'],
})
export class ImportTreeComponent implements OnInit {

  patient: any

  constructor(
    private modalCtrl: ModalController,
    private spinnerService: SpinnerService,
    private patientService: PatientService,
    private global: GlobalService,
    private toashService: ToastService,
  ) { }

  ngOnInit() {
    /*setTimeout(() => {
      Array.from(document.getElementsByClassName('spinner-div')).forEach(el => el.remove());
    }, 1000)*/
  }

  close() {
    this.modalCtrl.dismiss();
  }

  /**
   * capture photo
   */
  takePhoto(inputFile: IonInput) {
    inputFile.getInputElement().then((fileInput: any) => {
      fileInput.value = null;
      fileInput.click();
    });
  }

  /**
   * @description process the image
   * @param event process
   */
  processWebImage(event: any) {
    if (event.target.files.length > 0 && this.global.validatePathImg(event.target.files[0].type)) {
      this.toashService.presentToast({
        text: 'Formato de imagen no valido'
      });
      return;
    }
    const reader = new FileReader();
    reader.onload = async (readerEvent) => {
      const image64 = (readerEvent.target as any).result;
      this.patient.image_diagram = undefined;
      this.spinnerService.show();
      const sub = this.patientService.updateImageDiagram({ image64 }, this.patient.id).subscribe((res: any) => {
        this.spinnerService.hide();
        this.patient.image_diagram = res.image_diagram;
        sub.unsubscribe();
      }, () => {
        this.spinnerService.hide();
        this.toashService.presentToast({
          text: 'No se logro guardar, intente mas tarde'
        });
        sub.unsubscribe();
      })
    };
    reader.readAsDataURL(event.target.files[0]);
  }

}
